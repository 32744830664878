/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { MultiValue } from 'react-select'
import {
	AsyncPaginate,
	reduceGroupedOptions,
} from 'react-select-async-paginate'

import { Breadcrumb, Input, InputGroup } from '@components'
import Button from '@components/Button'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import Template from '@components/Template'
import TextEditor from '@components/TextEditor'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addContentSchema } from '@schemas/addContent.schema'
import { moduleApi } from '@services/ModuleApi'
import { pdfApi } from '@services/PdfApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import Formatters from '@utils/helpers/Formatters'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

type Inputs = {
	name: string
	description: string
	day: string
	expirationDate: string
	fileIds: MultiValue<{ value: number; label: string }>
}

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

function EditContents() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id, idContent } = useParams<{
		id: string
		idContent: string
	}>()

	const [getModule, { data }] = moduleApi.useShowMutation()
	const [getPdfs] = pdfApi.useListFilteredMutation()

	const [editContent, { isSuccess }] = moduleApi.useEditContentMutation()

	const [getContent, { data: content }] = moduleApi.useShowContentMutation()

	useEffect(() => {
		if (id) {
			getModule({ id: Number(id) as number })
		}
	}, [id, getModule])

	useEffect(() => {
		if (idContent) {
			getContent({
				idContent,
			})
		}
	}, [idContent, getContent])

	const baseUrl = `/modulos/${id}/conteudo/${idContent}/editar-conteudo`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Modulos`, `/modulos`, 1),
			createBreadcrumbLink(`${data?.name}`, `/modulos/${id}`, 2),
			createBreadcrumbLink(`Editar Conteúdo`, baseUrl, 3),
		]
	}, [baseUrl, data?.name, id])

	const pages = useCreateBreadcrumb(allPages)

	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addContentSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (dataContent) => {
		const dataContents = {
			moduleId: id,
			name: dataContent.name,
			description: dataContent.description,
			day: dataContent.day,
			expirationDate: dataContent.expirationDate,
			fileIds: dataContent.fileIds.map((v) => v.value as number) || [],
		}

		editContent({ idContent, ...dataContents })
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			navigate(`/modulos/${id}`)
			dispatch(notificationCreators.show(successMessages.updatedContent))
		}
	}, [isSuccess, navigate, id, dispatch])

	const [enunciado, setEnunciado] = useState(content?.description)

	useEffect(() => {
		if (content) {
			setValue('name', content?.name as string)
			setEnunciado(content?.description)
			setValue('description', content.description)
			setValue('day', content?.day)
			setValue(
				'expirationDate',
				Formatters.createdAt(content?.expirationDate, 5)
			)
			setValue(
				'fileIds',
				content?.files?.map((e) => ({ value: e.id, label: e.name }))
			)
		}
	}, [content, setValue])

	const loadOptions = async (
		search: string,
		options: any,
		additional: { page: number } | undefined
	) => {
		const dataFiles = await getPdfs({ search, page: additional!.page }).unwrap()

		const payload = {
			options: dataFiles?.data
				? dataFiles.data.map((item) => ({
						label: item.name,
						options: item.files.map((file) => ({
							value: file.id,
							label: file.name,
						})),
				  }))
				: options,
			hasMore: dataFiles?.meta.lastPage !== additional!.page,
			additional: {
				page: additional!.page! + 1,
			},
		}

		return payload
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Editar Conteúdo' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='flex flex-wrap justify-between'>
						<InputGroup label='Título' id='name' className='w-full'>
							<Input
								type='text'
								{...register('name')}
								isValid={inputIsValid(errors.name, touchedFields.name)}
							/>
						</InputGroup>

						<Controller
							name='description'
							control={control}
							render={({ field: { onBlur } }) => (
								<InputGroup
									className={`mt-6 ${
										errors.description && 'error-textBox'
									} w-[100%]`}
									label='Conteúdo'
									id='description'
								>
									<TextEditor
										onChange={(e) => {
											setEnunciado(e)
											setValue('description', e)
										}}
										onBlur={onBlur}
										value={enunciado}
									/>
								</InputGroup>
							)}
						/>

						<InputGroup
							label='Dias para exposição'
							id='day'
							className='w-[49%] mt-6'
						>
							<Input
								type='number'
								{...register('day')}
								isValid={inputIsValid(errors.day, touchedFields.day)}
							/>
						</InputGroup>

						<InputGroup
							className='mt-6 w-[49%]'
							label='Data de expiração'
							id='expirationDate'
						>
							<Input
								isValid={inputIsValid(
									errors.expirationDate,
									touchedFields.expirationDate
								)}
								{...register('expirationDate')}
								type='date'
								maxLength={4}
							/>
						</InputGroup>

						<InputGroup label='PDFs' id='fileIds' className='w-full mt-6'>
							<Controller
								control={control}
								name='fileIds'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<AsyncPaginate
											onChange={(v) => setValue('fileIds', v)}
											loadOptions={loadOptions}
											defaultOptions
											debounceTimeout={300}
											value={value}
											filterOption={() => true}
											reduceOptions={reduceGroupedOptions}
											additional={{ page: 1 }}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button
							variant='outline'
							onClick={() => navigate(`/modulos/${id}`)}
						>
							Cancelar
						</Button>
						<Button type='submit'>Editar Conteúdo</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default EditContents
