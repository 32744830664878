import React from 'react'

import { Logo } from '@components'
import Spinner from '@components/Spinner'

export default function Loading() {
	return (
		<div className='w-full h-screen bg-gray-light'>
			<div className='w-full h-[90%] flex items-center justify-center'>
				<Spinner size='xl' />
			</div>
			<div className='flex items-center justify-center'>
				<Logo />
			</div>
		</div>
	)
}
