import { BASE_URL } from '@constants/api'
import {
	AddYearRequest,
	ListYearRequest,
	ListYearResponse,
	ShowYearResponse,
	UpdateYearRequest,
} from '@models/year.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const yearApi = createApi({
	reducerPath: 'yearApi',
	tagTypes: ['Anos', 'Ano'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListYearResponse, ListYearRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/filters?page=${page}&type=year&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Anos'],
		}),
		show: builder.mutation<ShowYearResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddYearRequest>({
			query: (data) => ({
				url: `/filters`,
				body: {
					...data,
					typeId: 1,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Anos'],
		}),
		edit: builder.mutation<void, UpdateYearRequest>({
			query: ({ id, ...data }) => ({
				url: `/filters/${id}`,
				body: {
					...data,
					typeId: 1,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Anos'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Anos'],
		}),
	}),
})
