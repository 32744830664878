import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	AutocompleteMultiple,
	Breadcrumb,
	Button,
	Divider,
	EmptyRow,
	Flex,
	NavTab,
	Pagination,
	SearchInput,
	Spinner,
	Table,
	TagsActions,
	Td,
	Template,
	Tr,
} from '@components'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import {
	FilterStateProps,
	InitOptionsFilterResponse,
} from '@models/questions.model'
import { questionsApi } from '@services/QuestionsApi'
import { loadingModal, openAlertModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { LinkManager } from '@utils/helpers'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

export function ListQuestions() {
	const styles = {
		table: 'mt-4',
		link: 'font-medium text-primary',
		divider: 'mt-[17px] mb-[12px]',
	}
	const dispatch = useAppDispatch()
	const query = useQuery()
	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''
	const pages = [{ name: 'Questões', href: '#', current: true }]
	const navigate = useNavigate()
	const baseUrl = `/questoes`

	const linkManager = new LinkManager(baseUrl)
	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)
	const [initOptions, setInitOptions] = useState<InitOptionsFilterResponse>({
		articles: [],
		disciplines: [],
		institutions: [],
		materId: [],
		subMaterId: [],
		juries: [],
		roles: [],
		years: [],
	})
	const [values, setValues] = useState<FilterStateProps>({
		disciplines: [],
		materId: [],
		subMaterId: [],
		year: [],
		office: [],
		juries: [],
		institutions: [],
	})

	const { data: dataQuestions, isLoading: isLoadingQuestions } =
		questionsApi.useGetQuestionsQuery({
			page,
			value: {
				discipline: values.disciplines.map((value) => value.id).join(','),
				matter: values.materId.map((value) => value.id).join(','),
				submatter: values.subMaterId.map((value) => value.id).join(','),
				year: values.year.map((value) => value.id).join(','),
				position: values.office.map((value) => value.id).join(','),
				jury: values.juries.map((value) => value.id).join(','),
				institution: values.institutions.map((value) => value.id).join(','),
			},
			search: searchQuery,
		})

	const [
		getFile,
		{ isLoading: isLoadingFile, isSuccess: isSuccessFile, data: dataFile },
	] = questionsApi.useGetFileQuestionsMutation()

	const [
		GetAllOptionsFilter,
		{ isSuccess: isSuccessAllFilter, data: dataAllFilters },
	] = questionsApi.useAllOptionsFilterMutation()

	const [getOptionsMatter, { data: dataMatter, isSuccess: isSuccessMatter }] =
		questionsApi.useOptionMatterMutation()

	const [
		getOptionsSubMatter,
		{ data: dataSubMatter, isSuccess: isSuccessSubMatter },
	] = questionsApi.useOptionSubMatterMutation()

	const [deleteQuiz, { isLoading: loadingDelete, isSuccess: deleteSuccess }] =
		questionsApi.useDeleteQuizMutation()

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [{ title: 'Questões', to: createLink('') }]
	const searchCallback = useCallback(
		(value: string) => {
			navigate('/questoes?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)
	const tagActive =
		values.institutions.length > 0 ||
		values.disciplines.length > 0 ||
		values.materId.length > 0 ||
		values.subMaterId.length > 0 ||
		values.year.length > 0 ||
		values.office.length > 0 ||
		values.juries.length > 0

	const handleFilterClear = () => {
		setValues({
			disciplines: [],
			materId: [],
			subMaterId: [],
			year: [],
			office: [],
			juries: [],
			institutions: [],
		})
		setSearch('')
	}

	function saveAs(blob: Blob, fileName: string) {
		const anchor = document.createElement('a')

		anchor.download = fileName
		anchor.href = URL.createObjectURL(blob)
		anchor.click()
		URL.revokeObjectURL(anchor.href)
	}

	const handleDownload = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.preventDefault()
		getFile({
			value: {
				discipline: values.disciplines.map((value) => value.id).join(','),
				matter: values.materId.map((value) => value.id).join(','),
				submatter: values.subMaterId.map((value) => value.id).join(','),
				year: values.year.map((value) => value.id).join(','),
				position: values.office.map((value) => value.id).join(','),
				jury: values.juries.map((value) => value.id).join(','),
				institution: values.institutions.map((value) => value.id).join(','),
			},
		})
	}

	useEffect(() => {
		if (isSuccessFile && dataFile) {
			saveAs(dataFile, 'arquivo')
		}
	}, [isSuccessFile, dataFile])

	/* 	const handleFilter = () => {
		getQuestion({
			page: 1,
			value: {
				discipline: values.disciplines.map((value) => value.id).join(','),
				matter: values.materId.map((value) => value.id).join(','),
				submatter: values.subMaterId.map((value) => value.id).join(','),
				year: values.year.map((value) => value.id).join(','),
				position: values.office.map((value) => value.id).join(','),
				jury: values.juries.map((value) => value.id).join(','),
				institution: values.institutions.map((value) => value.id).join(','),
			},
		})
	} */

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteQuiz))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	/* useEffect(() => {
		getQuestion({
			page: 1,
			value: {
				discipline: values.disciplines.map((value) => value.id).join(','),
				matter: values.materId.map((value) => value.id).join(','),
				submatter: values.subMaterId.map((value) => value.id).join(','),
				year: values.year.map((value) => value.id).join(','),
				position: values.office.map((value) => value.id).join(','),
				jury: values.juries.map((value) => value.id).join(','),
				institution: values.institutions.map((value) => value.id).join(','),
			},
		})
	}, [])
 */
	useInputBounce(search, searchCallback)
	useFilters({
		search: searchQuery,
		fallbackUrl: '/questoes',
	})

	useEffect(() => {
		GetAllOptionsFilter()
	}, [GetAllOptionsFilter])

	useEffect(() => {
		if (isSuccessAllFilter && dataAllFilters) {
			setInitOptions(dataAllFilters)
		}
	}, [isSuccessAllFilter, dataAllFilters])

	useEffect(() => {
		if (values.disciplines.length > 0) {
			getOptionsMatter(values.disciplines.map((value) => value.id).join(','))
		} else {
			setValues((prev) => ({ ...prev, materId: [] }))
			setInitOptions((prev) => ({ ...prev, materId: [] }))
		}
	}, [values.disciplines, getOptionsMatter])

	useEffect(() => {
		if (isSuccessMatter && dataMatter) {
			setInitOptions((prevState) => ({
				...prevState,
				materId: dataMatter.data,
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessMatter, dataMatter])

	useEffect(() => {
		if (values.materId.length > 0) {
			getOptionsSubMatter(values.materId.map((value) => value.id).join(','))
		} else {
			setValues((prev) => ({ ...prev, subMaterId: [] }))
			setInitOptions((prev) => ({ ...prev, subMaterId: [] }))
		}
	}, [values.materId, getOptionsSubMatter])

	useEffect(() => {
		if (isSuccessSubMatter && dataSubMatter) {
			setInitOptions((prevState) => ({
				...prevState,
				subMaterId: dataSubMatter.data,
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessSubMatter, dataSubMatter])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<div className='flex justify-between items-end'>
					<NavTab links={links} />
					<Button onClick={() => navigate(`${baseUrl}/criar-questao`)}>
						Adicionar Questão
					</Button>
				</div>
				<div className='grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-4 mt-7'>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								disciplines: item,
							}))
						}}
						options={initOptions.disciplines}
						selectedValue={values.disciplines}
						label='Código/Lei'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								materId: item,
							}))
						}}
						options={initOptions.materId || []}
						selectedValue={values.materId}
						label='Assunto'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								subMaterId: item,
							}))
						}}
						options={initOptions.subMaterId || []}
						selectedValue={values.subMaterId}
						label='SubAssunto'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								year: item,
							}))
						}}
						options={initOptions.years}
						selectedValue={values.year}
						label='Ano'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								office: item,
							}))
						}}
						options={initOptions.roles}
						selectedValue={values.juries}
						label='Cargo'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								juries: item,
							}))
						}}
						options={initOptions.juries}
						selectedValue={values.juries}
						label='Banca'
					/>
					<AutocompleteMultiple
						onChange={(item) => {
							setValues((prevState) => ({
								...prevState,
								institutions: item,
							}))
						}}
						options={initOptions.institutions}
						selectedValue={values.institutions}
						label='Instituição'
					/>
				</div>

				{tagActive && (
					<div className='flex flex-wrap gap-2 justify-between mt-5'>
						<TagsActions
							title='Código/Lei'
							name='disciplines'
							options={values.disciplines}
							onClose={setValues}
						/>
						<TagsActions
							title='Assunto'
							name='materId'
							options={values.materId}
							onClose={setValues}
						/>
						<TagsActions
							title='SubAssunto'
							name='subMaterId'
							options={values.subMaterId}
							onClose={setValues}
						/>
						<TagsActions
							title='Ano'
							name='year'
							options={values.year}
							onClose={setValues}
						/>
						<TagsActions
							title='Cargo'
							name='office'
							options={values.office}
							onClose={setValues}
						/>
						<TagsActions
							title='Banca'
							name='juries'
							options={values.juries}
							onClose={setValues}
						/>
						<TagsActions
							title='Instituição'
							name='institutions'
							options={values.institutions}
							onClose={setValues}
						/>
					</div>
				)}

				<div className='flex justify-between mt-5'>
					<Button variant='danger' onClick={handleFilterClear}>
						Limpar Filtro
					</Button>

					<Button onClick={handleDownload} isLoading={isLoadingFile}>
						Fazer Download
					</Button>
				</div>

				<div className='flex gap-6 items-end mt-6'>
					<SearchInput
						placeholder='Buscar Questão'
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						value={search}
					/>
				</div>

				{isLoadingQuestions ? (
					<div className='flex justify-center my-10'>
						<Spinner size='md' />
					</div>
				) : (
					<div className={styles.table}>
						<Table
							th={[{ label: 'ID' }, { label: 'Nome' }, { label: 'Ações' }]}
							variant='mixed'
						>
							{dataQuestions?.questions &&
							dataQuestions?.questions.length > 0 ? (
								dataQuestions?.questions.map(
									({ id: idQuestion, title: name }) => {
										return (
											<Tr key={idQuestion}>
												<Td>{idQuestion}</Td>
												<Td className='w-[75%] px-6'>
													<span className='text-primary items-center font-medium'>
														{name}
													</span>
													<br />
													{/* <span>{description?.formated}</span> */}
												</Td>
												<Td>
													<Flex className='items-center gap-4'>
														<Button
															className='!px-2 !py-2'
															leftIcon={<PencilAltIcon />}
															disabled={false}
															variant='outline'
															onClick={() =>
																navigate(
																	`${baseUrl}/editar-questao/${idQuestion}`
																)
															}
														>
															<></>
														</Button>
														<Button
															className='!px-2 !py-2'
															leftIcon={<TrashIcon />}
															disabled={false}
															variant='outline'
															onClick={() => {
																const removeQuiz = {
																	title: 'Remover Questão',
																	message:
																		'Tem certeza que deseja remover a questão?',
																	buttonLabel: 'Remover Questão',
																	onClick: () =>
																		deleteQuiz({
																			id: idQuestion,
																		}),
																}

																dispatch(openAlertModal(removeQuiz))
															}}
														>
															<></>
														</Button>
													</Flex>
												</Td>
											</Tr>
										)
									}
								)
							) : (
								<EmptyRow tdAmount={4} message='Nenhuma questão encontrada' />
							)}
						</Table>
					</div>
				)}
				<Divider className={styles.divider} />
				{dataQuestions?.questions && dataQuestions?.questions?.length > 0 && (
					<Pagination
						pageCount={dataQuestions?.meta.lastPage || 0}
						maxAmount={dataQuestions?.meta.total || 0}
						perPage={dataQuestions?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}
