import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { Input, InputGroup, MarkInput, Spinner } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import FileInput from '@components/FileInput'
import PageTitle from '@components/PageTitle'
import SelectInput, { Option } from '@components/SelectInput'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addClassesSchema } from '@schemas/addClasses.schema'
import { classesApi } from '@services/ClassesApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

type Inputs = {
	name: string
	period: { value: string | number; option: string }
	pdf: boolean
	question: boolean
	general: boolean
	isPaid: boolean
	imageUrl: Array<File>
	description: string
	showExpiration: boolean
	codes: Array<{
		code: string
		type:
			| 'ANNUAL'
			| 'ANNUAL2'
			| 'MONTHLY'
			| 'SEMIANNUAL'
			| 'WEEKLY'
			| 'MONTHLY2'
			| 'MONTHLY3'
			| 'MONTHLY4'
			| 'MONTHLY5'
			| 'MONTHLY7'
			| 'MONTHLY8'
			| 'MONTHLY9'
			| 'MONTHLY10'
			| 'MONTHLY11'
	}>
	csvAluno: Array<File>
	ANNUAL: string
	ANNUAL2: string
	SEMIANNUAL: string
	MONTHLY: string
	MONTHLY2: string
	MONTHLY3: string
	MONTHLY4: string
	MONTHLY5: string
	MONTHLY7: string
	MONTHLY8: string
	MONTHLY9: string
	MONTHLY10: string
	MONTHLY11: string
	WEEKLY: string
}

function EditClasses() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id } = useParams<{
		id: string
	}>()

	const baseUrl = `/turmas/editar-turma/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Turmas`, `/turmas`, 1),
			createBreadcrumbLink(`Editar Turma`, baseUrl, 2),
		]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const [getClass, { data, isLoading }] = classesApi.useShowMutation()

	useEffect(() => {
		if (id) {
			getClass({ id: Number(id) as number })
		}
	}, [id, getClass])

	const [editClass, { isSuccess }] = classesApi.useEditMutation()

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, touchedFields },
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addClassesSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (dataEdit) => {
		const codes = []

		if (dataEdit.ANNUAL) {
			codes.push({ code: dataEdit.ANNUAL.replace(/\s/g, ''), type: 'ANNUAL' })
		}

		if (dataEdit.ANNUAL2) {
			codes.push({ code: dataEdit.ANNUAL2.replace(/\s/g, ''), type: 'ANNUAL2' })
		}

		if (dataEdit.MONTHLY) {
			codes.push({ code: dataEdit.MONTHLY.replace(/\s/g, ''), type: 'MONTHLY' })
		}

		if (dataEdit.MONTHLY2) {
			codes.push({
				code: dataEdit.MONTHLY2.replace(/\s/g, ''),
				type: 'MONTHLY2',
			})
		}

		if (dataEdit.MONTHLY3) {
			codes.push({
				code: dataEdit.MONTHLY3.replace(/\s/g, ''),
				type: 'MONTHLY3',
			})
		}

		if (dataEdit.MONTHLY4) {
			codes.push({
				code: dataEdit.MONTHLY4.replace(/\s/g, ''),
				type: 'MONTHLY4',
			})
		}

		if (dataEdit.MONTHLY5) {
			codes.push({
				code: dataEdit.MONTHLY5.replace(/\s/g, ''),
				type: 'MONTHLY5',
			})
		}
		if (dataEdit.MONTHLY7) {
			codes.push({
				code: dataEdit.MONTHLY7.replace(/\s/g, ''),
				type: 'MONTHLY7',
			})
		}
		if (dataEdit.MONTHLY8) {
			codes.push({
				code: dataEdit.MONTHLY8.replace(/\s/g, ''),
				type: 'MONTHLY8',
			})
		}
		if (dataEdit.MONTHLY9) {
			codes.push({
				code: dataEdit.MONTHLY9.replace(/\s/g, ''),
				type: 'MONTHLY9',
			})
		}
		if (dataEdit.MONTHLY10) {
			codes.push({
				code: dataEdit.MONTHLY10.replace(/\s/g, ''),
				type: 'MONTHLY10',
			})
		}
		if (dataEdit.MONTHLY11) {
			codes.push({
				code: dataEdit.MONTHLY11.replace(/\s/g, ''),
				type: 'MONTHLY11',
			})
		}

		if (dataEdit.SEMIANNUAL) {
			codes.push({
				code: dataEdit.SEMIANNUAL.replace(/\s/g, ''),
				type: 'SEMIANNUAL',
			})
		}

		if (dataEdit.WEEKLY) {
			codes.push({ code: dataEdit.WEEKLY.replace(/\s/g, ''), type: 'WEEKLY' })
		}

		const newData = {
			period: dataEdit.period.value as string,
			name: dataEdit.name,
			pdf: dataEdit.pdf,
			question: dataEdit.question,
			imageUrl: dataEdit.imageUrl,
			description: dataEdit.description,
			general: dataEdit.general,
			isPaid: !dataEdit.isPaid,
			showExpiration: dataEdit.showExpiration,
			clientsCsv: dataEdit.csvAluno,
			codes,
		}

		editClass({
			...newData,
			id,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			navigate('/turmas')
			dispatch(notificationCreators.show(successMessages.updatedClasses))
			reset()
		}
	}, [isSuccess, dispatch, navigate, reset])

	const periodOptions = useMemo(
		() => [
			{
				option: '7 dias',
				value: 'WEEKLY',
			},
			{ option: 'Mensal', value: 'MONTHLY' },
			{ option: 'Bimestral', value: 'MONTHLY2' },
			{ option: 'Trimestral', value: 'MONTHLY3' },
			{ option: '4 Meses', value: 'MONTHLY4' },
			{ option: '5 Meses', value: 'MONTHLY5' },
			{ option: 'Semestral', value: 'SEMIANNUAL' },
			{ option: '7 Meses', value: 'MONTHLY7' },
			{ option: '8 Meses', value: 'MONTHLY8' },
			{ option: '9 Meses', value: 'MONTHLY9' },
			{ option: '10 Meses', value: 'MONTHLY10' },
			{ option: '11 Meses', value: 'MONTHLY11' },
			{ option: 'Anual', value: 'ANNUAL' },
			{ option: '2 Anos', value: 'ANNUAL2' },
		],
		[]
	)

	const onPeriodCsvRegistrationChange = useCallback(
		(v: Option) => setValue('period', { value: v.value, option: v.option }),
		[setValue]
	)

	const [periods, setPeriods] = useState('WEEKLY')

	useEffect(() => {
		if (data) {
			setValue('name', data?.name as string)
			setValue('description', data?.description as string)
			setValue('pdf', !!data?.pdf)
			setValue('question', !!data?.question)
			setValue('general', !!data?.general)
			setValue('isPaid', !data?.isPaid)
			setValue('showExpiration', !!data?.showExpiration)

			periodOptions.forEach((period) => {
				setValue(
					period.value as
						| 'ANNUAL'
						| 'ANNUAL2'
						| 'MONTHLY'
						| 'SEMIANNUAL'
						| 'WEEKLY'
						| 'MONTHLY2'
						| 'MONTHLY3'
						| 'MONTHLY4'
						| 'MONTHLY5'
						| 'MONTHLY7'
						| 'MONTHLY8'
						| 'MONTHLY9'
						| 'MONTHLY10'
						| 'MONTHLY11',
					data.codes
						.filter((code) => code.type === period.value)
						.map((code) => code.code)
						.join(', ')
				)
			})
			setPeriods(data?.period.toUpperCase())
			setValue('period', {
				value: data?.period.toUpperCase(),
				option: data?.period,
			})
		}
	}, [data, periodOptions, setValue])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Editar Turma' />

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
						<div className='grid grid-cols-2 gap-4'>
							<InputGroup label='Título do Turma' id='name'>
								<Input
									type='text'
									{...register('name')}
									isValid={inputIsValid(errors.name, touchedFields.name)}
								/>
							</InputGroup>

							<InputGroup label='Descrição' id='description'>
								<Input
									type='text'
									{...register('description')}
									isValid={inputIsValid(
										errors.description,
										touchedFields.description
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 7 dias' id='WEEKLY'>
								<Input
									type='text'
									{...register('WEEKLY')}
									isValid={inputIsValid(errors.WEEKLY, touchedFields.WEEKLY)}
								/>
							</InputGroup>

							<InputGroup label='Código Mensal' id='MONTHLY'>
								<Input
									type='text'
									{...register('MONTHLY')}
									isValid={inputIsValid(errors.MONTHLY, touchedFields.MONTHLY)}
								/>
							</InputGroup>

							<InputGroup label='Código Bimestral' id='MONTHLY2'>
								<Input
									type='text'
									{...register('MONTHLY2')}
									isValid={inputIsValid(
										errors.MONTHLY2,
										touchedFields.MONTHLY2
									)}
								/>
							</InputGroup>

							<InputGroup label='Código Trimestral' id='MONTHLY3'>
								<Input
									type='text'
									{...register('MONTHLY3')}
									isValid={inputIsValid(
										errors.MONTHLY3,
										touchedFields.MONTHLY3
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 4 Meses' id='MONTHLY4'>
								<Input
									type='text'
									{...register('MONTHLY4')}
									isValid={inputIsValid(
										errors.MONTHLY4,
										touchedFields.MONTHLY4
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 5 Meses' id='MONTHLY5'>
								<Input
									type='text'
									{...register('MONTHLY5')}
									isValid={inputIsValid(
										errors.MONTHLY5,
										touchedFields.MONTHLY5
									)}
								/>
							</InputGroup>

							<InputGroup label='Código Semestral' id='SEMIANNUAL'>
								<Input
									type='text'
									{...register('SEMIANNUAL')}
									isValid={inputIsValid(
										errors.SEMIANNUAL,
										touchedFields.SEMIANNUAL
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 7 Meses' id='MONTHLY7'>
								<Input
									type='text'
									{...register('MONTHLY7')}
									isValid={inputIsValid(
										errors.MONTHLY7,
										touchedFields.MONTHLY7
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 8 Meses' id='MONTHLY8'>
								<Input
									type='text'
									{...register('MONTHLY8')}
									isValid={inputIsValid(
										errors.MONTHLY8,
										touchedFields.MONTHLY8
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 9 Meses' id='MONTHLY9'>
								<Input
									type='text'
									{...register('MONTHLY9')}
									isValid={inputIsValid(
										errors.MONTHLY9,
										touchedFields.MONTHLY9
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 10 Meses' id='MONTHLY10'>
								<Input
									type='text'
									{...register('MONTHLY10')}
									isValid={inputIsValid(
										errors.MONTHLY10,
										touchedFields.MONTHLY10
									)}
								/>
							</InputGroup>

							<InputGroup label='Código 11 Meses' id='MONTHLY11'>
								<Input
									type='text'
									{...register('MONTHLY11')}
									isValid={inputIsValid(
										errors.MONTHLY11,
										touchedFields.MONTHLY11
									)}
								/>
							</InputGroup>

							<InputGroup label='Código Anual' id='annual'>
								<Input
									type='text'
									{...register('ANNUAL')}
									isValid={inputIsValid(errors.ANNUAL, touchedFields.ANNUAL)}
								/>
							</InputGroup>

							<InputGroup label='Código 2 Anos' id='annual2'>
								<Input
									type='text'
									{...register('ANNUAL2')}
									isValid={inputIsValid(errors.ANNUAL2, touchedFields.ANNUAL2)}
								/>
							</InputGroup>

							<InputGroup
								className='col-span-2'
								label='Período para cadastro de CSV'
								id='period'
							>
								<Controller
									control={control}
									name='period'
									render={() => {
										return (
											<SelectInput
												btnClass='py-[8px] px-[13px]'
												options={periodOptions}
												selectedValue={periods}
												onChange={onPeriodCsvRegistrationChange}
											/>
										)
									}}
								/>
							</InputGroup>

							<Controller
								control={control}
								name='pdf'
								render={({ field: { onBlur } }) => {
									return (
										<>
											<MarkInput
												className='mt-4'
												type='checkbox'
												multiCheckbox
												defaultChecked={Boolean(data?.pdf)}
												itens={[{ id: '1', title: 'Acesso ao PDF' }]}
												onBlur={onBlur}
												onChange={(e) => {
													setValue('pdf', e.currentTarget.checked)
												}}
											/>
										</>
									)
								}}
							/>

							<Controller
								control={control}
								name='question'
								render={({ field: { onBlur } }) => {
									return (
										<>
											<MarkInput
												className='mt-4'
												type='checkbox'
												multiCheckbox
												defaultChecked={Boolean(data?.question)}
												itens={[{ id: '1', title: 'Acesso as questões' }]}
												onBlur={onBlur}
												onChange={(e) => {
													setValue('question', e.currentTarget.checked)
												}}
											/>
										</>
									)
								}}
							/>

							<Controller
								control={control}
								name='general'
								render={({ field: { onBlur } }) => {
									return (
										<>
											<MarkInput
												className='mt-4'
												type='checkbox'
												multiCheckbox
												defaultChecked={Boolean(data?.general)}
												itens={[{ id: '1', title: 'Acesso geral' }]}
												onBlur={onBlur}
												onChange={(e) => {
													setValue('general', e.currentTarget.checked)
												}}
											/>
										</>
									)
								}}
							/>

							<Controller
								control={control}
								name='showExpiration'
								render={({ field: { onBlur } }) => {
									return (
										<>
											<MarkInput
												className='mt-4'
												type='checkbox'
												multiCheckbox
												defaultChecked={Boolean(data?.showExpiration)}
												itens={[
													{ id: '1', title: 'Mostrar data de expiração' },
												]}
												onBlur={onBlur}
												onChange={(e) => {
													setValue('showExpiration', e.currentTarget.checked)
												}}
											/>
										</>
									)
								}}
							/>

							<Controller
								control={control}
								name='isPaid'
								render={({ field: { onBlur } }) => {
									return (
										<>
											<MarkInput
												className='mt-4'
												type='checkbox'
												multiCheckbox
												defaultChecked={Boolean(!data?.isPaid)}
												itens={[{ id: '1', title: 'Gratuito' }]}
												onBlur={onBlur}
												onChange={(e) => {
													setValue('isPaid', e.currentTarget.checked)
												}}
											/>
										</>
									)
								}}
							/>

							<Controller
								control={control}
								name='csvAluno'
								render={() => {
									return (
										<FileInput
											className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
											mt='mt-4'
											accept='.csv'
											onChange={(v) => {
												setValue('csvAluno', v)
											}}
										>
											CSV Aluno
										</FileInput>
									)
								}}
							/>

							<Controller
								control={control}
								name='imageUrl'
								render={() => {
									return (
										<FileInput
											className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
											mt='mt-4'
											accept='.png,.jpg'
											onChange={(v) => {
												setValue('imageUrl', v)
											}}
										>
											Imagem da turma
										</FileInput>
									)
								}}
							/>
						</div>

						<div className='flex gap-4 mt-6 mb-10'>
							<Button
								variant='outline'
								onClick={() => navigate(`/turmas?page=1`)}
							>
								Cancelar
							</Button>
							<Button type='submit'>Editar Turma</Button>
						</div>
					</form>
				)}
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default EditClasses
