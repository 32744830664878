import * as yup from 'yup'

export const addClassesSchema = yup.object({
	name: yup.string().required(),
	pdf: yup.boolean(),
	question: yup.boolean(),
	general: yup.boolean(),
	isPaid: yup.boolean(),
	showExpiration: yup.boolean(),
})
