import { BASE_URL } from '@constants/api'
import {
	AddInstitutionRequest,
	ListInstitutionRequest,
	ListInstitutionResponse,
	ShowInstitutionResponse,
	UpdateInstitutionRequest,
} from '@models/institution.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const institutionApi = createApi({
	reducerPath: 'institutionApi',
	tagTypes: ['Instituições', 'Instituição'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListInstitutionResponse, ListInstitutionRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/filters?page=${page}&type=institution&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Instituições'],
		}),
		show: builder.mutation<ShowInstitutionResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddInstitutionRequest>({
			query: (data) => ({
				url: `/filters`,
				body: {
					...data,
					typeId: 4,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Instituições'],
		}),
		edit: builder.mutation<void, UpdateInstitutionRequest>({
			query: ({ id, ...data }) => ({
				url: `/filters/${id}`,
				body: {
					...data,
					typeId: 4,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Instituições'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Instituições'],
		}),
	}),
})
