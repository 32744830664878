import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Input, InputGroup } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { questionsApi } from '@services/QuestionsApi'
import { inputIsValid } from '@utils/helpers'
import dayjs from 'dayjs'

type Inputs = {
	startDate: string
	endDate: string
}
const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function QuestionsReport() {
	const pages = [{ name: 'Relatório', href: '#', current: true }]

	const [getQuestionReport, { data, isLoading }] =
		questionsApi.useReportMutation()

	const {
		handleSubmit,
		reset,
		getValues,
		register,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		defaultValues: {
			startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
			endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
		},
	})

	const onSubmit: SubmitHandler<Inputs> = (inputData) => {
		getQuestionReport({
			startDate: inputData.startDate,
			endDate: inputData.endDate,
		})
		reset()
	}

	useEffect(() => {
		getQuestionReport({
			startDate:
				getValues('startDate') || dayjs().startOf('month').format('YYYY-MM-DD'),
			endDate:
				getValues('endDate') || dayjs().endOf('month').format('YYYY-MM-DD'),
		})
	}, [getQuestionReport, getValues])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Turmas' />

				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex className={styles.searchContainer}>
						<InputGroup label='Data de inicio' id='initialDate'>
							<Input
								type='date'
								maxLength={4}
								isValid={inputIsValid(
									errors.startDate,
									touchedFields.startDate
								)}
								{...register('startDate')}
							/>
						</InputGroup>
						<InputGroup label='Data de término' id='finalDate'>
							<Input
								type='date'
								maxLength={4}
								isValid={inputIsValid(errors.endDate, touchedFields.endDate)}
								{...register('endDate')}
							/>
						</InputGroup>
						<Button type='submit'>Filtrar</Button>
					</Flex>
				</form>
				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{
								label: 'Usuário',
							},
							{ label: 'Questões criadas' },
						]}
					>
						{data && data?.length > 0 ? (
							data?.map(({ id, name, email, questions }) => (
								<Tr key={id}>
									<Td className='w-[33%] px-6 py-4'>
										<Flex className='items-center gap-4'>{name}</Flex>
									</Td>
									<Td className='w-[56%] px-6'>
										<Flex className='items-center gap-4'>{email}</Flex>
									</Td>
									<Td className='w-[10%] px-6'>
										<Flex className='items-center gap-4'>{questions}</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhum turma encontrada' />
						)}
					</Table>
				)}
			</Template>
		</>
	)
}

export default QuestionsReport
