export default function capitalize(
	string: string,
	all: boolean | undefined = true
) {
	if (all) {
		const words = string.split(' ')
		const wordsCap = words.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)

		return wordsCap.join(' ')
	}

	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}
