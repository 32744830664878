import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Button,
	Divider,
	EmptyRow,
	Flex,
	Spinner,
	Table,
	Td,
	Tr,
} from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import { TrashIcon } from '@heroicons/react/outline'
import { MenuAlt2Icon } from '@heroicons/react/solid'
import Loading from '@pages/Loading'
import { categoryApi } from '@services/CategoryApi'
import { examsApi } from '@services/ExamsApi'
import { questionsApi } from '@services/QuestionsApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import {
	createBreadcrumbLink,
	useCreateBreadcrumb,
	useQuery,
} from '@utils/hooks'

import EditExams from '../IndividualCategory/EditExams'

const styles = {
	searchContainer: 'gap-[8px] my-[32px] items-end flex-wrap justify-end',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function IndividualSimulated() {
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const [page, setPage] = useState(pageQuery)

	const { id, idSimulated } = useParams<{
		id: string
		idSimulated: string
	}>()

	const { data: categoryInfo } = categoryApi.useShowQueryQuery({
		id: String(id) as string,
	})

	const { data: examsInfo } = examsApi.useShowQueryQuery({
		id: String(idSimulated) as string,
	})

	const { data: questionsInfo, isLoading } = questionsApi.useListQuery({
		id: idSimulated,
		page,
	})

	const [
		deleteQuestion,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = questionsApi.useDeleteMutation()

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteQuiz))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Categorias`, `/categorias`, 1),
			createBreadcrumbLink(`${categoryInfo?.name}`, `/categorias/${id}`, 2),
			createBreadcrumbLink(
				`${examsInfo?.name}`,
				`/categorias/${id}/simulado/${idSimulated}`,
				3
			),
		]
	}, [categoryInfo?.name, examsInfo?.name, id, idSimulated])

	const pages = useCreateBreadcrumb(allPages)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	const options = [
		{
			label: 'Editar Simulado',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editExams',
						modalInfo: idSimulated,
					})
				),
		},
	]

	const [orderQuestion] = questionsApi.useOrderQuestionMutation()
	const [questions, setQuestions] = useState(questionsInfo?.data)

	useEffect(() => {
		setQuestions(questionsInfo?.data)
	}, [questionsInfo])

	function handleDragEnd(result: DropResult) {
		orderQuestion({
			questionId: Number(result.draggableId),
			mockExamId: Number(idSimulated),
			newPosition: Number(result.destination?.index) + 1,
		})

		if (questions) {
			const list = [...questions]

			if (list.length && result.destination && result.source) {
				const [removed] = list.splice(result.source.index, 1)

				list.splice(result.destination.index, 0, removed)

				setQuestions(list)
			}
		}
	}

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${examsInfo?.name}`}
					options={options}
				/>

				<Flex className={styles.searchContainer}>
					<Button
						onClick={() =>
							navigate(
								`/categorias/${id}/simulado/${idSimulated}/adicionar-questao`
							)
						}
					>
						Adicionar Questão
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<DragDropContext onDragEnd={handleDragEnd}>
						<Table
							th={[
								{ label: 'id' },
								{ label: 'nome' },
								{ label: 'ações' },
								{
									label: 'ordem',
								},
							]}
							droppable
							variant='mixed'
						>
							{questions && questions.length > 0 ? (
								questions.map(({ id: questionID, title }, index) => (
									<Draggable
										key={questionID}
										draggableId={String(questionID)}
										index={index}
									>
										{(provided) => (
											<Tr
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<Td>{questionID}</Td>
												<Td className='w-[75%] px-6'>
													<Flex className='items-center gap-4'>{title}</Flex>
												</Td>
												<Td>
													<Flex className='items-center gap-4'>
														<Button
															className='!px-2 !py-2'
															leftIcon={<TrashIcon />}
															disabled={false}
															variant='outline'
															onClick={() => {
																const removeUser = {
																	title: 'Remover questão',
																	message:
																		'Tem certeza que deseja remover a questão deste simulado?',
																	buttonLabel: 'Remover Questão',
																	onClick: () =>
																		deleteQuestion({
																			questionId: questionID,
																			examId: idSimulated,
																		}),
																}

																dispatch(openAlertModal(removeUser))
															}}
														>
															<></>
														</Button>
													</Flex>
												</Td>
												<Td>
													<MenuAlt2Icon className='w-6 h-6' />
												</Td>
											</Tr>
										)}
									</Draggable>
								))
							) : (
								<EmptyRow tdAmount={3} message='Nenhuma questão encontrada' />
							)}
						</Table>
					</DragDropContext>
				)}
				<Divider className={styles.divider} />
			</Template>
			<EditExams />
		</>
	)
}

export default IndividualSimulated
