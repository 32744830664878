import React from 'react'

interface InputGroup {
	label: string
	id: string
	children: JSX.Element
	labelColor?: string
	labelWeight?: string
	className?: string
	tooltip?: {
		text?: string
		icon?: string
	}
}

const styles = {
	label: 'block text-sm leading-5 mb-1',
}

export default function InputGroup({
	label,
	id,
	children,
	labelColor = 'text-gray-700',
	labelWeight = 'font-medium',
	className = '',
	tooltip,
}: InputGroup) {
	return (
		<div className={className}>
			<label
				htmlFor={id}
				className={`${styles.label} ${labelColor} ${labelWeight}`}
			>
				{label}
				{tooltip && (
					<span
						className='ml-1 cursor-help text-gray-700'
						title={tooltip?.text}
					>
						{tooltip.icon || '?'}
					</span>
				)}
			</label>
			<div>
				{React.cloneElement(children, {
					id,
				})}
			</div>
		</div>
	)
}
