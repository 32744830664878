import { BASE_URL } from '@constants/api'
import {
	CheckQuestionsRequest,
	ListErrorsQuestionsRequest,
	ListErrorsQuestionsResponse,
	ShowErrorsQuestionsResponse,
	UpdateErrorsQuestionsRequest,
} from '@models/errosQuestions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const errorsQuestionsApi = createApi({
	reducerPath: 'errorsQuestionsApi',
	tagTypes: ['ErrorQuestion'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<
			ListErrorsQuestionsResponse,
			ListErrorsQuestionsRequest
		>({
			query: ({ page = '1', search = '' }) => ({
				url: `/questionErrors?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['ErrorQuestion'],
		}),
		show: builder.mutation<ShowErrorsQuestionsResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/questionErrors/${id}`,
				method: 'GET',
			}),
		}),
		edit: builder.mutation<void, UpdateErrorsQuestionsRequest>({
			query: ({ id, ...answer }) => ({
				url: `/questionErrors/${id}`,
				body: answer,
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
		checkQuestion: builder.mutation<void, CheckQuestionsRequest>({
			query: ({ id, ...isCorrected }) => ({
				url: `/questionErrors/${id}`,
				body: isCorrected,
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/questionErrors/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
	}),
})
