/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import {
	FileInput,
	Input,
	InputGroup,
	MarkInput,
	Spinner,
	TextEditor,
} from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addModuleSchema } from '@schemas/addModule.schema'
import { classesApi } from '@services/ClassesApi'
import { moduleApi } from '@services/ModuleApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	url: string
	isFree: string
	showPercentage: string
	classIds: MultiValue<{ value: string | number; label: string }>
	image: Array<File>
	description: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditModule() {
	const dispatch = useAppDispatch()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [getModule, { data, isLoading: isLoadingModule }] =
		moduleApi.useShowMutation()

	useEffect(() => {
		if (id && modalName === 'editModule') {
			getModule({ id: Number(id) as number })
		}
	}, [id, getModule, modalName])

	const [editModule, { isLoading, isSuccess }] = moduleApi.useEditMutation()

	const { data: dataClasses } = classesApi.useListQuery({
		limit: 10000,
	})

	const {
		register,
		reset,
		handleSubmit,
		control,
		setValue,
		getValues,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addModuleSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (dataModules) => {
		const dataModule = {
			name: dataModules.name,
			url: dataModules.url,
			isFree: dataModules.isFree ? dataModules.isFree : '0',
			showPercentage: dataModules.showPercentage
				? dataModules.showPercentage
				: '0',
			classIds: dataModules.classIds.map((v) => v.value as number) || [],
			image: dataModules.image,
			description: dataModules.description,
		}

		editModule({
			id,
			...dataModule,
		})
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedModule))
		}
	}, [isSuccess, dispatch])

	const [classIds, setClassIds] = useState<
		Array<{ option: string; value: string }>
	>([])

	useEffect(() => {
		const newClassIds: typeof classIds = []

		if (data?.classes && data.classes.length > 0) {
			data.classes.forEach((e) => {
				newClassIds.push({
					option: e.name,
					value: e.id,
				})
			})
		}

		setClassIds(newClassIds)
	}, [data])

	useEffect(() => {
		setValue(
			'classIds',
			classIds?.map((e) => ({ value: e.value, label: e.option }))
		)
		if (data) {
			setValue('name', data.name)
			setValue('url', data.url)
			setValue('isFree', data.isFree as unknown as string)
			setValue('showPercentage', data.showPercentage as unknown as string)
			setValue('description', data.description)
		}
	}, [data, setValue, classIds])

	if (isLoadingModule) {
		return (
			<Modal name='editModule'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editModule'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Modulo</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar o modulo
				</p>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<InputGroup className='mt-4' label='Título do Módulo' id='name'>
							<Input
								isValid={inputIsValid(errors.name, touchedFields.name)}
								{...register('name')}
								type='text'
							/>
						</InputGroup>

						<Controller
							{...register('description')}
							name='description'
							control={control}
							render={({ field: { onChange, onBlur } }) => (
								<InputGroup
									className={`mt-6 w-[100%] index-0-controler ${
										errors.description && 'error-textBox'
									}`}
									label='Descrição'
									id='description'
								>
									<TextEditor
										onChange={onChange}
										onBlur={onBlur}
										value={getValues('description')}
									/>
								</InputGroup>
							)}
						/>

						<InputGroup className='mt-4' label='Link' id='url'>
							<Input
								isValid={inputIsValid(errors.url, touchedFields.url)}
								{...register('url')}
								type='url'
							/>
						</InputGroup>

						<InputGroup label='Turma' id='classIds' className='mt-4'>
							<Controller
								control={control}
								name='classIds'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('classIds', v)}
											options={
												dataClasses?.data
													? dataClasses.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>

						<Controller
							control={control}
							name='image'
							render={() => {
								return (
									<FileInput
										className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
										mt='mt-4'
										accept='.png,.jpg'
										onChange={(v) => {
											setValue('image', v)
										}}
									>
										Imagem do modulo
									</FileInput>
								)
							}}
						/>

						<Controller
							control={control}
							name='showPercentage'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											defaultChecked={Boolean(data?.showPercentage)}
											itens={[{ id: '1', title: 'Curso concluído' }]}
											multiCheckbox
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'showPercentage',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='isFree'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											defaultChecked={Boolean(data?.isFree)}
											itens={[{ id: '1', title: 'Gratuito' }]}
											multiCheckbox
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'isFree',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<div className={styles.buttonGroup}>
							<Button
								type='button'
								onClick={() => {
									dispatch(closeModal())
								}}
								variant='outline'
								disabled={false}
							>
								Cancelar
							</Button>
							<Button type='submit' isLoading={isLoading} disabled={!modalName}>
								Editar Modulo
							</Button>
						</div>
					</form>
				)}
			</Dialog.Panel>
		</Modal>
	)
}
