/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import { Input, InputGroup, MarkInput, Spinner } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addCategorySchema } from '@schemas/addCategory.schema'
import { categoryApi } from '@services/CategoryApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	isFree: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditCategory() {
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const dispatch = useAppDispatch()

	const [getCategory, { data: category, isLoading: isLoadingCategory }] =
		categoryApi.useShowMutation()

	const [editCategory, { isLoading, isSuccess }] = categoryApi.useEditMutation()

	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addCategorySchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		editCategory({
			id,
			name: data.name,
			isFree: !!data.isFree,
		})
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedCategory))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (id && modalName === 'editCategory') {
			getCategory({
				id,
			})
		}
	}, [id, modalName, getCategory])

	useEffect(() => {
		if (category) {
			setValue('name', category?.name as string)
			setValue('isFree', category?.isFree as unknown as string)
		}
	}, [category, setValue])

	if (isLoadingCategory) {
		return (
			<Modal name='editCategory'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editCategory'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Categoria</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar a categoria
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome da categoria' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<Controller
						control={control}
						name='isFree'
						render={({ field: { onBlur } }) => {
							return (
								<>
									<MarkInput
										className='mt-4'
										type='checkbox'
										itens={[{ id: '3', title: 'Gratuito' }]}
										onBlur={onBlur}
										defaultChecked={Boolean(category?.isFree)}
										onChange={(e) => {
											setValue(
												'isFree',
												e.currentTarget.checked as unknown as string
											)
										}}
									/>
								</>
							)
						}}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Categoria
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
