import React from 'react'

import Divider from '@components/Divider'

interface PageTitleProps {
	title: string
}

const styles = {
	container: 'mb-8',
	title: 'text-[20px] text-dark font-medium mb-5',
}

export default function PageTitle({ title }: PageTitleProps) {
	return (
		<div className={styles.container}>
			<p className={styles.title}>{title}</p>

			<Divider />
		</div>
	)
}
