import * as yup from 'yup'

export const addLibrarieSchema = yup.object({
	name: yup.string().required(),
	pdfFiles: yup
		.mixed()
		.required('')
		.test('fileFormat', 'Selecione um arquivo (.pdf)', (value) => {
			return value && ['application/pdf'].includes(value[0].type)
		}),
})

export const editLibrarieSchema = yup.object({
	name: yup.string().required(),
})
