import * as yup from 'yup'

export const addClientSchema = yup.object({
	name: yup.string().required(),
	email: yup.string().required(),
	password: yup.string().required(),
	cpfCnpj: yup.string(),
	phone: yup.string(),
	whatsapp: yup.string(),
	cep: yup.string(),
	number: yup.string(),
	district: yup.string(),
	city: yup.string(),
	address: yup.string(),
	state: yup.string(),
	classesSelect: yup.array(),
})

export const editClientSchema = yup.object({
	name: yup.string().required(),
	email: yup.string().required(),
	password: yup.string(),
	cpfCnpj: yup.string().nullable(),
	phone: yup.string(),
	whatsapp: yup.string(),
	cep: yup.string(),
	number: yup.string(),
	district: yup.string(),
	city: yup.string(),
	address: yup.string(),
	state: yup.string(),
})

export const addClassClientSchema = yup.object({
	class: yup.array().min(1).required(),
})

export const editClassClientSchema = yup.object({
	class: yup.array().min(1).required(),
})
