import { useState, useEffect } from 'react'

interface Address {
	cep: string
	logradouro: string
	complemento: string
	bairro: string
	localidade: string
	uf: string
	unidade: string
	ibge: string
	gia: string
}

function useAddress(cep: string) {
	const [address, setAddress] = useState({} as Address)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [sucess, setSucess] = useState(false)

	useEffect(() => {
		if (!cep) return
		setLoading(true)
		fetch(`https://viacep.com.br/ws/${cep}/json/`)
			.then((response) => response.json())
			.then((data) => {
				setLoading(true)
				setAddress(data)
				setLoading(false)
				setSucess(true)
			})
			.catch((err) => {
				setError(err)
				setLoading(false)
			})
	}, [cep])

	return { address, error, loading, sucess }
}

export default useAddress
