import React from 'react'

import { Sprite } from '@models/icon.model'
import classNames from 'classnames'

type Icon = {
	name: Sprite
	className?: string
	width?: number
	height?: number
}

export default function Icon({
	name,
	width,
	height,

	className,
}: Icon) {
	return (
		<svg width={width} className={classNames(className)} height={height}>
			<use href={`/assets/images/sprite.svg#${name}`} />
		</svg>
	)
}
