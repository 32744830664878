import React from 'react'

import Td from '@components/Td'
import Tr from '@components/Tr'

interface EmptyRowProps {
	message: string
	tdAmount: number
}

export default function EmptyRow({ message, tdAmount }: EmptyRowProps) {
	return (
		<Tr>
			<Td>{message}</Td>
			{new Array(tdAmount).fill('').map((_, idx) => (
				<td key={idx} />
			))}
		</Tr>
	)
}
