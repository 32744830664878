import CSS from 'csstype'

type StyleAlias = 'my'

export default function getCssStyle(
	...rules: Array<{
		name?: keyof CSS.StandardProperties
		alias?: StyleAlias
		value: string
	}>
) {
	const styles: Partial<Record<keyof CSS.StandardProperties, string>> = {}

	rules.forEach(({ alias, name, value }) => {
		switch (alias) {
			case 'my': {
				styles.margin = `${value} 0`

				break
			}
			default: {
				break
			}
		}

		if (alias) return

		if (name) styles[name] = value
	})

	return styles as Record<string, string>
}
