import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MarkInput, Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { TrashIcon } from '@heroicons/react/outline'
import { commentsQuestionsApi } from '@services/commentsQuestionsApi'
import { loadingModal, openAlertModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function CommentsQuestions() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Comentarios de Questões', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = commentsQuestionsApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteCommentsQuestions,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = commentsQuestionsApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/comentarios-questoes?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteErrorQuestion))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/comentarios-questoes',
	})

	const [checkQuestion, { isSuccess }] =
		commentsQuestionsApi.useCheckQuestionMutation()

	// eslint-disable-next-line no-shadow
	function onSubmit(data: { id: number; isAccepted: boolean }) {
		checkQuestion({
			id: data.id,
			isAccepted: data.isAccepted,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				notificationCreators.show(successMessages.updatedStatusComentModule)
			)
		}
	}, [isSuccess, dispatch, navigate])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Comentarios de Questões' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Comentário de Questão'
					/>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{ label: 'aluno' },
							{ label: 'ID Questão' },
							{ label: 'Questão' },
							{ label: 'Comentário' },
							{ label: 'Data Cadastro' },
							{ label: 'Aprovar' },
							{ label: 'ações' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map((commentQuestion) => (
								<Tr key={commentQuestion.id}>
									<Td>{commentQuestion.id}</Td>
									<Td>{commentQuestion.client.name}</Td>
									<Td>{commentQuestion.questionId}</Td>
									<Td>{commentQuestion.question.title}</Td>
									<Td>
										<div
											dangerouslySetInnerHTML={{ __html: commentQuestion.text }}
										/>
									</Td>
									<Td>
										{Formatters.createdAt(commentQuestion.createdAt as string)}
									</Td>
									<Td>
										<MarkInput
											type='checkbox'
											itens={[{ id: '1', title: '' }]}
											defaultChecked={Boolean(commentQuestion.isAccepted)}
											multiCheckbox
											onChange={(e) =>
												onSubmit({
													id: commentQuestion.id,
													isAccepted: e.currentTarget.checked,
												})
											}
										/>
									</Td>

									<Td>
										<Button
											className='!px-2 !py-2'
											leftIcon={<TrashIcon />}
											disabled={false}
											variant='outline'
											onClick={() => {
												const removeCommentsQuestions = {
													title: 'Remover Erro de Questão',
													message:
														'Tem certeza que deseja remover o erro da Questão?',
													buttonLabel: 'Remover Erro da Questão',
													onClick: () =>
														deleteCommentsQuestions({
															id: commentQuestion.id,
														}),
												}

												dispatch(openAlertModal(removeCommentsQuestions))
											}}
										>
											<></>
										</Button>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={8} message='Nenhum comentário encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />

				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}

export default CommentsQuestions
