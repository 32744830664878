import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, NavTab } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import Loading from '@pages/Loading'
import { disciplineApi } from '@services/DisciplineApi'
import { closeModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import LinkManager from '@utils/helpers/LinkManager'
import useCreateBreadcrumb, {
	createBreadcrumbLink,
} from '@utils/hooks/useBreadcrumb'

import QuizTable from '../Quiz/QuizTable'
import EditSubSubject from './EditSubSubject'

function IndividualSubSubject() {
	const { id, subjectId, subSubjectId } = useParams<{
		id: string
		subjectId: string
		subSubjectId: string
	}>()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { data: disciplineInfo, isLoading: isLoadingDiscipline } =
		disciplineApi.useShowDisciplineQuery({
			id: Number(id) as number,
		})

	const { data: subjectInfo, isLoading: isLoadingSubject } =
		disciplineApi.useShowSubjectOneQuery({
			id: Number(subjectId) as number,
		})

	const { data: subSubjectInfo, isLoading: isLoadingSubSubject } =
		disciplineApi.useShowSubSubjectOneQuery({
			id: Number(subSubjectId) as number,
		})

	const baseUrl = `/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Disciplina`, `/disciplina`, 1),
			createBreadcrumbLink(`${disciplineInfo?.name}`, `/disciplina/${id}/`, 2),
			createBreadcrumbLink(
				`${subjectInfo?.name}`,
				`/disciplina/${id}/assunto/${subjectId}`,
				3
			),
			createBreadcrumbLink(`${subSubjectInfo?.name}`, baseUrl, 4),
		]
	}, [baseUrl, disciplineInfo, subjectInfo, subSubjectInfo, id, subjectId])
	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [{ title: 'Questões', to: createLink('') }]

	const components = {
		[createLink('')]: <QuizTable />,
	}

	// Modal Delete
	const [deleteSubSubject, setDeleteSubSubject] = useState(false)

	// const disableSubSubject = {
	// 	title: 'Desativar Sub Assunto',
	// 	message: 'Tem certeza que deseja desativar o sub assunto?',
	// 	buttonLabel: 'Desativar Sub Assunto',
	// 	onClick: () => setDeleteSubSubject(true),
	// }

	useEffect(() => {
		if (deleteSubSubject) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.deleteSubSubject))
			setDeleteSubSubject(false)
		}
	}, [deleteSubSubject, dispatch])

	const [duplicatedSubSubject, setDuplicatedSubSubject] = useState(false)

	// const duplicateSubSubject = {
	// 	title: 'Duplicar Sub Assunto',
	// 	message: 'Tem certeza que deseja duplicar a assunto?',
	// 	buttonLabel: 'Duplicar Sub Assunto',
	// 	onClick: () => {
	// 		setDuplicatedSubSubject(true)
	// 	},
	// }

	useEffect(() => {
		if (duplicatedSubSubject) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.duplicatedSubSubject))
			setDuplicatedSubSubject(false)
		}
	}, [duplicatedSubSubject, dispatch])

	const options = [
		{
			label: 'Editar Assunto',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editSubSubject',
						modalInfo: subSubjectId,
					})
				),
		},
	]

	if (isLoadingDiscipline || isLoadingSubject || isLoadingSubSubject) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${subSubjectInfo?.name}`}
					info={`Alterado pela última vez em ${Formatters.date(
						subSubjectInfo?.updatedAt || '',
						2
					)}`}
					options={options}
				/>

				<div className='flex justify-between items-end'>
					<NavTab links={links} />
					<Button onClick={() => navigate(`${baseUrl}/criar-questao`)}>
						Adicionar Questão
					</Button>
				</div>
				{components[pathname]}
			</Template>

			<EditSubSubject />
		</>
	)
}

export default IndividualSubSubject
