import React from 'react'

import { Menu } from '@headlessui/react'

const styles = {
	menu: 'relative inline-block text-left z-10',
	button:
		'inline-flex cursor-pointer justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary',
}

interface OptionsProps {
	options: Array<{
		onClick: CallableFunction
		label: string
	}>
}

export default function Option({ options }: OptionsProps) {
	return (
		<Menu as='div' className={styles.menu}>
			<div
				onClick={(e) => {
					e.preventDefault()

					options[0].onClick()
				}}
				className={`${styles.button} option-button`}
			>
				{options[0].label}
			</div>
		</Menu>
	)
}
