/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { InputGroup, TextEditor } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { errorsModuleApi } from '@services/errosModuleApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	answer: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function AnswerModule() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<Inputs>({
		mode: 'onBlur',
	})

	const [editAnswer, { isSuccess }] = errorsModuleApi.useEditMutation()

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		editAnswer({
			id,
			answer: data.answer,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedErrorModule))
		}
	}, [isSuccess, dispatch, navigate, id])

	const [getResponse, { data }] = errorsModuleApi.useShowMutation()

	useEffect(() => {
		if (id && modalName === 'answerModule') {
			getResponse({
				id,
			})
		}
	}, [id, getResponse, modalName])

	const [answer, setResponse] = useState(data?.response)

	useEffect(() => {
		if (data) {
			setResponse(data?.response)
			setValue('answer', data.response)
		}
	}, [data, setValue])

	return (
		<Modal name='answerModule'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Resposta</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para responder
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name='answer'
						control={control}
						render={({ field: { onBlur } }) => (
							<InputGroup
								className={`mt-6 ${errors.answer && 'error-textBox'}`}
								label='Resposta'
								id='answer'
							>
								<TextEditor
									onChange={(e) => {
										setResponse(e)
										setValue('answer', e)
									}}
									onBlur={onBlur}
									value={answer}
								/>
							</InputGroup>
						)}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' disabled={!modalName}>
							Responder
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
