import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Select, { MultiValue } from 'react-select'

import { Input, InputGroup } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import SelectInput, { Option } from '@components/SelectInput'
import Template from '@components/Template'
import TextEditor from '@components/TextEditor'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { editQuizSchema } from '@schemas/addQuiz.schema'
import { bancaApi } from '@services/BancaApi'
import { disciplineApi } from '@services/DisciplineApi'
import { institutionApi } from '@services/InstitutionApi'
import { occupationApi } from '@services/OccupationApi'
import { yearApi } from '@services/YearApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

type Inputs = {
	subSubject: MultiValue<{ value: string | number; label: string }>
	year: MultiValue<{ value: string | number; label: string }>
	occupation: MultiValue<{ value: string | number; label: string }>
	banca: MultiValue<{ value: string | number; label: string }>
	institution: MultiValue<{ value: string | number; label: string }>
	title: string
	enunciado: string
	comment: string
	trueOrFalse: number
}

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

function EditQuiz() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id, subjectId, subSubjectId, idQuiz } = useParams<{
		id: string
		subjectId: string
		subSubjectId: string
		idQuiz: string
	}>()

	const [subSubject, setSubSubject] = useState<
		Array<{ option: string; value: number }>
	>([])

	const [year, setYear] = useState<Array<{ option: string; value: number }>>([])

	const [occupation, setOccupation] = useState<
		Array<{ option: string; value: number }>
	>([])

	const [banca, setBanca] = useState<Array<{ option: string; value: number }>>(
		[]
	)

	const [institution, setInstitution] = useState<
		Array<{ option: string; value: number }>
	>([])

	const { data: disciplineInfo } = disciplineApi.useShowDisciplineQuery({
		id: Number(id) as number,
	})

	const { data: subjectInfo } = disciplineApi.useShowSubjectOneQuery({
		id: Number(subjectId) as number,
	})

	const { data: subSubjectInfo } = disciplineApi.useShowSubSubjectOneQuery({
		id: Number(subSubjectId) as number,
	})

	const baseUrl = `/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/editar-questao/${idQuiz}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Disciplina`, `/disciplina/`, 1),
			createBreadcrumbLink(`${disciplineInfo?.name}`, `/disciplina/${id}/`, 2),
			createBreadcrumbLink(
				`${subjectInfo?.name}`,
				`/disciplina/${id}/assunto/${subjectId}`,
				3
			),
			createBreadcrumbLink(
				`${subSubjectInfo?.name}`,
				`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`,
				4
			),
			createBreadcrumbLink(`Editar Questão`, baseUrl, 5),
		]
	}, [
		baseUrl,
		disciplineInfo?.name,
		id,
		subSubjectId,
		subSubjectInfo?.name,
		subjectId,
		subjectInfo?.name,
	])

	const pages = useCreateBreadcrumb(allPages)

	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
		reset,
		setValue,
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(editQuizSchema),
	})

	const [getQuiz, { data: quiz }] = disciplineApi.useShowQuizMutationMutation()

	const [editQuiz, { isSuccess }] = disciplineApi.useEditQuizMutation()

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const dataQuiz = {
			id: Number(idQuiz),
			title: data.title,
			description: data.enunciado,
			explanation: data.comment,
			isTrue: !!data.trueOrFalse,
			submatterIds: data.subSubject.map((v) => v.value as number) || [],
			yearIds: data.year.map((v) => v.value as number) || [],
			positionIds: data.occupation.map((v) => v.value as number) || [],
			juryIds: data.banca.map((v) => v.value as number) || [],
			institutionIds: data.institution.map((v) => v.value as number) || [],
		}

		editQuiz(dataQuiz)
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			navigate(
				`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`
			)
			dispatch(notificationCreators.show(successMessages.updatedQuiz))
		}
	}, [isSuccess, dispatch, navigate, id, subjectId, subSubjectId])

	useEffect(() => {
		if (idQuiz) {
			getQuiz({ id: Number(idQuiz) as number })
		}
	}, [idQuiz, getQuiz])

	const { data: dataSubSubject } = disciplineApi.useListSubSubjectQuery({
		matterId: subjectId as string,
	})

	const { data: dataYear } = yearApi.useListQuery({ page: 1, limit: 100000 })

	const { data: dataOccupation } = occupationApi.useListQuery({
		page: 1,
		limit: 100000,
	})

	const { data: dataBanca } = bancaApi.useListQuery({ page: 1, limit: 100000 })

	const { data: dataInstitution } = institutionApi.useListQuery({
		page: 1,
		limit: 100000,
	})

	const [enunciado, setEnunciado] = useState(quiz?.description)
	const [comment, setComment] = useState(quiz?.explanation)

	useEffect(() => {
		const newSubSubject: typeof subSubject = []
		const newYear: typeof year = []
		const newOccupation: typeof occupation = []
		const newBanca: typeof banca = []
		const newIntitution: typeof institution = []

		if (quiz?.subMatters && quiz.subMatters.length > 0) {
			quiz.subMatters.forEach((e) => {
				newSubSubject.push({
					option: e.name,
					value: e.id as number,
				})
			})
		}

		if (quiz?.filters.yearFilters && quiz.filters.yearFilters.length > 0) {
			quiz.filters.yearFilters.forEach((e) => {
				newYear.push({
					option: e.name,
					value: e.id as number,
				})
			})
		}

		if (
			quiz?.filters.positionFilters &&
			quiz.filters.positionFilters.length > 0
		) {
			quiz.filters.positionFilters.forEach((e) => {
				newOccupation.push({
					option: e.name,
					value: e.id as number,
				})
			})
		}

		if (quiz?.filters.juryFilters && quiz.filters.juryFilters.length > 0) {
			quiz.filters.juryFilters.forEach((e) => {
				newBanca.push({
					option: e.name,
					value: e.id as number,
				})
			})
		}

		if (
			quiz?.filters.institutionFilters &&
			quiz.filters.institutionFilters.length > 0
		) {
			quiz.filters.institutionFilters.forEach((e) => {
				newIntitution.push({
					option: e.name,
					value: e.id as number,
				})
			})
		}

		setSubSubject(newSubSubject)
		setValue('trueOrFalse', quiz?.isTrue ? quiz?.isTrue : 0)
		setYear(newYear)
		setOccupation(newOccupation)
		setBanca(newBanca)
		setInstitution(newIntitution)
	}, [quiz, setValue])

	useEffect(() => {
		setValue(
			'subSubject',
			subSubject?.map((e) => ({ value: e.value, label: e.option }))
		)
		setValue(
			'year',
			year?.map((e) => ({ value: e.value, label: e.option }))
		)
		setValue(
			'occupation',
			occupation?.map((e) => ({ value: e.value, label: e.option }))
		)
		setValue(
			'banca',
			banca?.map((e) => ({ value: e.value, label: e.option }))
		)
		setValue(
			'institution',
			institution?.map((e) => ({ value: e.value, label: e.option }))
		)
		if (quiz) {
			setValue('title', quiz.title)
			setEnunciado(quiz?.description)
			setComment(quiz?.explanation)
			setValue('enunciado', quiz.description)
			setValue('comment', quiz.explanation)
		}
	}, [banca, institution, occupation, quiz, setValue, subSubject, year])

	const typeOptions = useMemo(
		() => [
			{ option: 'VERDADEIRO', value: 1 },
			{ option: 'FALSO', value: 0 },
		],
		[]
	)
	const onTypeChange = useCallback(
		(v: Option) => setValue('trueOrFalse', Number(v.value)),
		[setValue]
	)

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Editar Questão' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='grid grid-cols-2 gap-4'>
						<InputGroup label='Sub Assunto' id='subSubjectSelect'>
							<Controller
								control={control}
								name='subSubject'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('subSubject', v)}
											options={
												dataSubSubject?.data
													? dataSubSubject.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											defaultValue={
												subSubject.length > 0
													? subSubject.map(
															({ option, value: submatterId }) => ({
																value: submatterId as number,
																label: option,
															})
													  )
													: undefined
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Ano' id='year'>
							<Controller
								control={control}
								name='year'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('year', v)}
											options={
												dataYear?.data
													? dataYear.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											defaultValue={
												year.length > 0
													? year.map(({ option, value: submatterId }) => ({
															value: submatterId as number,
															label: option,
													  }))
													: undefined
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Cargo' id='occupation'>
							<Controller
								control={control}
								name='occupation'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('occupation', v)}
											options={
												dataOccupation?.data
													? dataOccupation.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											defaultValue={
												occupation.length > 0
													? occupation.map(
															({ option, value: submatterId }) => ({
																value: submatterId as number,
																label: option,
															})
													  )
													: undefined
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Banca' id='banca'>
							<Controller
								control={control}
								name='banca'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('banca', v)}
											options={
												dataBanca?.data
													? dataBanca.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											defaultValue={
												banca.length > 0
													? banca.map(({ option, value: submatterId }) => ({
															value: submatterId as number,
															label: option,
													  }))
													: undefined
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Instituição' id='institution'>
							<Controller
								control={control}
								name='institution'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('institution', v)}
											options={
												dataInstitution?.data
													? dataInstitution.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											defaultValue={
												institution.length > 0
													? institution.map(
															({ option, value: institutionId }) => ({
																value: institutionId as number,
																label: option,
															})
													  )
													: undefined
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Nome da disciplina' id='name'>
							<Input
								{...register('title')}
								type='text'
								isValid={inputIsValid(errors.title, touchedFields.title)}
							/>
						</InputGroup>
					</div>

					<Controller
						name='enunciado'
						control={control}
						render={({ field: { onBlur } }) => (
							<InputGroup
								className={`mt-6 ${errors.enunciado && 'error-textBox'}`}
								label='Conteúdo'
								id='enunciado'
							>
								<TextEditor
									onChange={(e) => {
										setEnunciado(e)
										setValue('enunciado', e)
									}}
									onBlur={onBlur}
									value={enunciado}
								/>
							</InputGroup>
						)}
					/>

					<Controller
						name='comment'
						control={control}
						render={({ field: { onBlur } }) => (
							<InputGroup
								className={`mt-6 ${errors.comment && 'error-textBox'}`}
								label='Conteúdo'
								id='comment'
							>
								<TextEditor
									onChange={(e) => {
										setComment(e)
										setValue('comment', e)
									}}
									onBlur={onBlur}
									value={comment}
								/>
							</InputGroup>
						)}
					/>
					<div className='flex max-w-[50%]'>
						<InputGroup
							className='mt-6'
							label='Verdadeiro/Falso'
							id='trueOrFalse'
						>
							<SelectInput
								btnClass='px-[13px] py-[8px] w-[150px]'
								options={typeOptions}
								selectedValue={quiz?.isTrue}
								onChange={onTypeChange}
							/>
						</InputGroup>
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button
							variant='outline'
							onClick={() =>
								navigate(
									`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`
								)
							}
						>
							Cancelar
						</Button>
						<Button type='submit'>Editar Questão</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default EditQuiz
