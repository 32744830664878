import { BASE_URL } from '@constants/api'
import {
	AddPdfRequest,
	AddPdfResponse,
	EditPdfRequest,
	ListPdfRequest,
	ListPdfResponse,
	ShowPdfRequest,
	ShowPdfResponse,
} from '@models/AddPdfRequest'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const pdfApi = createApi({
	reducerPath: 'pdfApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListPdfResponse, ListPdfRequest>({
			query: ({ search = '', page }) => {
				return {
					url: `/files?search=${search}&page=${page}`,
					method: 'GET',
				}
			},
		}),
		listFiltered: builder.mutation<ListPdfResponse, ListPdfRequest>({
			query: ({ search = '', page }) => {
				return {
					url: `/files?search=${search}&page=${page}`,
					method: 'GET',
				}
			},
		}),
		add: builder.mutation<AddPdfResponse, AddPdfRequest>({
			query: ({ pdf }) => {
				const formData = new FormData()

				pdf.forEach((pdfFile, i) => {
					formData.append(`pdf[${i}]`, pdfFile)
				})

				return {
					url: '/libraries/upload/pdf',
					body: formData,
					method: 'POST',
				}
			},
		}),
		show: builder.mutation<ShowPdfResponse, ShowPdfRequest>({
			query: ({ id }) => {
				return {
					url: `/files/${id}}`,
					method: 'GET',
				}
			},
		}),
		edit: builder.mutation<AddPdfResponse, EditPdfRequest>({
			query: ({ pdf, id }) => {
				const formData = new FormData()

				formData.append('pdf', pdf)

				return {
					url: `/files/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
		}),
	}),
})
