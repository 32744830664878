import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { CustomLinkProps } from '@models/link.model'
import { disciplineApi } from '@services/DisciplineApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddDiscipline from '../AddDiscipline'
import EditDiscipline from '../EditDiscipline'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function CustomLink({ id, children, ...props }: CustomLinkProps) {
	const { search } = useLocation()

	return (
		<Link to={`/disciplina/${id}`} state={{ prevQs: search }} {...props}>
			{children}
		</Link>
	)
}

function List() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Disciplina', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = disciplineApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteDiscipline,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = disciplineApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/disciplina?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteDiscipline))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/disciplina',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Disciplina' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Disciplina'
					/>

					<Button
						onClick={() => dispatch(openModal({ name: 'addDiscipline' }))}
					>
						Adicionar Disciplina
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table th={[{ label: 'id' }, { label: 'nome' }, { label: 'ações' }]}>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(({ id: disciplineId, name }) => (
								<Tr key={disciplineId}>
									<Td>{disciplineId}</Td>
									<Td hasLink className='w-[75%]'>
										<CustomLink id={disciplineId}>
											<Flex className='items-center gap-4'>{name}</Flex>
										</CustomLink>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editDiscipline',
															modalInfo: disciplineId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeDiscipline = {
														title: 'Remover Disciplina',
														message:
															'Tem certeza que deseja remover a disciplina?',
														buttonLabel: 'Remover Disciplina',
														onClick: () =>
															deleteDiscipline({
																id: disciplineId,
															}),
													}

													dispatch(openAlertModal(removeDiscipline))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhuma disciplina encontrada' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
			<AddDiscipline />
			<EditDiscipline />
		</>
	)
}

export default List
