import { BASE_URL } from '@constants/api'
import {} from '@models/user.model'
import {
	AddClassClientRequest,
	AddClientRequest,
	GiveAccessClientRequest,
	ListClassClientRequest,
	ListClientActiveResponse,
	ListClientRequest,
	ListClientResponse,
	ShowClientResponse,
	UpdateClassClientRequest,
	UpdateClientRequest,
} from '@models/client.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const clientApi = createApi({
	reducerPath: 'clientApi',
	tagTypes: ['Clientes'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListClientResponse, ListClientRequest>({
			query: ({ page, search = '' }) => ({
				url: `/clients?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Clientes'],
		}),
		listActive: builder.query<ListClientActiveResponse, ListClientRequest>({
			query: ({ page, search = '' }) => ({
				url: `active/clients?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Clientes'],
		}),
		showClient: builder.query<ShowClientResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/clients/${id}`,
				method: 'GET',
			}),
			providesTags: ['Clientes'],
		}),
		showClass: builder.mutation<ListClientResponse, ListClassClientRequest>({
			query: ({ id, page = '1', search = '' }) => ({
				url: `/classes?limit=1000&page=${page}&search=${search}&clientId=${id}`,
				method: 'GET',
			}),
		}),
		showClientActive: builder.query<ShowClientResponse, { id: string }>({
			query: ({ id }) => ({
				url: `active/clients/${id}`,
				method: 'GET',
			}),
		}),
		show: builder.mutation<ShowClientResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/clients/${id}`,
				method: 'GET',
			}),
		}),
		showClientMutation: builder.mutation<ShowClientResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/clients/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddClientRequest>({
			query: ({
				image,
				classes,
				name,
				email,
				password,
				cpfCnpj,
				phone,
				whatsapp,
				cep,
				number,
				district,
				city,
				address,
				state,
			}) => {
				const formData = new FormData()

				formData.append('name', name)
				formData.append('email', email)
				formData.append('password', password)
				formData.append('cpfCnpj', cpfCnpj)
				formData.append('whatsapp', whatsapp)
				formData.append('cep', cep)
				formData.append('number', number)
				formData.append('phone', phone)
				formData.append('district', district)
				formData.append('city', city)
				formData.append('state', state)
				formData.append('address', address)
				formData.append('image', image)

				classes.forEach((classe, i) => {
					formData.append(`classes[${i}]`, classe)
				})

				return {
					url: `/clients`,
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Clientes'],
		}),
		addClass: builder.mutation<void, AddClassClientRequest>({
			query: ({ id, ...data }) => ({
				url: `/clients/${id}`,
				body: { ...data },
				method: 'PUT',
			}),
			invalidatesTags: ['Clientes'],
		}),
		edit: builder.mutation<void, UpdateClientRequest>({
			query: ({ id, ...data }) => ({
				url: `/clients/${id}`,
				body: {
					...data,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Clientes'],
		}),
		editClassClient: builder.mutation<void, UpdateClassClientRequest>({
			query: ({ id, ...data }) => ({
				url: `classes/client/${id}`,
				body: {
					...data,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Clientes'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/clients/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Clientes'],
		}),
		deleteClassClient: builder.mutation<void, { id: string; clientId: string }>(
			{
				query: ({ id, clientId }) => ({
					url: `classes/client/${id}`,
					body: {
						clientId,
					},
					method: 'DELETE',
				}),
				invalidatesTags: ['Clientes'],
			}
		),
		migrateData: builder.mutation<void, { email?: string }>({
			query: ({ email }) => ({
				url: `/import/client`,
				body: {
					clientEmail: email,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Clientes'],
		}),
		giveAccess: builder.mutation<void, GiveAccessClientRequest>({
			query: ({ id }) => ({
				url: `/clients/${id}/giveAccess`,
				method: 'POST',
			}),
			invalidatesTags: ['Clientes'],
		}),
	}),
})
