/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Input, InputGroup, MarkInput } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { clientApi } from '@services/ClientApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	expirationDate: string
	isRefunded: string
	registerDate: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditClass() {
	const dispatch = useAppDispatch()
	const { id: idUser } = useParams<{ id: string }>()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [editClass, { isLoading, isSuccess }] =
		clientApi.useEditClassClientMutation()

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		register,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const newData = {
			clientId: idUser,
			expirationDate: data.expirationDate,
			isRefunded: !!data.isRefunded,
			registerDate: data.registerDate,
		}

		editClass({
			id,
			...newData,
		})

		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedClasses))
		}
	}, [isSuccess, dispatch])

	return (
		<Modal name='editClass'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Turma</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar essa turma
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='grid grid-cols-2 gap-4 mt-5'>
						<InputGroup label='Data de expiração' id='expirationDate'>
							<Input
								isValid={inputIsValid(
									errors.expirationDate,
									touchedFields.expirationDate
								)}
								{...register('expirationDate')}
								type='date'
								maxLength={4}
							/>
						</InputGroup>

						<InputGroup label='Data de registro' id='registerDate'>
							<Input
								isValid={inputIsValid(
									errors.registerDate,
									touchedFields.registerDate
								)}
								{...register('registerDate')}
								type='date'
								maxLength={4}
							/>
						</InputGroup>

						<Controller
							control={control}
							name='isRefunded'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											// defaultChecked={Boolean(data?.isFree)}
											itens={[{ id: '1', title: 'Reembolsado' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'isRefunded',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>
					</div>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>
						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Turma
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
