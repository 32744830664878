import React from 'react'

import InfoBox from '@components/InfoBox'
import { ShowClientResponse } from '@models/client.model'
import { formatCpfCnpj } from '@utils/helpers/CpfCnpjFormated'
import Formatters from '@utils/helpers/Formatters'

interface ClientInfo {
	clientInfo?: ShowClientResponse
}

export function InfoClient({ clientInfo }: ClientInfo) {
	const infos = [
		{
			label: 'Nome',
			item: <p>{clientInfo?.name || 'Não informado'}</p>,
		},
		{
			label: 'Email',
			item: <p>{clientInfo?.email || 'Não informado'}</p>,
		},
		{
			label: 'CPF/CNPJ',
			item: (
				<p>
					{clientInfo?.cpfCnpj
						? formatCpfCnpj(clientInfo?.cpfCnpj)
						: 'Não informado'}
				</p>
			),
		},
		{
			label: 'Criado em',
			item: <p>{Formatters.createdAt(clientInfo?.createdAt as string)}</p>,
		},
		{
			label: 'Atualizado em',
			item: <p>{Formatters.createdAt(clientInfo?.updatedAt as string)}</p>,
		},
	]

	const contact = [
		{
			label: 'Telefone',
			item: <p>{clientInfo?.phone || 'Não informado'}</p>,
		},
		{
			label: 'WhatsApp',
			item: <p>{clientInfo?.whatsapp || 'Não informado'}</p>,
		},
		{
			label: 'Estado',
			item: <p>{clientInfo?.address?.uf || 'Não informado'}</p>,
		},
		{
			label: 'Cidade',
			item: <p>{clientInfo?.address?.city || 'Não informado'}</p>,
		},
		{
			label: 'Bairro',
			item: <p>{clientInfo?.address?.district || 'Não informado'}</p>,
		},
		{
			label: 'Endereço',
			item: <p>{clientInfo?.address?.street || 'Não informado'}</p>,
		},
		{
			label: 'Numero',
			item: <p>{clientInfo?.address?.number || 'Não informado'}</p>,
		},
		{
			label: 'Cep',
			item: (
				<p>
					{clientInfo?.address?.cep?.replace(/(\d{5})(\d)/, '$1-$2') ||
						'Não informado'}
				</p>
			),
		},
	]

	return (
		<>
			<div className='flex justify-between gap-4'>
				<InfoBox
					title='Informações'
					subtitle=''
					itens={infos}
					width='full'
					myHeader='py-5'
					mtBox='mt-0'
					mbBox='mb-10'
				/>
				<InfoBox
					title='Contato'
					subtitle=''
					itens={contact}
					width='full'
					myHeader='py-5'
					mtBox='mt-0'
					mbBox='mb-10'
				/>
			</div>
		</>
	)
}
