import { ModalProps } from '@models/modal.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Options extends ModalProps {
	type: 'error' | 'success' | 'alert' | 'alert-green' | 'input' | null
}

interface ModalState {
	modalName?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	modalInfo: any
	options?: Options
	inputValue?: string
}

const initialState: ModalState = {
	modalName: '',
	modalInfo: undefined,
	options: {
		buttonLabel: 'Confirmar',
		message: '',
		title: '',
		type: null,
		onClick: undefined,
		buttonLoading: false,
	},
	inputValue: '',
}

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal: (
			state,
			{
				payload: { name, modalInfo },
			}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
			PayloadAction<{ name: string; modalInfo?: any }>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			state.modalName = name
			state.modalInfo = modalInfo
		},
		openAlertModal: (
			state,
			{
				payload: { type, title, buttonLabel, message, onClick },
			}: PayloadAction<ModalProps & { type?: 'alert' | 'alert-green' }>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			const options: Options = {
				type: type || 'alert',
				title,
				message,
				buttonLabel,
				onClick,
			}

			state.modalName = ''

			state.options = { ...initialState.options, ...options }
		},
		openErrorModal: (
			state,
			{
				payload: { title, buttonLabel, message, onClick },
			}: PayloadAction<ModalProps>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			const options: Options = {
				type: 'error',
				title,
				message,
				buttonLabel,
				onClick,
			}

			state.options = { ...initialState.options, ...options }
		},
		openSuccessModal: (
			state,
			{
				payload: { title, buttonLabel, message, onClick },
			}: PayloadAction<ModalProps>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			const options: Options = {
				type: 'success',
				title,
				message,
				buttonLabel,
				onClick,
			}

			state.modalName = ''

			state.options = { ...initialState.options, ...options }
		},
		closeModal: (state) => {
			if (state.modalName) {
				state.modalName = ''
			}

			if (state.options) {
				state.options.type = null
			}

			document.getElementsByTagName('body')[0].style.overflowY = 'auto'
		},
		closeErrorModal: (state) => {
			if (state.options?.type === 'error') {
				state.options.type = null
			}
			document.getElementsByTagName('body')[0].style.overflowY = 'auto'
		},
		loadingModal: (
			state,
			{ payload: { loading } }: PayloadAction<{ loading: boolean }>
		) => {
			if (state.options) {
				state.options.buttonLoading = loading
			}
		},
		openInputModal: (
			state,
			{
				payload: { title, buttonLabel, inputPlaceholder, onClick },
			}: PayloadAction<ModalProps>
		) => {
			document.getElementsByTagName('body')[0].style.overflowY = 'hidden'

			const options: Options = {
				type: 'input',
				title,
				buttonLabel,
				inputPlaceholder,
				onClick,
			}

			state.modalName = 'inputModal'
			state.options = { ...initialState.options, ...options }
			state.inputValue = ''
		},
		updateInputValue: (state, { payload }: PayloadAction<string>) => {
			state.inputValue = payload
		},
	},
})

export const {
	openAlertModal,
	openErrorModal,
	openSuccessModal,
	closeErrorModal,
	closeModal,
	openModal,
	loadingModal,
	openInputModal,
	updateInputValue,
} = modalSlice.actions

export const modalReducer = modalSlice.reducer
