import React from 'react'

import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

interface InfoBoxProps {
	title: string
	subtitle: string
	mtBox?: string
	mbBox?: string
	myHeader?: string
	width: number | 'full'
	children?: React.ReactNode
	itens: Array<{ label?: string; item: React.ReactNode }>
	headerFixed?: boolean
}

const styles = {
	box: 'bg-white rounded-sm shadow-5',
	headerContainer: ' mx-6 flex justify-between items-center',
	headerContent: 'flex flex-col gap-1',
	title: 'text-lg leading-6 font-medium text-dark',
	subtitle: 'text-gray',
	itemContainer: 'border-t border-gray-200 px-6 py-5',
	itemLabel: 'text-gray-500 font-medium',
	item: 'flex justify-between',
	itemChildren: 'w-[60%] text-end',
}

export default function InfoBox({
	title,
	subtitle,
	children,
	width,
	mtBox = 'mt-4',
	myHeader = 'my-10',
	mbBox = 'mb-0',
	itens,
	headerFixed,
}: InfoBoxProps) {
	return (
		<div
			style={getCssStyle({
				name: 'width',
				value: width === 'full' ? '100%' : pixelsToRem(width),
			})}
			className={`${styles.box} ${mtBox} ${mbBox} ${
				headerFixed ? 'relative' : ''
			}`}
		>
			<div
				className={`${styles.headerContainer} ${myHeader} ${
					headerFixed
						? 'sticky top-0 bg-white w-[100%] z-[5] mx-0 px-6 border-b border-gray-200 rounded-tl-sm'
						: ','
				}`}
			>
				<div className={styles.headerContent}>
					<p className={styles.title}>{title}</p>
					<p className={styles.subtitle}>{subtitle}</p>
				</div>

				{children && <div>{children}</div>}
			</div>

			<div className={headerFixed ? 'mt-[-1px]' : ''}>
				{itens.map(({ label, item }, idx) => (
					<div key={label || idx} className={styles.itemContainer}>
						<div className={styles.item}>
							{label && <p className={styles.itemLabel}>{label}</p>}

							<div className={styles.itemChildren}>{item}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
