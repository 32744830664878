import React, { useEffect } from 'react'

import {
	Button,
	EmptyRow,
	Flex,
	Table,
	Td,
	Tr,
	Status,
	Divider,
} from '@components'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { ShowClientResponse } from '@models/client.model'
import { clientApi } from '@services/ClientApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'

import AddClass from './AddClass'
import EditClass from './EditClass'

interface ClientInfo {
	clientInfo?: ShowClientResponse
}

interface PeriodsType {
	[key: string]: string
}

const styles = {
	divider: 'mt-[32px] mb-[12px]',
}

export function ListClass({ clientInfo }: ClientInfo) {
	const dispatch = useAppDispatch()

	const [deleteClass, { isSuccess: deleteSuccess, isLoading: loadingDelete }] =
		clientApi.useDeleteClassClientMutation()

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteClasses))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	const periods: PeriodsType = {
		ANNUAL: 'Anual',
		SEMIANNUAL: 'Semestral',
		MONTHLY: 'Mensal',
		WEEKLY: 'Semanal',
		MONTHLY2: 'Bimestral',
		MONTHLY3: 'Trimestral',
		MONTHLY4: '4 Meses',
		MONTHLY5: '5 Meses',
		MONTHLY7: '7 Meses',
		MONTHLY8: '8 Meses',
		MONTHLY9: '9 Meses',
		MONTHLY10: '10 Meses',
		MONTHLY11: '11 Meses',
		ANNUAL2: '2 Anos',
	}

	return (
		<>
			<Table
				th={[
					{
						label: 'id',
					},
					{
						label: 'nome',
					},
					{ label: 'estornado' },
					{ label: 'data de expiração' },
					{ label: 'periodo' },
					{ label: 'criado em' },
					{ label: 'ações' },
				]}
			>
				{clientInfo?.clientClasses && clientInfo?.clientClasses.length > 0 ? (
					clientInfo?.clientClasses.map(
						({ class: { name, period }, ...classClient }) => (
							<Tr key={classClient.id}>
								<Td>{classClient.id}</Td>
								<Td className='w-[30%] px-6'>{name}</Td>
								<Td className='w-[20%] px-6'>
									<Status
										className='px-4 py-0'
										status={classClient?.is_refunded ? 'success' : 'danger'}
									>
										{classClient?.is_refunded ? 'Sim' : 'Não'}
									</Status>
								</Td>
								<Td className='w-[20%] px-6'>
									{Formatters.createdAt(classClient?.expiration_date)}
								</Td>
								<Td className='w-[15%] px-6'>
									{
										periods[
											classClient?.code?.type ? classClient?.code?.type : period
										]
									}
								</Td>
								<Td className='w-[20%] px-6'>
									{Formatters.createdAt(classClient?.created_at)}
								</Td>

								<Td>
									<Flex className='items-center gap-4'>
										<Button
											className='!px-2 !py-2'
											leftIcon={<PencilAltIcon />}
											disabled={false}
											variant='outline'
											onClick={() =>
												dispatch(
													openModal({
														name: 'editClass',
														modalInfo: classClient.id,
													})
												)
											}
										>
											<></>
										</Button>
										<Button
											className='!px-2 !py-2'
											leftIcon={<TrashIcon />}
											disabled={false}
											variant='outline'
											onClick={() => {
												const removeClass = {
													title: 'Remover Turma',
													message: 'Tem certeza que deseja remover a turma?',
													buttonLabel: 'Remover Turma',
													onClick: () =>
														deleteClass({
															id: classClient.id,
															clientId: clientInfo.id,
														}),
												}

												dispatch(openAlertModal(removeClass))
											}}
										>
											<></>
										</Button>
									</Flex>
								</Td>
							</Tr>
						)
					)
				) : (
					<EmptyRow tdAmount={3} message='Nenhuma turma encontrado' />
				)}
			</Table>
			<Divider className={styles.divider} />

			<AddClass />
			<EditClass />
		</>
	)
}
