import * as yup from 'yup'

export const addQuizSchema = yup.object({
	subSubjectSelect: yup.array().required(),
	yearSelect: yup.array(),
	occupationSelect: yup.array(),
	bancaSelect: yup.array(),
	institutionSelect: yup.array(),
	title: yup.string().required(),
	enunciado: yup.string().required(),
	comment: yup.string().required(),
	trueOrFalse: yup.number(),
})

export const editQuizSchema = yup.object({
	subSubject: yup.array().required(),
	year: yup.array(),
	occupation: yup.array(),
	banca: yup.array(),
	institution: yup.array(),
	title: yup.string().required(),
	enunciado: yup.string().required(),
	comment: yup.string().required(),
	trueOrFalse: yup.number(),
})
