import * as yup from 'yup'

export const addExamsSchema = yup.object({
	name: yup.string().required(),
	classesSelect: yup.array().min(1).required(),
})

export const editExamsSchema = yup.object({
	name: yup.string().required(),
	classes: yup.array().min(1).required(),
})
