/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef } from 'react'

import Input from '@components/Input'
import Spinner from '@components/Spinner'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { closeModal, updateInputValue } from '@store/ducks/modal'
import { useAppDispatch, useAppSelector } from '@store/hooks'

const styles = {
	dialog: 'relative z-10',
	background:
		'fixed inset-0 bg-black-transparent bg-opacity-75 transition-opacity',
	container: 'fixed z-10 inset-0 overflow-y-auto',
	subContainer:
		'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0',
	span: 'hidden sm:inline-block sm:align-middle sm:h-screen',
	box: 'relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6',
	boxContainer: 'sm:flex sm:items-start',
	header:
		'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
	icon: 'h-6 w-6',
	content: 'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left  w-full',
	title: 'text-lg leading-6 font-medium text-gray-900',
	messageContainer: 'mt-2',
	message: 'text-sm text-gray-500',
	footer: 'mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex justify-end',
	actionButton:
		'inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm',
	cancelButton:
		'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm',
}

export default function InputModal() {
	const dispatch = useAppDispatch()
	const { options, inputValue } = useAppSelector((state) => state.modal)

	const cancelButtonRef = useRef(null)
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(updateInputValue(e.target.value))
	}

	return (
		<Transition.Root
			show={['input'].includes(options?.type || '')}
			as={Fragment}
		>
			<Dialog
				as='div'
				className={styles.dialog}
				initialFocus={cancelButtonRef}
				onClose={() => {
					dispatch(closeModal())
				}}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className={styles.background} />
				</Transition.Child>

				<div className={styles.container}>
					<div className={styles.subContainer}>
						<span className={styles.span} aria-hidden='true'>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<Dialog.Panel className={styles.box}>
								<div className={styles.boxContainer}>
									<div className={`${styles.header} ${'bg-red-100'}`}>
										<ExclamationIcon
											className={`${styles.icon} ${'text-red-600'}`}
											aria-hidden='true'
										/>
									</div>

									<div className={styles.content}>
										<Dialog.Title as='h3' className={styles.title}>
											{options?.title}
										</Dialog.Title>
										<div className='mt-3'>
											<Input
												type='text'
												value={inputValue}
												onChange={handleInputChange}
												placeholder={options?.inputPlaceholder}
											/>
										</div>
									</div>
								</div>

								<div className={styles.footer}>
									<button
										type='button'
										className={`${
											styles.actionButton
										} ${'bg-red-600 hover:bg-red-700'}`}
										onClick={() => {
											options?.onClick && options?.onClick()

											dispatch(closeModal())
										}}
										disabled={options?.buttonLoading}
									>
										{options?.buttonLoading ? (
											<div className='flex h-full items-center justify-center'>
												<Spinner size='xs' color='white' />
											</div>
										) : (
											options?.buttonLabel
										)}
									</button>
									<button
										type='button'
										className={styles.cancelButton}
										onClick={() => {
											dispatch(closeModal())
										}}
										ref={cancelButtonRef}
										disabled={options?.buttonLoading}
									>
										Cancelar
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
