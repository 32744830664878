import { BASE_URL } from '@constants/api'
import {
	CheckModuleRequest,
	ListErrorsModuleRequest,
	ListErrorsModuleResponse,
	ShowErrorsModuleResponse,
	UpdateErrorsModuleRequest,
} from '@models/errosModule.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const errorsModuleApi = createApi({
	reducerPath: 'errorsModuleApi',
	tagTypes: ['ErrorModule'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListErrorsModuleResponse, ListErrorsModuleRequest>({
			query: ({ page = '1', search = '' }) => ({
				url: `/moduleErrors?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['ErrorModule'],
		}),
		show: builder.mutation<ShowErrorsModuleResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/moduleErrors/${id}`,
				method: 'GET',
			}),
		}),
		edit: builder.mutation<void, UpdateErrorsModuleRequest>({
			query: ({ id, ...answer }) => ({
				url: `/moduleErrors/${id}`,
				body: answer,
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorModule'],
		}),
		checkModule: builder.mutation<void, CheckModuleRequest>({
			query: ({ id, ...isCorrected }) => ({
				url: `/moduleErrors/${id}`,
				body: isCorrected,
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorModule'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/moduleErrors/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ErrorModule'],
		}),
	}),
})
