import { BASE_URL } from '@constants/api'
import {
	AddOccupationRequest,
	ListOccupationRequest,
	ListOccupationResponse,
	ShowOccupationResponse,
	UpdateOccupationRequest,
} from '@models/occupation.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const occupationApi = createApi({
	reducerPath: 'occupationApi',
	tagTypes: ['Cargos', 'Cargo'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListOccupationResponse, ListOccupationRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/filters?page=${page}&type=position&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Cargos'],
		}),
		show: builder.mutation<ShowOccupationResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddOccupationRequest>({
			query: (data) => ({
				url: `/filters`,
				body: {
					...data,
					typeId: 2,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Cargos'],
		}),
		edit: builder.mutation<void, UpdateOccupationRequest>({
			query: ({ id, ...data }) => ({
				url: `/filters/${id}`,
				body: {
					...data,
					typeId: 2,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Cargos'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Cargos'],
		}),
	}),
})
