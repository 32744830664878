import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Pagination } from '@components'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { announcementApi } from '@services/AnnouncementApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddAnnouncement from './AddAnnouncement'
import EditAnnouncement from './EditAnnouncement'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] mt-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ListAnnouncement() {
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = announcementApi.useListQuery({
		page,
		search: searchQuery,
	})

	const [
		deleteAnnouncement,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = announcementApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/comunicados?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteAnnouncement))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/comunicados',
	})

	return (
		<>
			<Flex className={styles.searchContainer}>
				<SearchInput
					placeholder='Buscar Comunicado'
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
				/>

				<Button
					onClick={() => dispatch(openModal({ name: 'addAnnouncement' }))}
				>
					Adicionar Comunicado
				</Button>
			</Flex>

			{isLoading ? (
				<div className='flex justify-center'>
					<Spinner size='md' />
				</div>
			) : (
				<Table
					th={[
						{ label: 'id' },
						{
							label: 'nome',
						},
						{ label: 'tipo' },
						{ label: 'ações' },
					]}
				>
					{data?.data && data?.data?.length > 0 ? (
						data?.data.map(({ id: announcementId, name, type }) => (
							<Tr key={announcementId}>
								<Td>{announcementId}</Td>
								<Td className='w-[60%] px-6'>{name}</Td>
								<Td>{type}</Td>
								<Td>
									<Flex className='items-center gap-4'>
										<Button
											className='!px-2 !py-2'
											leftIcon={<PencilAltIcon />}
											disabled={false}
											variant='outline'
											onClick={() =>
												dispatch(
													openModal({
														name: 'editAnnouncement',
														modalInfo: announcementId,
													})
												)
											}
										>
											<></>
										</Button>
										<Button
											className='!px-2 !py-2'
											leftIcon={<TrashIcon />}
											disabled={false}
											variant='outline'
											onClick={() => {
												const removeAnnouncement = {
													title: 'Remover Comunicado',
													message:
														'Tem certeza que deseja remover o comunicado?',
													buttonLabel: 'Remover Comunicado',
													onClick: () =>
														deleteAnnouncement({
															id: announcementId,
														}),
												}

												dispatch(openAlertModal(removeAnnouncement))
											}}
										>
											<></>
										</Button>
									</Flex>
								</Td>
							</Tr>
						))
					) : (
						<EmptyRow tdAmount={3} message='Nenhum comunicado encontrado' />
					)}
				</Table>
			)}

			<Divider className={styles.divider} />
			{data?.data && data?.data?.length > 0 && (
				<Pagination
					pageCount={data?.meta.lastPage || 0}
					maxAmount={data?.meta.total || 0}
					perPage={data?.meta.perPage || 0}
				/>
			)}
			<AddAnnouncement />
			<EditAnnouncement />
		</>
	)
}

export default ListAnnouncement
