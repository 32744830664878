/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'
import { Link } from 'react-router-dom'

import { HomeIcon } from '@heroicons/react/solid'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'
import useIsCurrentLink from '@utils/helpers/useIsCurrentLink'

type BreadcrumbProps = {
	pages?: { name: string; href: string }[]
}

interface BreadcrumbLinkProps {
	page?: { name: string; href: string }
}

const styles = {
	home: 'text-gray-400',
	homeIcon: 'flex-shrink-0 h-5 w-5',
	link: 'ml-1 font-medium',
	defaultLink: 'text-gray-500 hover:text-gray-700',
	activeLink: 'text-primary',
	name: 'capitalize',
}

function BreadcrumbLink({ page }: BreadcrumbLinkProps) {
	const match = useIsCurrentLink(page?.href)

	return (
		<Link
			to={page?.href ? page.href : '#'}
			style={getCssStyle({
				name: 'fontSize',
				value: pixelsToRem(14),
			})}
			className={`${styles.link} ${styles.name} ${
				match ? styles.activeLink : styles.defaultLink
			}`}
		>
			{page?.name}
		</Link>
	)
}

export default function Breadcrumb({ pages }: BreadcrumbProps) {
	return (
		<nav
			style={getCssStyle({
				alias: 'my',
				value: pixelsToRem(19),
			})}
			className='flex'
			aria-label='Breadcrumb'
		>
			<ol role='list' className='flex'>
				<li>
					<div>
						<Link to='/' className={styles.home}>
							<HomeIcon className={styles.homeIcon} aria-hidden='true' />
							<span className='sr-only'>Home</span>
						</Link>
					</div>
				</li>
				{pages?.map((page) => {
					return (
						<li key={page.name} className='mx-1'>
							<div className='flex items-center'>
								<svg
									className='flex-shrink-0 h-5 w-5 text-gray-300'
									xmlns='http://www.w3.org/2000/svg'
									fill='currentColor'
									viewBox='0 0 20 20'
									aria-hidden='true'
								>
									<path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
								</svg>
								<BreadcrumbLink page={page} />
							</div>
						</li>
					)
				})}
			</ol>
		</nav>
	)
}
