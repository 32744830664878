/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import { Input, InputGroup, Spinner } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddOccupationRequest } from '@models/occupation.model'
import { addOccupationsSchema } from '@schemas/addOccupation.schema'
import { occupationApi } from '@services/OccupationApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = AddOccupationRequest

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditOccupation() {
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const dispatch = useAppDispatch()

	const [getOccupation, { data: occupation, isLoading: loadingGetOccupation }] =
		occupationApi.useShowMutation()

	const [editOccupation, { isLoading, isSuccess }] =
		occupationApi.useEditMutation()

	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
		setValue,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addOccupationsSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		editOccupation({
			...data,
			id: id as number,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedOccupation))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (id && modalName === 'editOccupation') {
			getOccupation({
				id: Number(id),
			})
		}
	}, [id, modalName, getOccupation])

	useEffect(() => {
		if (occupation) {
			setValue('name', occupation?.name as string)
		}
	}, [occupation, setValue])

	if (loadingGetOccupation) {
		return (
			<Modal name='editBanca'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editOccupation'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Cargo</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar o cargo
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Cargo' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Cargo
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
