import React from 'react'

export type Status = 'success' | 'warn' | 'danger' | 'info' | 'info2'

interface StatusProps {
	children: React.ReactNode
	status: Status
	className?: string
}

const styles = {
	badge: 'rounded-[200px] max-w-max',
	default: 'text-green bg-green-transparent',
	warn: 'text-yellow bg-yellow-transparent',
	danger: 'text-red bg-red-transparent',
	info: 'text-primary bg-indigo-100',
	info2: 'text-[#05505c] bg-[#D5F5F6]',
}

export default function Status({
	children,
	status,
	className = 'px-4 py-2',
}: StatusProps) {
	let statusColor

	switch (status) {
		case 'danger': {
			statusColor = styles.danger
			break
		}
		case 'warn': {
			statusColor = styles.warn
			break
		}
		case 'info': {
			statusColor = styles.info
			break
		}
		case 'info2': {
			statusColor = styles.info2
			break
		}
		default: {
			statusColor = styles.default
		}
	}

	return (
		<div className={`${className} ${styles.badge} ${statusColor}`}>
			{children}
		</div>
	)
}
