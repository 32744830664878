import { BASE_URL } from '@constants/api'
import {
	AddAnnouncementRequest,
	ListAnnouncementClassResponse,
	ListAnnouncementRequest,
	ShowAnnouncementResponse,
	UpdateAnnouncementRequest,
} from '@models/announcement.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const announcementClassApi = createApi({
	reducerPath: 'announcementClassApi',
	tagTypes: ['Announcement'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListAnnouncementClassResponse, ListAnnouncementRequest>(
			{
				query: ({ page = '1', search = '' }) => ({
					url: `announcements?hasClass=true&page=${page}&search=${search}&limit=20`,
					method: 'GET',
				}),
				providesTags: ['Announcement'],
			}
		),
		show: builder.mutation<ShowAnnouncementResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/announcements/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddAnnouncementRequest>({
			query: (newData) => ({
				url: `/announcements`,
				body: {
					...newData,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Announcement'],
		}),
		edit: builder.mutation<void, UpdateAnnouncementRequest>({
			query: ({ id, ...data }) => ({
				url: `/announcements/${id}`,
				body: {
					...data,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Announcement'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/announcements/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Announcement'],
		}),
	}),
})
