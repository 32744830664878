import React from 'react'

import classNames from 'classnames'

interface FlexProps {
	children: React.ReactNode
	dir?: 'col' | 'col-reverse' | 'row' | 'row-reverse'
	className?: string
}

export default function Flex({ children, dir, className = '' }: FlexProps) {
	const classnames = classNames(dir && `flex-${dir}`)

	return <div className={`flex ${classnames} ${className}`}>{children}</div>
}
