import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Status } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import {
	PencilAltIcon,
	TrashIcon,
	DocumentDuplicateIcon,
} from '@heroicons/react/outline'
import { CustomLinkProps } from '@models/link.model'
import { moduleApi } from '@services/ModuleApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddModule from './module/AddModule'
import EditModule from './module/EditModule'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function CustomLink({ id, children, ...props }: CustomLinkProps) {
	const { search } = useLocation()

	return (
		<Link to={`/modulos/${id}`} state={{ prevQs: search }} {...props}>
			{children}
		</Link>
	)
}

function ListModules() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Modulos', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = moduleApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [deleteModule, { isLoading: loadingDelete, isSuccess: deleteSuccess }] =
		moduleApi.useDeleteMutation()

	const [
		duplicateModule,
		{ isLoading: loadingDuplicate, isSuccess: duplicateSuccess },
	] = moduleApi.useDuplicateModuleMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/modulos?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteModule))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDuplicate }))

		if (duplicateSuccess) {
			dispatch(notificationCreators.show(successMessages.duplicatedModule))
		}
	}, [dispatch, loadingDuplicate, duplicateSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/modulos',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Modulos' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Modulo'
					/>

					<Button onClick={() => dispatch(openModal({ name: 'addModule' }))}>
						Adicionar Modulo
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{ label: 'nome' },
							{ label: 'link' },
							{ label: 'status' },
							{ label: 'ações' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(({ id: moduleId, name, url, isFree }) => (
								<Tr key={moduleId}>
									<Td>{moduleId}</Td>
									<Td className='w-[75%] px-6'>
										<CustomLink id={moduleId}>
											<Flex className='items-center gap-4'>{name}</Flex>
										</CustomLink>
									</Td>
									<Td className='w-[75%] px-6'>
										<Flex className='items-center gap-4'>{url}</Flex>
									</Td>
									<Td>
										<Status
											className='px-4 py-0'
											status={isFree ? 'success' : 'danger'}
										>
											{isFree ? 'GRATUITO' : 'PAGO'}
										</Status>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<DocumentDuplicateIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const copyModule = {
														title: 'Duplicar Modulo',
														message:
															'Tem certeza que deseja duplicar o modulo?',
														buttonLabel: 'Duplicar Modulo',
														onClick: () =>
															duplicateModule({
																id: moduleId,
															}),
													}

													dispatch(openAlertModal(copyModule))
												}}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editModule',
															modalInfo: moduleId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeModule = {
														title: 'Remover Modulo',
														message: 'Tem certeza que deseja remover o modulo?',
														buttonLabel: 'Remover Modulo',
														onClick: () =>
															deleteModule({
																id: moduleId,
															}),
													}

													dispatch(openAlertModal(removeModule))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhuma modulo encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />

				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
			<AddModule />
			<EditModule />
		</>
	)
}

export default ListModules
