/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import { Noop } from 'react-hook-form'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { BASE_URL } from '@constants/api'
import { useAppSelector } from '@store/hooks'
import CustomUploaderTextEditor from '@utils/CustomUploaderTextEditor'
import {
	ClassicEditor,
	Essentials,
	Bold,
	MediaEmbed,
	Italic,
	Strikethrough,
	Subscript,
	Superscript,
	Font,
	Underline,
	Heading,
	Link,
	Image,
	ImageToolbar,
	ImageUpload,
	ImageCaption,
	ImageStyle,
	BlockQuote,
	List,
	Indent,
	Code,
	CodeBlock,
	ImageResize,
	Alignment,
} from 'ckeditor5'

interface TextEditorProps {
	onChange: (content: string) => void
	onBlur: Noop
	value?: string
}
export default function TextEditor({
	onChange,
	onBlur,
	value,
}: TextEditorProps) {
	const { token } = useAppSelector((state) => state.auth)

	const editorRef = useRef<null | ClassicEditor>(null)

	function uploadAdapterPlugin(editor: any) {
		editor.plugins.get('FileRepository').createUploadAdapter = (
			loader: any
		) => {
			return new CustomUploaderTextEditor(
				loader,
				`${BASE_URL}/contents/images`,
				token ?? ''
			)
		}
	}

	return (
		<div className='custom-editor'>
			<CKEditor
				onChange={(event, editor) => {
					const data = editor.getData()

					onChange(data)
				}}
				id='editor'
				editor={ClassicEditor}
				data={value || ''}
				onReady={(editor) => {
					editorRef.current = editor
					const fontFamily = editor.commands.get('fontFamily')

					fontFamily?.execute({ value: 'Arial' })
				}}
				onBlur={onBlur}
				config={{
					fontFamily: {
						options: ['Arial'],
						supportAllValues: true,
					},
					removePlugins: ['Styles'], // Remove plugins que podem causar conflitos
					extraPlugins: [uploadAdapterPlugin],
					plugins: [
						Alignment,
						Essentials,
						Bold,
						Italic,
						Strikethrough,
						Subscript,
						Superscript,
						Font,
						Heading,
						Link,
						MediaEmbed,
						Underline,
						Image,
						ImageToolbar,
						ImageUpload,
						ImageCaption,
						ImageResize,
						ImageStyle,
						BlockQuote,
						List,
						Indent,
						Code,
						CodeBlock,
					],
					toolbar: {
						items: [
							'undo',
							'redo',
							'|',
							'heading',
							'|',
							'fontfamily',
							'fontsize',
							'fontColor',
							'fontBackgroundColor',
							'Alignment',
							'|',
							'bold',
							'italic',
							'strikethrough',
							'subscript',
							'underline',
							'superscript',
							'code',
							'|',
							'link',
							'uploadImage',
							'MediaEmbed',
							'blockQuote',
							'codeBlock',
							'|',
							'bulletedList',
							'numberedList',
							'todoList',
							'outdent',
							'indent',
						],
					},
					image: {
						toolbar: [
							'imageStyle:full',
							'imageStyle:side',
							'|',
							'imageTextAlternative',
							'|',
							'resizeImage:25',
							'resizeImage:50',
							'resizeImage:75',
							'resizeImage:original',
						],
					},
					fontColor: {
						colors: [
							{
								color: '#ff1300',
								label: 'red',
							},
							{
								color: '#009966',
								label: 'green',
							},
						],
					},

					htmlSupport: {
						allow: [
							{
								name: 'iframe',
								attributes: true,
								classes: true,
								styles: true,
							},
						],
					},
					mediaEmbed: {
						previewsInData: true,
					},
					fontBackgroundColor: {
						colors: [
							{
								color: '#ff1300',
								label: 'red',
							},
							{
								color: '#009966',
								label: 'green',
							},
						],
					},
				}}
			/>
		</div>
	)
}
