import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation } from 'react-router-dom'

import { Input, InputGroup, Logo } from '@components'
import Button from '@components/Button'
import Error from '@components/Error'
import Spinner from '@components/Spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from '@schemas/login.schema'
import { authApi } from '@services/AuthApi'
import { signInSuccess } from '@store/ducks/auth'
import { openErrorModal } from '@store/ducks/modal'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import inputIsValid from '@utils/helpers/inputIsValid'

export default function Login() {
	const {
		auth: { signed },
	} = useAppSelector((state) => state)

	const dispatch = useAppDispatch()

	const [authenticate, { data, isSuccess, isError, error, status }] =
		authApi.useAuthenticateMutation()

	type Inputs = {
		email: string
		password: string
	}

	const { state } = useLocation()

	const styles = {
		header: 'flex justify-center items-center pt-[115px] pb-[50px]',
		box: 'm-auto flex justify-center w-[795px] pt-[60px] pb-[100px] bg-white rounded-sm shadow-1 mb-[20px]',
		content: 'w-[368px]',
		alert: 'flex text-danger items-center',
		alertIcon: 'mr-2',
		title: 'mt-1 mb-1.5 text-dark text-[36px] font-600 leading-108',
		button:
			'mt-4 mb-6 p-[15px] flex justify-center items-center w-full h-[54px] rounded-3xl shadow-4 font-600 text-[14px]',
		googleIcon: 'mr-[15px]',
		link: 'text-gray underline',
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(signInSuccess({ token: data?.token }))
		}
		if (error) {
			dispatch(
				openErrorModal({
					title: 'Verificar campos',
					message: 'Dados incorretos',
				})
			)
		}
	}, [isSuccess, data, dispatch, isError, error])

	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(loginSchema),
	})

	const onSubmit = handleSubmit((payload) => {
		authenticate(payload)
	})

	if (signed)
		return <Navigate to={(state as { redirect: string })?.redirect || '/'} />

	return (
		<>
			<header className={styles.header}>
				<Logo expanded className='mx-auto' />
			</header>

			<main className={styles.box}>
				<div className={styles.content}>
					<h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
						Entre na sua conta
					</h2>
					<form onSubmit={onSubmit} className='mt-8 space-y-6' method='POST'>
						<InputGroup className='mt-4' label='E-mail' id='email'>
							<Input
								isValid={inputIsValid(errors.email, touchedFields.email)}
								{...register('email')}
								type='email'
							/>
						</InputGroup>
						<InputGroup className='mt-4' label='Senha' id='password'>
							<Input
								isValid={inputIsValid(errors.password, touchedFields.password)}
								{...register('password')}
								type='password'
							/>
						</InputGroup>
						<div>
							<Button className='w-full' type='submit'>
								{status === 'pending' ? (
									<div className='flex h-full items-center justify-center'>
										<Spinner size='xs' color='white' />
									</div>
								) : (
									<p>Fazer Login</p>
								)}
							</Button>
						</div>
					</form>
				</div>
			</main>
			<Error />
		</>
	)
}
