import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { MarkInput, Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { errorsModuleApi } from '@services/errosModuleApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AnswerModule from './EditModule'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ErrorsModule() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Erros de Modulo', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = errorsModuleApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteErrorsModule,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = errorsModuleApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/erros-modulo?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteErrorModule))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/erros-modulo',
	})

	const [checkQuestion, { isSuccess }] =
		errorsModuleApi.useCheckModuleMutation()

	// eslint-disable-next-line no-shadow
	function onSubmit(data: { id: number; isCorrected: boolean }) {
		checkQuestion({
			id: data.id,
			isCorrected: data.isCorrected,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				notificationCreators.show(successMessages.updatedStatusErrorModule)
			)
		}
	}, [isSuccess, dispatch, navigate])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Erros de Modulo' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Erro de Modulo'
					/>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{ label: 'aluno' },
							{ label: 'Modulo' },
							{ label: 'Conteúdo' },
							{ label: 'Erro' },
							{ label: 'Data Cadastro' },
							{ label: 'Corrigido' },
							{ label: 'ações' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map((errorModule) => (
								<Tr key={errorModule.id}>
									<Td>{errorModule.id}</Td>
									<Td>{errorModule.client.name}</Td>
									<Td>
										<Link
											to={`/modulos/${errorModule.content.moduleId}`}
											target='_blank'
										>
											{errorModule.content.module.name}
										</Link>
									</Td>
									<Td>
										<Link
											to={`/modulos/${errorModule.content.moduleId}/conteudo/${errorModule.contentId}/editar-conteudo`}
											target='_blank'
										>
											{errorModule.content.name}
										</Link>
									</Td>
									<Td>{errorModule.text}</Td>
									<Td>
										{Formatters.createdAt(errorModule.createdAt as string)}
									</Td>
									<Td>
										<MarkInput
											type='checkbox'
											itens={[{ id: '1', title: '' }]}
											defaultChecked={Boolean(errorModule.isCorrected)}
											multiCheckbox
											onChange={(e) =>
												onSubmit({
													id: errorModule.id,
													isCorrected: e.currentTarget.checked,
												})
											}
										/>
									</Td>

									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'answerModule',
															modalInfo: errorModule.id,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeErrorsModule = {
														title: 'Remover Erro de Modulo',
														message:
															'Tem certeza que deseja remover o erro do Modulo?',
														buttonLabel: 'Remover Erro do Modulo',
														onClick: () =>
															deleteErrorsModule({
																id: errorModule.id,
															}),
													}

													dispatch(openAlertModal(removeErrorsModule))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={8} message='Nenhum erro encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />

				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
			<AnswerModule />
		</>
	)
}

export default ErrorsModule
