import { BASE_URL } from '@constants/api'
import {
	LoginRequest,
	LoginResponse,
	ProfileResponse,
} from '@models/auth.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers, { endpoint, getState }) => {
			if (endpoint.includes('/login')) {
				headers.delete('Authorization')

				return headers
			}

			const newHeaders = setAuthorization(headers, { getState, endpoint })

			return newHeaders
		},
	}),
	endpoints: (builder) => ({
		authenticate: builder.mutation<LoginResponse, LoginRequest>({
			query: (userCredentials) => ({
				url: '/login',
				method: 'POST',
				body: userCredentials,
			}),
		}),
		profile: builder.mutation<ProfileResponse, void>({
			query: () => ({
				url: `/profile`,
				method: 'GET',
			}),
		}),
	}),
})
