import React, { LegacyRef } from 'react'
import NumberFormat, {
	NumberFormatValues,
	SourceInfo,
} from 'react-number-format'

interface InputProps {
	type:
		| 'text'
		| 'password'
		| 'email'
		| 'url'
		| 'date'
		| 'datetime-local'
		| 'number'
		| 'tel'
		| 'time'
		| 'week'
	name?: string
	id?: string
	placeholder?: string
	isValid?: boolean
	format?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void
	value?: string
	min?: number | string
	maxLength?: number
	thousandSeparator?: boolean | string
	decimalSeparator?: string
	prefix?: string
	pattern?: string
	max?: number | string
	defaultValue?: string | number
	onInput?: (event: React.FormEvent<HTMLInputElement>) => void
	disabled?: boolean
}

const styles = {
	input:
		'shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]',
	valid: 'border-green',
	error: 'border-red',
	placeholder: 'text-gray',
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			type,
			id,
			placeholder,
			isValid,
			onValueChange,
			name,
			onChange,
			value,
			...props
		},
		ref
	) => {
		if (
			(props.format ||
				props.thousandSeparator ||
				props.prefix ||
				props.decimalSeparator) &&
			(type === 'text' || type === 'tel')
		) {
			return (
				<NumberFormat
					name={name}
					ref={ref as LegacyRef<NumberFormat<unknown>> | undefined}
					type={type}
					id={id}
					value={value}
					className={`${styles.input} ${(() => {
						if (isValid && isValid !== undefined) {
							return styles.valid
						}
						if (!isValid && isValid !== undefined) {
							return styles.error
						}

						return ''
					})()}`}
					onValueChange={onValueChange}
					placeholder={placeholder}
					{...props}
				/>
			)
		}

		return (
			<input
				onChange={onChange}
				ref={ref}
				type={type}
				id={id}
				name={name}
				value={value}
				className={`${styles.input} ${(() => {
					if (isValid && isValid !== undefined) {
						return styles.valid
					}
					if (!isValid && isValid !== undefined) {
						return styles.error
					}

					return ''
				})()}`}
				placeholder={placeholder}
				{...props}
			/>
		)
	}
)

export default Input
