import React, { useEffect, useRef, useState } from 'react'

import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useVirtualizer } from '@tanstack/react-virtual'
import classNames from 'classnames'

export interface AutocompleteOption {
	id: number | string
	name: string
	value?: number | string
}

interface AutocompleteProps {
	options: Array<AutocompleteOption>
	label?: string
	onChange: (value: AutocompleteOption[]) => void
	absolute?: boolean
	selectedValue?: AutocompleteOption[]
}

function VirtualizedList({ items }: { items: Array<AutocompleteOption> }) {
	const parentRef = useRef<HTMLDivElement>(null)

	const count = items.length
	const virtualizer = useVirtualizer({
		count,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 45,
		overscan: 5,
	})

	const rowItems = virtualizer.getVirtualItems()

	return (
		<div ref={parentRef}>
			<div
				style={{
					height: `${virtualizer.getTotalSize()}px`,
					width: '100%',
					position: 'relative',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						transform: `translateY(${
							rowItems && rowItems[0] && rowItems[0].start
								? rowItems[0].start
								: ''
						}px)`,
					}}
				>
					{rowItems.map((virtualRow) => (
						<Combobox.Option
							key={virtualRow.key}
							value={items?.[virtualRow.index]}
							data-index={virtualRow.index}
							ref={virtualizer.measureElement}
							className={({ active }) =>
								classNames(
									'relative cursor-default select-none py-2 pl-3 pr-9',
									active ? 'bg-primary text-white' : 'text-gray-900'
								)
							}
						>
							{({ selected }) => (
								<div>
									<span className={`block  ${selected ? 'font-semibold' : ''}`}>
										{items[virtualRow.index].name}
									</span>
									{selected && (
										<span className='absolute inset-y-0 right-0 flex items-center pr-4'>
											<CheckIcon className='h-5 w-5' aria-hidden='true' />
										</span>
									)}
								</div>
							)}
						</Combobox.Option>
					))}
				</div>
			</div>
		</div>
	)
}

export default function AutocompleteMultiple({
	options,
	label,
	onChange,
	absolute = true,
	selectedValue,
}: AutocompleteProps) {
	const [query, setQuery] = useState('')
	const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>(
		[]
	)

	useEffect(() => {
		if (selectedValue !== undefined) {
			selectedValue === selectedOptions
				? setSelectedOptions(selectedOptions)
				: setSelectedOptions(selectedValue)
		}
	}, [selectedOptions, selectedValue, setSelectedOptions])

	return (
		<Combobox
			as='div'
			onChange={(newSelectedOptions) => {
				setSelectedOptions(newSelectedOptions)
				setQuery('') // Clear the input after selecting an option
				onChange(newSelectedOptions)
			}}
			value={selectedOptions}
			multiple
		>
			<Combobox.Label className='block text-sm font-medium text-gray-700'>
				{label}
			</Combobox.Label>
			<div className='relative'>
				<Combobox.Input
					className='w-full rounded-md border border-gray-300 bg-white py-[6.5px] pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm'
					placeholder='Selecione'
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(items: AutocompleteOption[]) =>
						items?.map((item) => item.name).join(', ')
					}
				/>
				<Combobox.Button
					className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
					onClick={() => setQuery('')}
				>
					<SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
				</Combobox.Button>

				<Combobox.Options
					className={`${
						absolute ? 'absolute' : 'relative'
					} z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
				>
					<VirtualizedList
						items={
							options.filter((option) =>
								option.name.toLowerCase().includes(query.toLowerCase())
							) ?? []
						}
					/>
				</Combobox.Options>
			</div>
		</Combobox>
	)
}
