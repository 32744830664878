import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { LinkManager } from '@utils/helpers'

interface UseFiltersProps {
	search?: string
	sort?: Array<{ name: string; value: string | undefined }>
	fallbackUrl: string
}

/**
 * Salva os filtros e a pesquisa diretamente na url
 * @param options configuração do hook
 *
 * @param options.search texto a ser pesquisado
 *
 * @param options.sort array (memoizado) de filtros a serem adicionados na url
 * @example 
 * const sort = useMemo(() => [
				{ name: 'createdAt', value: orderByCreatedAt },
				{ name: 'name', value: orderByName },
		]) // vai ficar assim na url: ...url?createdAt=&name=
 *
 * @param options.fallback url que será usada caso não tenha nenhum filtro
 * @example const fallbackUrl = '/members' 
 */
export default function useFilters(options: UseFiltersProps) {
	const { search, sort, fallbackUrl } = options

	const navigate = useNavigate()

	const firstRender = useRef(true)

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false

			return
		}

		if (search) {
			const url = LinkManager.replaceUrlParam('search', search)

			navigate(`/${url}`)
		} else {
			const url = LinkManager.deleteUrlParam('search')

			navigate(url ? `/${url}` : fallbackUrl)
		}

		sort?.forEach((item) => {
			if (!item.value) {
				const url = LinkManager.deleteUrlParam(item.name)

				navigate(url ? `/${url}` : fallbackUrl)
			} else {
				const url = LinkManager.replaceUrlParam(item.name, item.value || '')

				navigate(`/${url}`)
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, sort])
}
