import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import {
	Divider,
	EmptyRow,
	Flex,
	SelectInput,
	Table,
	Td,
	Tr,
} from '@components'
import Breadcrumb from '@components/Breadcrumb'
import { CardStats } from '@components/CardStatus'
import PageTitle from '@components/PageTitle'
import Spinner from '@components/Spinner'
import Template from '@components/Template'
import '../../styles/index.css'
import { dashboardApi } from '@services/Dashboard'
import Formatters from '@utils/helpers/Formatters'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
)

const styles = {
	divider: 'mt-[32px] mb-[12px]',
}

export default function App() {
	const [yearFilter, setYearFilter] = useState<string | number>('')
	const [monthFilter, setMonthFilter] = useState<string | number>('')

	const { data, isLoading, refetch } = dashboardApi.useListQuery({
		year: yearFilter || 2023,
		month: monthFilter,
	})

	const monthOptions = [
		{ option: 'Selecione um mês', value: '' },
		{ option: 'Janeiro', value: '1' },
		{ option: 'Fevereiro', value: '2' },
		{ option: 'Março', value: '3' },
		{ option: 'Abril', value: '4' },
		{ option: 'Maio', value: '5' },
		{ option: 'Junho', value: '6' },
		{ option: 'Julho', value: '7' },
		{ option: 'Agosto', value: '8' },
		{ option: 'Setembro', value: '9' },
		{ option: 'Outubro', value: '10' },
		{ option: 'Novembro', value: '11' },
		{ option: 'Dezembro', value: '12' },
	]

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		height: 500,
		scales: {
			y: {
				beginAtZero: true,
			},
			x: {
				beginAtZero: true,
			},
		},
	}

	const labels = data?.clientsTotalPerMonth?.map((client) => {
		return monthFilter ? Formatters.createdAt(client.date, 6) : client.date

		// const date = new Date(client.date)
		// const day = date.getDate()
		// const month = date.getMonth() + 1
		// const formattedDate = `${day < 10 ? '0' : ''}${day}/${
		// 	month < 10 ? '0' : ''
		// }${month}`

		// return formattedDate
	})

	const dataT = {
		labels,
		datasets: [
			{
				label: 'Alunos por periodo',
				data: data?.clientsTotalPerMonth?.map((client) => client.total),
				borderColor: '#6BBBDC',
				backgroundColor: 'rgba(82, 176, 238, 0.166)',
				tension: 0.1,
				fill: true,
			},
		],
	}

	useEffect(() => {
		refetch()
	}, [refetch, yearFilter, monthFilter])

	return (
		<div>
			<Template>
				<Breadcrumb />
				<PageTitle title='Dashboard' />
				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<>
						<div className='grid grid-cols-4 gap-x-8'>
							<CardStats
								value={data?.clientsTotal}
								key={1}
								title='Alunos'
								icon='team'
							/>
							<CardStats
								value={data?.activePaidClientsTotal}
								key={1}
								title='Alunos pagos ativos'
								icon='teamCash'
							/>
							<CardStats
								value={data?.classesTotal}
								key={1}
								title='Turmas'
								icon='class'
							/>
							<CardStats
								value={data?.questionsTotal}
								key={1}
								title='Questões cadastradas'
								icon='question'
							/>
						</div>
						<div className='grid grid-cols-2 gap-x-8 mt-8 mb-8'>
							<div className=' px-6 py-6  rounded-xl bg-white transform  hover:scale-105 transition duration-300 h-fit border-[1px] border-[#DFDEDE]'>
								<h3 className='font-600 text-base text-[#333333] pb-5'>
									Turmas mais vendidas
								</h3>

								<Table
									css='min-h-[215px] border-none shadow-none '
									thtCss='shadow-none'
									thCss='px-[12px] text-[14px] text-[#2E3135] '
									variant='gray'
									th={[
										{ label: 'id' },
										{ label: 'turma' },
										{ label: 'quantidade de alunos' },
									]}
								>
									{data?.mostSoldClasses &&
									data?.mostSoldClasses?.length > 0 ? (
										data?.mostSoldClasses.map(({ id, name, total }) => (
											<Tr key={id}>
												<Td className='px-[12px] py-[10px]'>{id}</Td>
												<Td className='w-[60%] pl-3'>
													<Flex className='items-center gap-4'>{name}</Flex>
												</Td>
												<Td className='w-[35%] text-end pr-[18px]'>{total}</Td>
											</Tr>
										))
									) : (
										<EmptyRow tdAmount={3} message='Nenhuma dado encontrado' />
									)}
								</Table>
							</div>
							<div className=' px-6 py-6  rounded-xl bg-white transform  hover:scale-105 transition duration-300 h-fit border-[1px] border-[#DFDEDE]'>
								<h3 className='font-600 text-base text-[#333333] pb-5'>
									Últimos alunos cadastrados
								</h3>
								<Table
									th={[{ label: 'nome' }, { label: 'data do cadastrado' }]}
									css='min-h-[215px] border-none shadow-none'
									thtCss='shadow-none'
									thCss='px-[12px] text-[14px] text-[#2E3135]'
								>
									{data?.lastClients && data?.lastClients?.length > 0 ? (
										data?.lastClients.map(({ id, name, createdAt }) => (
											<Tr key={id}>
												<Td className='w-[68%] px-[12px] py-[10px]'>
													<Flex className='items-center gap-4'>{name}</Flex>
												</Td>
												<Td className='text-end pr-[16px]'>
													{Formatters.createdAt(createdAt)}
												</Td>
											</Tr>
										))
									) : (
										<EmptyRow tdAmount={3} message='Sem dados disponíveis' />
									)}
								</Table>
							</div>
						</div>

						{data?.clientsTotalPerMonth &&
							data?.clientsTotalPerMonth?.length > 0 && (
								<div className='w-full bg-white border-[1px] border-[#DFDEDE] rounded-xl px-[39px] py-[32px]'>
									<div className='flex justify-between pb-[43px]'>
										<p className='font-600 text-base text-[#333333]'>
											Alunos por período
										</p>
										<p className='flex gap-[5px] font-600 text-base text-[#333333] items-center'>
											<span className='w-4 h-4 rounded-[2px] border-[0.2px] border-[#0C0C0C] bg-[#6BBBDC]' />{' '}
											Número de alunos por período
										</p>
										<SelectInput
											btnClass='py-[8px] px-[13px] w-[100px]'
											options={data.yearInterval}
											onChange={(e) => setYearFilter(e.value)}
										/>
										<SelectInput
											btnClass='py-[8px] px-[13px] w-[158px]'
											options={monthOptions}
											onChange={(e) => setMonthFilter(e.value)}
										/>
									</div>
									<Line options={options} data={dataT} />
								</div>
							)}
					</>
				)}
				<Divider className={styles.divider} />
			</Template>
		</div>
	)
}
