import { useEffect, useRef } from 'react'

/**
 * Faz com que o callback seja executado quando search tiver um length maior ou igual
 * ao mínimo definido.
 * @param search o termo a ser pesquisado
 * @param callback função que deve ser passada memoizada, conténdo a lógica da pesquisa
 * @param minLength quantidades de caracteres mínima para acionar a busca (o padrão é 3)
 * @example const searchCallback = useCallback((value) => {
 * 		fetchSearch(value);
 * }, [])
 *
 * useInputBounce('o que o usuario quer pesquisar', searchCallback)
 */
export default function useInputBounce(
	search: string,
	callback: CallableFunction,
	minLength = 3
) {
	const inputTimeout = useRef<null | NodeJS.Timeout>(null)

	const firstRender = useRef(true)

	useEffect(() => {
		if (!firstRender.current) {
			if (inputTimeout.current) clearTimeout(inputTimeout.current)

			if (!search) {
				callback('')

				return () => undefined
			}

			if (search.length < minLength) return () => undefined

			inputTimeout.current = setTimeout(() => {
				callback(search)
			}, 400)
		}

		firstRender.current = false

		return () => {
			if (inputTimeout.current) {
				clearTimeout(inputTimeout.current)
			}
		}
	}, [search, callback, minLength])
}
