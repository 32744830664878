import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Button, NavTab } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import Loading from '@pages/Loading'
import { disciplineApi } from '@services/DisciplineApi'
import { closeModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import LinkManager from '@utils/helpers/LinkManager'
import useCreateBreadcrumb, {
	createBreadcrumbLink,
} from '@utils/hooks/useBreadcrumb'

import SubSubjectTable from '../SubSubject/SubSubjectTable'
import AddSubSubject from './AddSubSubject'
import EditSubject from './EditSubject'

function IndividualSubject() {
	const { id, subjectId } = useParams<{
		id: string
		subjectId: string
	}>()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()

	const { data: disciplineInfo, isLoading: isLoadingDiscipline } =
		disciplineApi.useShowDisciplineQuery({
			id: Number(id) as number,
		})

	const { data: subjectInfo, isLoading: isLoadingSubject } =
		disciplineApi.useShowSubjectOneQuery({
			id: Number(subjectId) as number,
		})

	const baseUrl = `/disciplina/${id}/assunto/${subjectId}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Disciplina`, `/disciplina`, 1),
			createBreadcrumbLink(`${disciplineInfo?.name}`, `/disciplina/${id}/`, 2),
			createBreadcrumbLink(`${subjectInfo?.name}`, baseUrl, 3),
		]
	}, [baseUrl, disciplineInfo, subjectInfo, id])
	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [{ title: 'Sub Assuntos', to: createLink('') }]

	const components = {
		[createLink('')]: <SubSubjectTable />,
	}

	// Modal Delete
	const [deleteSubject, setDeleteSubject] = useState(false)

	// const disableSubject = {
	// 	title: 'Desativar Assunto',
	// 	message: 'Tem certeza que deseja desativar o assunto?',
	// 	buttonLabel: 'Desativar Assunto',
	// 	onClick: () => setDeleteSubject(true),
	// }

	useEffect(() => {
		if (deleteSubject) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.deleteSubject))
			setDeleteSubject(false)
		}
	}, [deleteSubject, dispatch])

	// Modal Duplicated
	const [duplicatedSubject, setDuplicatedSubject] = useState(false)

	// const duplicateSubject = {
	// 	title: 'Duplicar Assunto',
	// 	message: 'Tem certeza que deseja duplicar a assunto?',
	// 	buttonLabel: 'Duplicar Assunto',
	// 	onClick: () => {
	// 		setDuplicatedSubject(true)
	// 	},
	// }

	useEffect(() => {
		if (duplicatedSubject) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.duplicatedSubject))
			setDuplicatedSubject(false)
		}
	}, [duplicatedSubject, dispatch])

	const options = [
		{
			label: 'Editar Assunto',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editSubject',
						modalInfo: subjectId,
					})
				),
		},
	]

	if (isLoadingDiscipline || isLoadingSubject) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${subjectInfo?.name}`}
					info={`Alterado pela última vez em ${Formatters.date(
						subjectInfo?.updatedAt || '',
						2
					)}`}
					options={options}
				/>

				<div className='flex justify-between items-end'>
					<NavTab links={links} />
					<Button
						onClick={() => dispatch(openModal({ name: 'addSubSubject' }))}
					>
						Adicionar Sub Assunto
					</Button>
				</div>
				{components[pathname]}
			</Template>

			<AddSubSubject />
			<EditSubject />
		</>
	)
}

export default IndividualSubject
