import { UserRoles } from '@models/auth.model'
import IndividualDiscipline from '@pages/Disciplines/Individual/Discipline'
import CreateQuiz from '@pages/Disciplines/Individual/Quiz/CreateQuiz'
import EditQuiz from '@pages/Disciplines/Individual/Quiz/EditQuiz'
import IndividualSubject from '@pages/Disciplines/Individual/Subject'
import IndividualSubSubject from '@pages/Disciplines/Individual/SubSubject'
import List from '@pages/Disciplines/List'

const roles: Array<UserRoles> = ['Admin', 'Question Creator']

export const disciplineRoutes = [
	{
		path: '/disciplina',
		element: List,
		isPrivate: true,
		roles,
	},
	{
		path: '/disciplina/:id',
		element: IndividualDiscipline,
		isPrivate: true,
		roles,
	},
	{
		path: '/disciplina/:id/assunto/:subjectId',
		element: IndividualSubject,
		isPrivate: true,
		roles,
	},
	{
		path: '/disciplina/:id/assunto/:subjectId/sub-assunto/:subSubjectId',
		element: IndividualSubSubject,
		isPrivate: true,
		roles,
	},
	{
		path: '/disciplina/:id/assunto/:subjectId/sub-assunto/:subSubjectId/criar-questao/',
		element: CreateQuiz,
		isPrivate: true,
		roles,
	},
	{
		path: '/disciplina/:id/assunto/:subjectId/sub-assunto/:subSubjectId/editar-questao/:idQuiz',
		element: EditQuiz,
		isPrivate: true,
		roles,
	},
]
