/* eslint-disable @typescript-eslint/no-explicit-any */
export default class CustomUploaderTextEditor {
	private loader: any

	private url: string

	private token: string

	constructor(loader: any, url: string, token: string) {
		this.loader = loader
		this.url = url
		this.token = token
	}

	upload() {
		return this.loader.file.then(
			(file: File) =>
				new Promise((resolve, reject) => {
					const formData = new FormData()

					formData.append('image', file)

					fetch(this.url, {
						method: 'POST',
						body: formData,
						headers: {
							Authorization: `Bearer ${this.token}`,
						},
					})
						.then((response) => response.json())
						.then((result) => {
							if (result && result.location) {
								resolve({
									default: result.location,
								})
							} else {
								reject(result)
							}
						})
						.catch((error) => {
							reject(error)
						})
				})
		)
	}

	abort() {
		// Implement abort if needed
	}
}
