import React, { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Breadcrumb, Button, NavTab, Template, UserBanner } from '@components'
import { successMessages } from '@constants/feedbackMessages'
import { clientApi } from '@services/ClientApi'
import { loadingModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { LinkManager } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

import { InfoClient } from './InfoClient'
import GiveAccess from './InfoClient/GiveAccess'
import { ListClass } from './ListClass'

function IndividualClient() {
	const { id } = useParams<{ id: string }>()
	const dispatch = useAppDispatch()

	const { data: clientInfo } = clientApi.useShowClientQuery({
		id: String(id) as string,
	})

	const baseUrl = `/clientes/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Clientes`, '/clientes', 1),
			createBreadcrumbLink(`${clientInfo?.name}`, baseUrl, 2),
		]
	}, [baseUrl, clientInfo?.name])
	const pages = useCreateBreadcrumb(allPages)
	const [
		migrateData,
		{ isSuccess: migrateSuccess, isLoading: loadingMigrate },
	] = clientApi.useMigrateDataMutation()
	const { pathname } = useLocation()

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [
		{ title: 'Turmas', to: createLink('') },
		{ title: 'Informações', to: createLink('/informacoes') },
	]

	const components = {
		[createLink('')]: <ListClass clientInfo={clientInfo} />,
		[createLink('/informacoes')]: <InfoClient clientInfo={clientInfo} />,
	}

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingMigrate }))

		if (migrateSuccess) {
			dispatch(notificationCreators.show(successMessages.migrateData))
		}
	}, [dispatch, loadingMigrate, migrateSuccess])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />

				<UserBanner
					name={`${clientInfo?.name}`}
					info={`${clientInfo?.email}`}
					avatar={clientInfo?.imageUrl}
				/>

				<div className='flex justify-between items-end  mb-[32px]'>
					<NavTab links={links} />
					<div className='flex flex-wrap justify-between gap-1'>
						{pathname === createLink('') && (
							<Button
								onClick={() =>
									dispatch(
										openModal({ name: 'addClass', modalInfo: clientInfo?.id })
									)
								}
							>
								Adicionar Turma
							</Button>
						)}
						{pathname === createLink('/informacoes') &&
							!clientInfo?.migrated && (
								<Button
									onClick={() =>
										migrateData({
											email: clientInfo?.email,
										})
									}
								>
									Migrar dados
								</Button>
							)}
						<Button
							onClick={() => {
								dispatch(
									openModal({ name: 'giveAccess', modalInfo: clientInfo?.id })
								)
							}}
						>
							Conceder acesso
						</Button>
					</div>
				</div>
				<GiveAccess />
				{components[pathname]}
			</Template>
		</>
	)
}

export default IndividualClient
