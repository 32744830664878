import * as yup from 'yup'

export const addAnnouncementSchema = yup.object({
	name: yup.string().required(),
	text: yup.string().required(),
	type: yup.object().required(),
})

export const addAnnouncementClassSchema = yup.object({
	name: yup.string().required(),
	text: yup.string().required(),
	type: yup.object().required(),
	classId: yup.object().required(),
})
