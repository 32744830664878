import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Button, NavTab } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import EditDiscipline from '@pages/Disciplines/EditDiscipline'
import Loading from '@pages/Loading'
import { disciplineApi } from '@services/DisciplineApi'
import { closeModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import LinkManager from '@utils/helpers/LinkManager'
import useCreateBreadcrumb, {
	createBreadcrumbLink,
} from '@utils/hooks/useBreadcrumb'

import IndividualSubject from '../Subject/SubjectTable'
import AddSubject from './AddSubject'

function IndividualDiscipline() {
	const { id } = useParams<{ id: string }>()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()

	const { data: disciplineInfo, isLoading } =
		disciplineApi.useShowDisciplineQuery({
			id: Number(id) as number,
		})

	const baseUrl = `/disciplina/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Disciplina`, '/disciplina', 1),
			createBreadcrumbLink(`${disciplineInfo?.name}`, baseUrl, 2),
		]
	}, [baseUrl, disciplineInfo])

	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [{ title: 'Assuntos', to: createLink('') }]

	const components = {
		[createLink('')]: <IndividualSubject />,
	}

	// Modal Delete
	const [deleteDiscipline, setDeleteDiscipline] = useState(false)

	// const disableDiscipline = {
	// 	title: 'Desativar Disciplina',
	// 	message: 'Tem certeza que deseja desativar a disciplina?',
	// 	buttonLabel: 'Desativar Disciplina',
	// 	onClick: () => setDeleteDiscipline(true),
	// }

	useEffect(() => {
		if (deleteDiscipline) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.deleteDiscipline))
			setDeleteDiscipline(false)
		}
	}, [deleteDiscipline, dispatch])

	// Modal Duplicate
	const [duplicatdDiscipline, setDuplicatdDiscipline] = useState(false)

	// const duplicateDiscipline = {
	// 	title: 'Duplicar Disciplina',
	// 	message: 'Tem certeza que deseja duplicar a disciplina?',
	// 	buttonLabel: 'Duplicar Disciplina',
	// 	onClick: () => {
	// 		setDuplicatdDiscipline(true)
	// 	},
	// }

	useEffect(() => {
		if (duplicatdDiscipline) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.duplicatedDiscipline))
			setDuplicatdDiscipline(false)
		}
	}, [duplicatdDiscipline, dispatch])

	const options = [
		{
			label: 'Editar Disciplina',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editDiscipline',
						modalInfo: id,
					})
				),
		},
	]

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${disciplineInfo?.name}`}
					info={`Alterado pela última vez em ${Formatters.date(
						disciplineInfo?.updatedAt || '',
						2
					)}`}
					options={options}
				/>

				<div className='flex justify-between items-end'>
					<NavTab links={links} />
					<Button onClick={() => dispatch(openModal({ name: 'addSubject' }))}>
						Adicionar Assunto
					</Button>
				</div>
				{components[pathname]}
			</Template>
			<AddSubject />
			<EditDiscipline />
		</>
	)
}

export default IndividualDiscipline
