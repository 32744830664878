import { IMAGE_PROFILE_URL } from '@constants/api'
import { days } from '@constants/date'
import dayjs from 'dayjs'

import capitalize from './capitalize'

export default class Formatters {
	/**
	 * @example
	 * createdAt(date, 1) // May 10, 2022
	 * createdAt(date, 2) // 2022-05-27 13:30:19
	 * createdAt(date, 3) // 16/03/2022 - 18:00
	 *
	 */
	public static createdAt(
		date: string | undefined,
		format: 1 | 2 | 3 | 4 | 5 | 6 = 1
	) {
		const formats = {
			1: dayjs(date).locale('pt-br').format('DD MMM[,] YYYY'),
			2: dayjs(date).locale('pt-br').format('YYYY-MM-DD HH:mm:ss'),
			3: dayjs(date).locale('pt-br').format('DD/MM/YYYY [-] HH:mm'),
			4: dayjs(date).locale('pt-br').format('MMM DD[,] YYYY'),
			5: dayjs(date).locale('pt-br').format('YYYY-MM-DD'),
			6: dayjs(date).locale('pt-br').format('DD'),
		}

		return formats[format]
	}

	public static currency(value: number) {
		return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
	}

	public static dayOfWeek(date: string) {
		return days[dayjs(date).day()]
	}

	public static pictures(id: string) {
		return `${IMAGE_PROFILE_URL}/${id}`
	}

	/**
	 * @example
	 * Formatters.date(date, 1) // Setembro, 2022
	 * Formatters.date(date, 2) // 05 de Setembro às 19h30
	 * Formatters.date(date, 3) // 2022-05-14
	 * Formatters.date(date, 4) // Qua, 05 de Setembro às 19h30
	 * Formatters.date(date, 5) // Qua, 05 de Setembro
	 */
	public static date(date: string, format: 1 | 2 | 3 | 4 | 5) {
		const date_ = dayjs(date).locale('pt-br')

		const formats = {
			1: date_.format('MMMM[,] YYYY'),
			2: `${date_.format('DD [de]')} ${capitalize(
				date_.format('MMMM')
			)} às ${date_.format('HH[h]mm')}`,
			3: date_.format('YYYY-MM-DD'),
			4: `${capitalize(date_.format('ddd'))}, ${date_.format(
				'DD [de]'
			)} ${capitalize(date_.format('MMMM'))} às ${date_.format('HH[h]mm')}`,
			5: `${capitalize(date_.format('ddd'))}, ${date_.format(
				'DD [de]'
			)} ${capitalize(date_.format('MMMM'))}`,
		}

		return formats[format]
	}

	/**
	 * @example
	 * Formatters.hours(date, 1) // 21:00
	 */
	public static hours(date: string, format: 1) {
		const date_ = dayjs(date)

		const formats = {
			1: date_.locale('pt-br').format('HH:mm'),
		}

		return formats[format]
	}

	public static cnpj(cnpj: string) {
		return cnpj.replace(
			/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			'$1.$2.$3/$4-$5'
		)
	}
}
