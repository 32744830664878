import { BASE_URL } from '@constants/api'
import {
	CheckModuleRequest,
	ListCommentsModuleRequest,
	ListCommentsModuleResponse,
	ShowCommentsModuleResponse,
	UpdateCommentsModuleRequest,
} from '@models/commentsModule.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const commentsModuleApi = createApi({
	reducerPath: 'commentsModuleApi',
	tagTypes: ['CommentModule'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListCommentsModuleResponse, ListCommentsModuleRequest>({
			query: ({ page = '1', search = '' }) => ({
				url: `/moduleComments?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['CommentModule'],
		}),
		show: builder.mutation<ShowCommentsModuleResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/moduleComments/${id}`,
				method: 'GET',
			}),
		}),
		edit: builder.mutation<void, UpdateCommentsModuleRequest>({
			query: ({ id, answer }) => ({
				url: `/moduleComments/${id}`,
				body: { answer },
				method: 'PUT',
			}),
			invalidatesTags: ['CommentModule'],
		}),
		checkModule: builder.mutation<void, CheckModuleRequest>({
			query: ({ id, isApproved }) => ({
				url: `/moduleComments/${id}`,
				body: { isApproved },
				method: 'PUT',
			}),
			invalidatesTags: ['CommentModule'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/moduleComments/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CommentModule'],
		}),
	}),
})
