/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'

import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { clientApi } from '@services/ClientApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function GiveAccess() {
	const dispatch = useAppDispatch()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [giveAccess, { isLoading, isSuccess }] =
		clientApi.useGiveAccessMutation()

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.giveAccess))
		}
	}, [isSuccess, dispatch])

	return (
		<Modal name='giveAccess'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Conceder acesso</p>
				<p className={styles.subTitle}>
					Lembre-se, ao conceder acesso ao usuário, sua senha automaticamente
					será mudada para <strong>migrei123</strong>.
				</p>

				<div className={styles.buttonGroup}>
					<Button
						type='button'
						onClick={() => {
							dispatch(closeModal())
						}}
						variant='outline'
						disabled={false}
					>
						Cancelar
					</Button>
					<Button
						type='button'
						onClick={() => {
							giveAccess({ id })
						}}
						isLoading={isLoading}
						disabled={!modalName}
					>
						Conceder acesso
					</Button>
				</div>
			</Dialog.Panel>
		</Modal>
	)
}
