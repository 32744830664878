import { BASE_URL } from '@constants/api'
import {
	AddDisciplineRequest,
	ListDisciplineRequest,
	ListDisciplineResponse,
	ListQuizRequest,
	ListQuizResponse,
	ListSubjectRequest,
	ListSubjectResponse,
	ListSubSubjectRequest,
	ListSubSubjectResponse,
	ShowDisciplineResponse,
	ShowQuizResponse,
	ShowSubjectResponse,
	ShowSubSubjectResponse,
	UpdateDisciplineRequest,
	UpdateSubjectRequest,
	UpdateSubSubjectRequest,
} from '@models/discipline.model'
import {
	AddQuizRequest,
	OrderQuestionRequest,
	UpdateQuizRequest,
} from '@models/quiz.model'
import { AddSubjectRequest } from '@models/subject.model'
import { AddSubSubjectRequest } from '@models/subSubject.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const disciplineApi = createApi({
	reducerPath: 'disciplineApi',
	tagTypes: ['Disciplines', 'Discipline'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListDisciplineResponse, ListDisciplineRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/disciplines?page=${page}&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		listSubject: builder.query<ListSubjectResponse, ListSubjectRequest>({
			query: ({ page, search = '', disciplineId }) => ({
				url: `/matters?page=${page}&search=${search}&limit=20&disciplineId=${disciplineId}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		listSubSubject: builder.query<
			ListSubSubjectResponse,
			ListSubSubjectRequest
		>({
			query: ({ page = '1', search = '', matterId }) => ({
				url: `/submatters?page=${page}&search=${search}&limit=20&matterId=${matterId}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		listQuiz: builder.query<ListQuizResponse, ListQuizRequest>({
			query: ({ search = '', submatterId }) => ({
				url: `/question?search=${search}&submatterId=${submatterId}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		// Get One
		showDiscipline: builder.query<ShowDisciplineResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/disciplines/${id}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		showSubjectOne: builder.query<ShowSubjectResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/matters/${id}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		showSubSubjectOne: builder.query<ShowSubjectResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/submatters/${id}`,
				method: 'GET',
			}),
			providesTags: ['Disciplines'],
		}),
		// Mutation
		show: builder.mutation<ShowDisciplineResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/disciplines/${id}`,
				method: 'GET',
			}),
		}),
		showSubjectMutation: builder.mutation<ShowSubjectResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/matters/${id}`,
				method: 'GET',
			}),
		}),
		showSubSubjectMutation: builder.mutation<
			ShowSubSubjectResponse,
			{ id: number }
		>({
			query: ({ id }) => ({
				url: `/submatters/${id}`,
				method: 'GET',
			}),
		}),
		showQuizMutation: builder.mutation<ShowQuizResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/question/${id}`,
				method: 'GET',
			}),
		}),
		// Add
		add: builder.mutation<void, AddDisciplineRequest>({
			query: (data) => ({
				url: `/disciplines`,
				body: {
					...data,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		addSubject: builder.mutation<void, AddSubjectRequest>({
			query: ({ id, name }) => ({
				url: `matters/?disciplineId=${id}`,
				body: {
					name,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		addSubSubject: builder.mutation<void, AddSubSubjectRequest>({
			query: ({ subjectId, name }) => ({
				url: `submatters`,
				body: {
					matterId: subjectId,
					name,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		addQuiz: builder.mutation<void, AddQuizRequest>({
			query: (data) => {
				return {
					url: `/question`,
					body: data,
					method: 'POST',
				}
			},
			invalidatesTags: ['Disciplines'],
		}),
		OrderQuestion: builder.mutation<void, OrderQuestionRequest>({
			query: ({ questionId, submatterId, newPosition }) => {
				return {
					url: `/question/reorder`,
					body: {
						questionId,
						submatterId,
						newPosition,
					},
					method: 'POST',
				}
			},
			invalidatesTags: ['Disciplines'],
		}),
		// Edit
		edit: builder.mutation<void, UpdateDisciplineRequest>({
			query: ({ id, ...data }) => ({
				url: `/disciplines/${id}`,
				body: data,
				method: 'PUT',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		editSubject: builder.mutation<void, UpdateSubjectRequest>({
			query: ({ id, name, disciplineId }) => ({
				url: `/matters/${id}`,
				body: {
					disciplineId,
					name,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		editSubSubject: builder.mutation<void, UpdateSubSubjectRequest>({
			query: ({ id, name, subjectId }) => ({
				url: `/submatters/${id}`,
				body: {
					matterId: subjectId,
					name,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		editQuiz: builder.mutation<void, UpdateQuizRequest>({
			query: ({ id, ...data }) => ({
				url: `/question/${id}`,
				body: { ...data, articleIds: [] },
				method: 'PUT',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		// Delete
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/disciplines/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		deleteSubject: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/matters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		deleteSubSubject: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/submatters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Disciplines'],
		}),
		deleteQuiz: builder.mutation<void, { id: string | number }>({
			query: ({ id }) => ({
				url: `/question/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Disciplines'],
		}),
	}),
})
