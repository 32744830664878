import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Select, { SingleValue } from 'react-select'

import {
	Button,
	EmptyRow,
	InputGroup,
	MarkInput,
	Spinner,
	Table,
	Td,
	Tr,
} from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addQuizSchema } from '@schemas/addQuiz.schema'
import { categoryApi } from '@services/CategoryApi'
import { examsApi } from '@services/ExamsApi'
import { filtersApi } from '@services/filtersApi'
import { questionsApi } from '@services/QuestionsApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

type Inputs = {
	discipline: SingleValue<{ value: number; label: string }>
	subject: SingleValue<{ value: number; label: string }>
	subSubject: SingleValue<{ value: number; label: string }>
}

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

function AddQuestion() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const [disciplineId, setDisciplineId] = useState<number>()
	const [matterId, setMatterId] = useState<number>()
	const [subMatterId, setSubMatterId] = useState<number>()
	const [questionIds, setQuestionIds] = useState<number[]>([])

	const { id, idSimulated } = useParams<{
		id: string
		idSimulated: string
	}>()

	const { data: categoryInfo } = categoryApi.useShowQueryQuery({
		id: String(id) as string,
	})

	const { data: examsInfo } = examsApi.useShowQueryQuery({
		id: String(idSimulated) as string,
	})

	const { data: dataDiscipline } = filtersApi.useListQuery({})

	const { data: dataSubject } = filtersApi.useListMatterQuery({
		disciplineId: disciplineId || '-1',
	})

	const { data: dataSubSubject } = filtersApi.useListSubMatterQuery({
		matterId: matterId || '-1',
	})

	const { data: dataQuestion, isLoading } = questionsApi.useListQuestionsQuery({
		subMatterId: subMatterId || '1',
		mockId: idSimulated,
	})

	const [addQuestions, { isSuccess }] = questionsApi.useAddMutation()

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Categorias`, `/categorias`, 1),
			createBreadcrumbLink(`${categoryInfo?.name}`, `/categorias/${id}`, 2),
			createBreadcrumbLink(
				`${examsInfo?.name}`,
				`/categorias/${id}/simulado/${idSimulated}`,
				3
			),
			createBreadcrumbLink(
				`Adicionar questão`,
				`/categorias/${id}/simulado/${idSimulated}/adicionar-questao`,
				4
			),
		]
	}, [categoryInfo?.name, examsInfo?.name, id, idSimulated])

	const pages = useCreateBreadcrumb(allPages)

	const { control } = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addQuizSchema),
	})

	const onSubmit = () => {
		const newQuestionIds = questionIds.filter(
			(numero, index, self) => self.indexOf(numero) === index
		)

		addQuestions({ questionIds: newQuestionIds, mockExamId: idSimulated })
	}

	useEffect(() => {
		if (isSuccess) {
			navigate(`/categorias/${id}/simulado/${idSimulated}`)
			dispatch(notificationCreators.show(successMessages.storedQuiz))
		}
	}, [isSuccess, dispatch, navigate, id, idSimulated])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Adicionar Questão' />

				<div className='grid grid-cols-3 gap-4'>
					<InputGroup label='Disciplina' id='discipline'>
						<Controller
							control={control}
							name='discipline'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setDisciplineId(v?.value)}
										options={
											dataDiscipline?.disciplines
												? dataDiscipline?.disciplines.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>
					<InputGroup label='Assunto' id='subject'>
						<Controller
							control={control}
							name='subject'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setMatterId(v?.value)}
										options={
											dataSubject?.data
												? dataSubject.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										onBlur={onBlur}
										isDisabled={!dataSubject?.data.length}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>
					<InputGroup label='Sub Assunto' id='subSubject' className='mb-6'>
						<Controller
							control={control}
							name='subSubject'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setSubMatterId(v?.value)}
										options={
											dataSubSubject?.data
												? dataSubSubject.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										onBlur={onBlur}
										isDisabled={!dataSubSubject?.data.length}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>
				</div>
				<div className='flex justify-end mb-8'>
					<Button
						className='h-10'
						onClick={onSubmit}
						disabled={questionIds.length === 0}
					>
						Adicionar Questões
					</Button>
				</div>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{
								label: 'id',
							},
							{
								label: 'nome',
							},
							{
								label: 'selecionar',
							},
						]}
					>
						{dataQuestion?.data &&
						subMatterId &&
						dataQuestion?.data?.length > 0 ? (
							dataQuestion.data.map(({ id: questionId, title }) => (
								<Tr key={questionId}>
									<Td>{questionId}</Td>
									<Td className='w-[100%] px-6'>{title}</Td>

									<Td>
										<MarkInput
											type='checkbox'
											itens={[
												{ id: questionId as unknown as string, title: '' },
											]}
											multiCheckbox
											onChange={(e) => {
												if (e.currentTarget.checked) {
													setQuestionIds([...questionIds, questionId])
												} else {
													setQuestionIds(
														questionIds.filter(
															(number) => number !== questionId
														)
													)
												}
											}}
										/>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhuma questão encontrada' />
						)}
					</Table>
				)}
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default AddQuestion
