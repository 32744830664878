/* eslint-disable no-console */
import { DEBUG_MODE } from '@constants/api'
import { Middleware } from '@reduxjs/toolkit'

export const loggerMiddleware: Middleware = (store) => (next) => (action) => {
	if (DEBUG_MODE) {
		console.group(action.type)
		console.info('dispatching', action)

		console.log('next state', store.getState())
		console.groupEnd()
	}

	const result = next(action)

	return result
}
