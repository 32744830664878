import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { AppRoutes } from '@routes'
import { store } from '@store'

import 'dayjs/locale/pt-br'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

/* The Strict Mode is triggering useEffect twice on
   development mode because test of future new features
*/
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<AppRoutes />
			</Router>
		</Provider>
	</React.StrictMode>
)

reportWebVitals()
