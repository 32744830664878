import React from 'react'

import Flex from '@components/Flex'
import Sidebar from '@components/Sidebar'

interface TemplateProps {
	children: React.ReactNode
	header?: React.ReactNode
}

const styles = {
	container: 'flex',
	subContainer: 'w-full h-[100vh] overflow-y-auto',
	content: 'ml-[24px] mr-[30px]',
}

export default function Template({ children, header }: TemplateProps) {
	return (
		<div className={styles.container}>
			<Sidebar />

			<div className={styles.subContainer}>
				{header}

				<div>
					<div className={styles.content}>
						<Flex dir='col'>{children}</Flex>
					</div>
				</div>
			</div>
		</div>
	)
}
