import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
	Breadcrumb,
	Divider,
	EmptyRow,
	Flex,
	PageTitle,
	Pagination,
	SearchInput,
	Spinner,
	Table,
	Td,
	Template,
	Tr,
} from '@components'
import { statusMigrationsApi } from '@services/StatusMigrationsApi'
import Formatters from '@utils/helpers/Formatters'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

function StatusMigrations() {
	const styles = {
		searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
		tag: 'font-medium text-blue-600 underline underline-offset-2',
		divider: 'mt-[32px] mb-[12px]',
		avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
	}
	const pages = [{ name: 'Status das migrações', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = statusMigrationsApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/statusMigrations?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/statusMigrations',
	})
	const statuses: { [key: string]: string } = {
		CLIENT_MIGRATED: 'CLIENT_MIGRATED',
		MIGRATING_QUESTIONS_DATA: 'MIGRATING_QUESTIONS_DATA',
		MIGRATING_ADDRESS_DATA: 'MIGRATING_ADDRESS_DATA',
		MIGRATING_CLASSES_DATA: 'MIGRATING_CLASSES_DATA',
		MIGRATING_NOTEBOOKS_DATA: 'MIGRATING_NOTEBOOKS_DATA',
		MIGRATING_NOTEBOOK_RESPONSES_DATA: 'MIGRATING_NOTEBOOK_RESPONSES_DATA',
		MIGRATING_MODULE_DATA: 'MIGRATING_MODULE_DATA',
		MIGRATING_MODULE_COMMENT_DATA: 'MIGRATING_MODULE_COMMENT_DATA',
		MIGRATING_MODULE_ERROR_DATA: 'MIGRATING_MODULE_ERROR_DATA',
		MIGRATING_QUESTION_COMMENT_DATA: 'MIGRATING_QUESTION_COMMENT_DATA',
		MIGRATING_QUESTION_ERROR_DATA: 'MIGRATING_QUESTION_ERROR_DATA',
		MIGRATING_QUESTION_REMINDER_DATA: 'MIGRATING_QUESTION_REMINDER_DATA',
		MIGRATING_FILTERS_DATA: 'MIGRATING_FILTERS_DATA',
		MIGRATING_MOCK_RESPONSES_TRAINING_DATA:
			'MIGRATING_MOCK_RESPONSES_TRAINING_DATA',
		MIGRATING_MOCK_RESPONSES_DATA: 'MIGRATING_MOCK_RESPONSES_DATA',
		FINISHED: 'FINISHED',
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Status das migrações' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Cliente'
					/>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'nome' },
							{
								label: 'status',
							},
							{ label: 'Iniciou em' },
							{ label: 'Finalizou em' },
							{ label: 'Exceção' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(
								({
									clientId,
									status,
									client,
									exception,
									finishedAt,
									startedAt,
								}) => (
									<Tr key={clientId}>
										<Td className='w-[22%] px-6'>
											<Flex className='items-center gap-4'>
												<Link to={`/clientes/${client.id}`}>{client.name}</Link>
											</Flex>
										</Td>
										<Td>{statuses[status]}</Td>
										<Td>{Formatters.createdAt(startedAt as string, 2)}</Td>
										<Td>{Formatters.createdAt(finishedAt as string, 2)}</Td>
										<Td>{exception}</Td>
									</Tr>
								)
							)
						) : (
							<EmptyRow tdAmount={3} message='Nenhum turma encontrada' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}

export default StatusMigrations
