import { BASE_URL } from '@constants/api'
import {
	ListStatusMigrationsRequest,
	ListStatusMigrationsResponse,
} from '@models/statusMigrations'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const statusMigrationsApi = createApi({
	reducerPath: 'statusMigrationsApi',
	tagTypes: ['StatusMigrations'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<
			ListStatusMigrationsResponse,
			ListStatusMigrationsRequest
		>({
			query: ({ page = '1', search = '' }) => ({
				url: `/statusMigrations?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['StatusMigrations'],
		}),
	}),
})
