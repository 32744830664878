import React from 'react'

import Icon from '@components/Icon'

type LogoProps = {
	dark?: boolean
	className?: string
	expanded?: boolean
}

export default function Logo({
	dark = false,
	expanded = true,
	className = '',
}: LogoProps) {
	return dark ? (
		<Icon
			name={expanded ? 'dark-logo-expanded' : 'dark-logo-collapsed'}
			width={115}
			height={50}
			className={className}
		/>
	) : (
		<Icon
			name={expanded ? 'white-logo-expanded' : 'white-logo-collapsed'}
			width={115}
			height={50}
			className={className}
		/>
	)
}
