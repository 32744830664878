import React, { Fragment, useRef } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const styles = {
	menu: 'relative inline-block text-left z-10',
	button:
		'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary',
	chevron: '-mr-1 ml-2 h-5 w-5',
	items:
		'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
	item: 'block px-4 py-2 text-sm',
	activeItem: 'bg-gray-100 text-gray-900',
	inactiveItem: 'text-gray-700',
}

interface OptionsProps {
	options: Array<{
		onClick: CallableFunction
		label: string
		className?: string
	}>
}

export default function Options({ options }: OptionsProps) {
	const optionButton = useRef<HTMLButtonElement>(null)

	return (
		<Menu as='div' className={styles.menu}>
			<div>
				<Menu.Button
					ref={optionButton}
					className={`${styles.button} option-button`}
				>
					Opções
					<ChevronDownIcon className={styles.chevron} aria-hidden='true' />
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter='transition ease-out duration-100'
				enterFrom='transform opacity-0 scale-95'
				enterTo='transform opacity-100 scale-100'
				leave='transition ease-in duration-75'
				leaveFrom='transform opacity-100 scale-100'
				leaveTo='transform opacity-0 scale-95'
			>
				<Menu.Items className={styles.items}>
					<div className='py-1'>
						{options.map(({ label, onClick, className = '' }) => (
							<Menu.Item key={label}>
								{({ active }) => (
									<a
										href='#1'
										className={classNames(
											active ? styles.activeItem : styles.inactiveItem,
											styles.item,
											className
										)}
										onClick={(e) => {
											e.preventDefault()

											onClick()
										}}
									>
										{label}
									</a>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}
