/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import { Input, InputGroup } from '@components'
import Button from '@components/Button'
import FileInput from '@components/FileInput'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addClientSchema } from '@schemas/addClient.schema'
import { classesApi } from '@services/ClassesApi'
import { clientApi } from '@services/ClientApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'
import useAddress from '@utils/hooks/useAddress'

type Inputs = {
	name: string
	email: string
	image: Array<string | Blob>
	password: string
	roles: string[]
	cpfCnpj: string
	phone: string
	whatsapp: string
	cep: string
	number: string
	district: string
	city: string
	address: string
	state: string
	classesSelect?: MultiValue<{ value: string | number; label: string }>
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function AddClient() {
	const dispatch = useAppDispatch()
	const [isCpf, setIsCpf] = useState(true)

	const {
		modal: { modalName },
	} = useAppSelector((state) => state)

	const { data: dataClasses } = classesApi.useListQuery({ limit: 100000 })

	const [addClient, { isLoading, isSuccess }] = clientApi.useAddMutation()

	const {
		register,
		control,
		setValue,
		reset,
		handleSubmit,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addClientSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const classClients = data?.classesSelect?.map((r) => {
			return r.value as string
		})

		const dataClient = {
			name: data.name || '',
			email: data.email || '',
			password: data.password || '',
			cpfCnpj: data?.cpfCnpj || '',
			phone: data?.phone || '',
			whatsapp: data?.whatsapp || '',
			cep: data?.cep || '',
			number: data?.number || '',
			district: data?.district || '',
			city: data?.city || '',
			address: data?.address || '',
			state: data?.state || '',
			image: data.image ? data.image[0] : '',
			classes: classClients ? (classClients as string[]) : [],
		}

		addClient(dataClient)
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedClient))
		}
	}, [isSuccess, dispatch])

	const [cep, setCep] = useState('')
	const { address, sucess } = useAddress(cep)

	useEffect(() => {
		if (sucess) {
			setValue('state', address?.uf as string)
			setValue('city', address?.localidade as string)
			setValue('district', address?.bairro as string)
			setValue('address', address?.logradouro as string)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucess])

	return (
		<Modal name='addClient'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Adicionar Cliente</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para adicionar um novo cliente
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup label='Turmas' id='classesSelect'>
						<Controller
							control={control}
							name='classesSelect'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('classesSelect', v)}
										options={
											dataClasses?.data
												? dataClasses.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										isMulti
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Nome do cliente' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Email do cliente' id='email'>
						<Input
							isValid={inputIsValid(errors.email, touchedFields.email)}
							{...register('email')}
							type='email'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Senha' id='password'>
						<Input
							isValid={inputIsValid(errors.password, touchedFields.password)}
							{...register('password')}
							type='password'
						/>
					</InputGroup>
					<Controller
						control={control}
						name='cpfCnpj'
						render={() => (
							<InputGroup className='mt-4' label='Cpf/Cnpj' id='cpfCnpj'>
								<Input
									isValid={inputIsValid(errors.cpfCnpj, touchedFields.cpfCnpj)}
									{...register('cpfCnpj')}
									format={isCpf ? '###.###.###-###' : '##.###.###/####-##'}
									type='text'
									min={0}
									onValueChange={(value) => {
										setValue('cpfCnpj', value.value)

										if (value.value.length > 11) {
											setIsCpf(false)
										}

										if (!isCpf && value.value.length <= 11) {
											setIsCpf(true)
										}
									}}
								/>
							</InputGroup>
						)}
					/>

					<Controller
						control={control}
						name='phone'
						render={() => (
							<InputGroup className='mt-4' label='Telefone' id='phone'>
								<Input
									isValid={inputIsValid(errors.phone, touchedFields.phone)}
									format='(##) #####-####'
									type='text'
									min={0}
									onValueChange={(value) => {
										setValue('phone', value.value)
									}}
									{...register('phone')}
								/>
							</InputGroup>
						)}
					/>

					<Controller
						control={control}
						name='whatsapp'
						render={() => (
							<InputGroup className='mt-4' label='Whatsapp' id='whatsapp'>
								<Input
									isValid={inputIsValid(
										errors.whatsapp,
										touchedFields.whatsapp
									)}
									format='(##) #####-####'
									type='text'
									min={0}
									onValueChange={(value) => {
										setValue('whatsapp', value.value)
									}}
									{...register('whatsapp')}
								/>
							</InputGroup>
						)}
					/>

					<InputGroup className='mt-4' label='Cep' id='cep'>
						<Input
							isValid={inputIsValid(errors.cep, touchedFields.cep)}
							{...register('cep')}
							format='#####-###'
							type='text'
							min={0}
							onValueChange={(value) => {
								if (value.value.length === 8) {
									setCep(value.value)
								}
								setValue('cep', value.value)
							}}
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Estado' id='state'>
						<Input
							isValid={inputIsValid(errors.state, touchedFields.state)}
							{...register('state')}
							type='text'
							maxLength={2}
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Cidade' id='city'>
						<Input
							isValid={inputIsValid(errors.city, touchedFields.city)}
							{...register('city')}
							type='text'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Bairro' id='district'>
						<Input
							isValid={inputIsValid(errors.district, touchedFields.district)}
							{...register('district')}
							type='text'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Endereço' id='address'>
						<Input
							isValid={inputIsValid(errors.address, touchedFields.address)}
							{...register('address')}
							type='text'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Número' id='number'>
						<Input
							isValid={inputIsValid(errors.number, touchedFields.number)}
							{...register('number')}
							type='number'
						/>
					</InputGroup>

					<Controller
						control={control}
						name='image'
						render={() => {
							return (
								<FileInput
									className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
									mt='mt-4'
									onChange={(v) => {
										setValue('image', v)
									}}
								>
									Avatar
								</FileInput>
							)
						}}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Adicionar Cliente
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
