import React from 'react'

import Icon from '@components/Icon'
import { Sprite } from '@models/icon.model'

export interface CardStatsProps {
	title: string
	value?: number
	icon: Sprite
}

export function CardStats({ icon, title, value }: CardStatsProps) {
	return (
		<div className='max-w-[260px] relative flex flex-wrap justify-end py-[30px] px-5 rounded-xl bg-white text-gray-700 transform  hover:scale-105 transition duration-300 border-[1px] border-[#DFDEDE]'>
			<div className='w-[61px] h-[61px] bg-[#91b5ff1f] rounded-sm flex items-center justify-center absolute left-5'>
				<Icon name={icon} width={27} height={27} />
			</div>
			<div className='text-right'>
				<h4 className='text-[32px] pb-[25px] font-bold text-[#0C0C0C]'>
					{value}
				</h4>
				<p className='text-base font-normal text-[#828282]'>{title}</p>
			</div>
		</div>
	)
}

export default CardStats
