import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Icon, Pagination } from '@components'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { clientApi } from '@services/ClientApi'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] mt-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ListClientsActive() {
	const [notErrorImg, setNotErrorImg] = useState<boolean>(true)
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = clientApi.useListActiveQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/clientes/ativos?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/clientes/ativos',
	})

	return (
		<>
			<Flex className={styles.searchContainer}>
				<SearchInput
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
					placeholder='Buscar Cliente Ativo'
				/>
			</Flex>

			{isLoading ? (
				<div className='flex justify-center'>
					<Spinner size='md' />
				</div>
			) : (
				<Table
					th={[
						{
							label: 'nome',
						},
						{ label: 'e-mail' },
					]}
				>
					{data?.data && data?.data?.length > 0 ? (
						data?.data.map(({ client, client_id }) => (
							<Tr key={client_id}>
								<Td>
									<Link to={`/clientes/${client.id}`}>
										<Flex className='items-center gap-4'>
											{client.imageUrl && notErrorImg ? (
												<img
													src={client.imageUrl}
													alt={client.name}
													className=' h-11 w-11 rounded-full'
													onError={() => setNotErrorImg(false)}
												/>
											) : (
												<Icon
													name='avatar-default'
													className='w-11 h-11 rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]'
												/>
											)}
											{client.name}
										</Flex>
									</Link>
								</Td>
								<Td>
									<Link to={`/clientes/${client.id}`}>
										<Flex className='items-center gap-4'>{client.email}</Flex>
									</Link>
								</Td>
							</Tr>
						))
					) : (
						<EmptyRow tdAmount={3} message='Nenhum cliente ativo encontrado' />
					)}
				</Table>
			)}

			<Divider className={styles.divider} />
			{data?.data && data?.data?.length > 0 && (
				<Pagination
					pageCount={data?.meta.lastPage || 0}
					maxAmount={data?.meta.total || 0}
					perPage={data?.meta.perPage || 0}
				/>
			)}
		</>
	)
}

export default ListClientsActive
