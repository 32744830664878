import { BASE_URL } from '@constants/api'
import {
	ListUnreadedMessagesRequest,
	ListUnreadedMessagesResponse,
} from '@models/unreadedMessages.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const unreadedMessagesApi = createApi({
	reducerPath: 'unreadedMessagesApi',
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<
			ListUnreadedMessagesResponse,
			ListUnreadedMessagesRequest
		>({
			query: () => ({
				url: '/unreadedMessages',
				method: 'GET',
			}),
		}),
		show: builder.mutation<
			ListUnreadedMessagesResponse,
			ListUnreadedMessagesRequest
		>({
			query: () => ({
				url: '/unreadedMessages',
				method: 'GET',
			}),
		}),
	}),
})
