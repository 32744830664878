import React from 'react'
import ReactPaginate from 'react-paginate'
import { useLocation, useNavigate } from 'react-router-dom'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import useQuery from '@utils/hooks/useQuery'

interface PaginationProps {
	pageCount: number
	maxAmount: number
	perPage: number
}

const styles = {
	container: 'flex items-center justify-between mb-10',
	pagination: 'flex-1 flex items-center justify-between',
	text: 'text-sm text-gray-700',
	amount: 'font-medium',
	arrowButton:
		'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 h-full',
	nextButton: 'rounded-r-md',
	prevButton: 'rounded-l-md',
	break:
		'relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
	link: 'relative z-0 inline-flex rounded-md shadow-sm -space-x-px',
	page: 'relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
	active: 'z-10 bg-indigo-50 border-primary text-primary hover:bg-indigo-50',
}

export default function Pagination({
	pageCount,
	maxAmount,
	perPage,
}: PaginationProps) {
	const query = useQuery()

	const page = Number(query.get('page')) || 1
	const initialPage = page - 1

	const navigate = useNavigate()

	const { pathname } = useLocation()

	const currentAmount = perPage * page

	function handleChange(event: { selected: number }) {
		navigate(`${pathname}?page=${event.selected + 1}`)
	}

	return (
		<div className={styles.container}>
			<div className={styles.pagination}>
				<div>
					<p className={styles.text}>
						Showing{' '}
						<span className={styles.amount}>{currentAmount - perPage + 1}</span>{' '}
						to{' '}
						<span className={styles.amount}>
							{currentAmount > maxAmount ? maxAmount : currentAmount}
						</span>{' '}
						of <span className={styles.amount}>{maxAmount}</span> results
					</p>
				</div>
				<div>
					<nav>
						<ReactPaginate
							initialPage={initialPage}
							forcePage={initialPage}
							onPageChange={handleChange}
							breakLabel='...'
							nextLabel={
								<div className={`${styles.arrowButton} ${styles.nextButton}`}>
									<span className='sr-only'>Next</span>
									<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
								</div>
							}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={
								<div className={`${styles.arrowButton} ${styles.prevButton}`}>
									<span className='sr-only'>Previous</span>
									<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
								</div>
							}
							breakLinkClassName={styles.break}
							className={styles.link}
							pageLinkClassName={styles.page}
							activeLinkClassName={styles.active}
						/>
					</nav>
				</div>
			</div>
		</div>
	)
}
