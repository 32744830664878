import { BASE_URL } from '@constants/api'
import {
	AddExamsRequest,
	ListExamsRequest,
	ListExamsResponse,
	ShowExamsResponse,
	UpdateExamsRequest,
} from '@models/exams.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const examsApi = createApi({
	reducerPath: 'examsApi',
	tagTypes: ['Exams'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListExamsResponse, ListExamsRequest>({
			query: ({ id, page = '1', search = '' }) => ({
				url: `/mockExams?page=${page}&categoryId=${id}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['Exams'],
		}),
		show: builder.mutation<ShowExamsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/mockExams/${id}`,
				method: 'GET',
			}),
		}),
		showQuery: builder.query<ShowExamsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/mockExams/${id}`,
				method: 'GET',
			}),
			providesTags: ['Exams'],
		}),
		add: builder.mutation<void, AddExamsRequest>({
			query: (dataExams) => {
				return {
					url: `/mockExams`,
					body: dataExams,
					method: 'POST',
				}
			},
			invalidatesTags: ['Exams'],
		}),
		edit: builder.mutation<void, UpdateExamsRequest>({
			query: ({ id, ...data }) => ({
				url: `/mockExams/${id}`,
				body: {
					...data,
					typeId: 1,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Exams'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/mockExams/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Exams'],
		}),
	}),
})
