import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'

import { Input, InputGroup, Spinner, TextEditor } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addAnnouncementClassSchema } from '@schemas/addAnnouncement.schema'
import { announcementClassApi } from '@services/AnnouncementClassApi'
import { classesApi } from '@services/ClassesApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	classId: SingleValue<{ value?: number; label?: string }>
	type: SingleValue<{ value?: string; label?: string }>
	name: string
	text: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditAnnouncementClass() {
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const dispatch = useAppDispatch()

	const { data: dataClass } = classesApi.useListQuery({ limit: 10000 })

	const [
		getAnnouncementClass,
		{ data: announcementClass, isLoading: loadingGetAnnouncementClass },
	] = announcementClassApi.useShowMutation()

	const [editAnnouncementClass, { isLoading, isSuccess }] =
		announcementClassApi.useEditMutation()

	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addAnnouncementClassSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const datasAnnouncementClass = {
			type: data?.type ? data?.type?.value : 'E-mail',
			classId: data?.classId?.value,
			name: data.name ? data.name : '',
			text: data.text ? data.text : '',
		}

		editAnnouncementClass({
			...datasAnnouncementClass,
			id,
		})
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(
				notificationCreators.show(successMessages.updatedAnnouncementClass)
			)
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (id && modalName === 'editAnnouncementClass') {
			getAnnouncementClass({
				id,
			})
		}
	}, [id, modalName, getAnnouncementClass])

	const [text, setText] = useState(announcementClass?.text)

	useEffect(() => {
		if (announcementClass) {
			setValue('name', announcementClass?.name as string)
			setText(announcementClass.text)
			setValue('text', announcementClass.text)
			setValue('classId', {
				value: announcementClass.class.id,
				label: announcementClass.class.name,
			})
			setValue('type', {
				value: announcementClass.type,
				label: announcementClass.type,
			})
		}
	}, [announcementClass, setValue])

	if (loadingGetAnnouncementClass) {
		return (
			<Modal name='editAnnouncementClass'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editAnnouncementClass'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Comunicado de Turma</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar o comunicado de turma
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome do comunicado' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<InputGroup label='Tipo' id='type' className='w-full mt-6'>
						<Controller
							control={control}
							name='type'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('type', v)}
										options={[
											{
												value: 'E-mail',
												label: 'E-mail',
											},
											{
												value: 'Quadro de Aviso',
												label: 'Quadro de Aviso',
											},
										]}
										value={value}
										defaultValue={[
											{
												value: announcementClass?.type,
												label: announcementClass?.type,
											},
										]}
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<InputGroup label='Turma' id='classId' className='w-full mt-6'>
						<Controller
							control={control}
							name='classId'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('classId', v)}
										options={
											dataClass?.data
												? dataClass.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										defaultValue={[
											{
												value: announcementClass?.class.id,
												label: announcementClass?.class.name,
											},
										]}
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder='Turma'
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<Controller
						{...register('text')}
						name='text'
						control={control}
						render={({ field: { onBlur } }) => (
							<InputGroup
								className={`mt-6 w-[100%] index-0-controler ${
									errors.text && 'error-textBox'
								}`}
								label='Texto'
								id='text'
							>
								<TextEditor
									onChange={(e) => {
										setText(e)
										setValue('text', e)
									}}
									onBlur={onBlur}
									value={text}
								/>
							</InputGroup>
						)}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Comunicado de Turma
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
