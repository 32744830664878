import React, { Dispatch, SetStateAction } from 'react'

import { FilterStateProps, StateProps } from '@models/questions.model'

interface TagsProps {
	title: string
	name:
		| 'disciplines'
		| 'materId'
		| 'subMaterId'
		| 'year'
		| 'office'
		| 'juries'
		| 'institutions'
	onClose: Dispatch<SetStateAction<FilterStateProps>>
	options?: Array<StateProps>
}

export function TagsActions({ options, name, title, onClose }: TagsProps) {
	return (
		<>
			{options && options?.length > 0 && (
				<div className='flex flex-row items-center flex-wrap p-2 gap-3 w-full max-w-[426px] min-h-9 bg-white border border-gray-200 rounded-lg'>
					<div className='flex flex-wrap items-center gap-[10px] w-5/6'>
						<h5 className='font-normal text-14 leading-normal tracking-0.05 text-gray-800'>
							{title}
						</h5>

						{options?.map((item, index) => (
							<div
								key={index}
								className='flex flex-row items-center px-2 gap-4 min-w-min min-h-[21px] bg-gray-100 rounded-full font-normal text-[14px] tracking-0.05 text-gray-400'
							>
								{item.name}
								<svg
									onClick={() => {
										onClose((prevState: FilterStateProps) => {
											return {
												...prevState,
												[name]: prevState[name].filter(
													(tag) => tag.id !== item.id
												),
											}
										})
									}}
									className='cursor-pointer text-primary'
									width='20px'
									height='20px'
								>
									<use href='/assets/images/sprite.svg#dismiss-circle-20' />
								</svg>
							</div>
						))}
					</div>
					<button
						className='cursor-pointer text-primary'
						type='button'
						onClick={() => {
							onClose((prevState: FilterStateProps) => {
								return {
									...prevState,
									[name]: [],
								}
							})
						}}
					>
						<svg width='20px' height='20px'>
							<use href='/assets/images/sprite.svg#dismiss-20' />
						</svg>
					</button>
				</div>
			)}
		</>
	)
}
