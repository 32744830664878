/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import { Input, InputGroup, Spinner } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { editLibrarieSchema } from '@schemas/addLibrarie.schema'
import { librariresApi } from '@services/LibrarieApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	pdfFiles: Array<string | Blob>
	pdfName: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditLibrarie() {
	const dispatch = useAppDispatch()
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [editLibrarie, { isLoading, isSuccess }] =
		librariresApi.useEditMutation()

	const [
		getLibrarie,
		{
			data: dataLibrarie,
			isLoading: loadingGetLibrarie,
			isSuccess: successGetLibrarie,
		},
	] = librariresApi.useShowMutation()

	useEffect(() => {
		if (id && modalName === 'editLibrarie') {
			getLibrarie({
				id: Number(id),
			})
		}
	}, [id, modalName, getLibrarie])

	const {
		register,
		reset,
		handleSubmit,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(editLibrarieSchema),
	})

	const [pdfFiles, setPdfFiles] = useState<
		Array<{ name: string; file: string; deleted: boolean }>
	>([])

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		dataLibrarie?.files.forEach((element) => {
			setPdfFiles(() => {
				return [
					...pdfFiles,
					{
						name: element.name,
						file: element.file,
						deleted: false,
					},
				]
			})
		})

		editLibrarie({
			id,
			name: data?.name,
			pdfFiles,
		})

		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedLibrarie))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (dataLibrarie && successGetLibrarie) {
			setValue('name', dataLibrarie?.name as string)
		}
	}, [dataLibrarie, setValue, successGetLibrarie])

	if (loadingGetLibrarie) {
		return (
			<Modal name='editLibrarie'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editLibrarie'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Biblioteca</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar a biblioteca
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome da biblioteca' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>
						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Biblioteca
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
