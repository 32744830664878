/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'

import { Input, InputGroup } from '@components'
import Button from '@components/Button'
import FileInput from '@components/FileInput'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addPopupsSchema } from '@schemas/addPopups.schema'
import { classesApi } from '@services/ClassesApi'
import { popupsApi } from '@services/PopupsApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	accessTime: number
	link: string
	classId: SingleValue<{ value?: number; label?: string }>
	mobilePicture: Array<string | Blob>
	desktopPicture: Array<string | Blob>
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditPopups() {
	const dispatch = useAppDispatch()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [getModule, { data }] = popupsApi.useShowMutation()

	const { data: dataClass } = classesApi.useListQuery({ limit: 10000 })

	useEffect(() => {
		if (id && modalName === 'editPopups') {
			getModule({ id })
		}
	}, [id, getModule, modalName])

	const [editPopups, { isLoading, isSuccess }] = popupsApi.useEditMutation()

	const {
		register,
		control,
		setValue,
		reset,
		handleSubmit,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addPopupsSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (dataInfo) => {
		const dataPopups = {
			classId: dataInfo?.classId?.value,
			accessTime: dataInfo.accessTime,
			link: dataInfo.link,
			mobilePicture: dataInfo.mobilePicture ? dataInfo.mobilePicture[0] : '',
			desktopPicture: dataInfo.desktopPicture ? dataInfo.desktopPicture[0] : '',
		}

		editPopups({ id, ...dataPopups })
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedPopups))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		setValue('classId', {
			value: data?.class.id,
			label: data?.class.name,
		})

		if (data) {
			setValue('accessTime', data.accessTime)
			setValue('link', data.link)
		}
	}, [setValue, data])

	return (
		<Modal name='editPopups'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Popup</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para adicionar um novo popup
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup label='Turma' id='classId' className='w-full mt-6'>
						<Controller
							control={control}
							name='classId'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('classId', v)}
										options={
											dataClass?.data
												? dataClass.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Tempo de acesso' id='accessTime'>
						<Input
							isValid={inputIsValid(
								errors.accessTime,
								touchedFields.accessTime
							)}
							{...register('accessTime')}
							type='number'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='Link' id='link'>
						<Input
							isValid={inputIsValid(errors.link, touchedFields.link)}
							{...register('link')}
							type='url'
						/>
					</InputGroup>

					<Controller
						control={control}
						name='desktopPicture'
						render={() => {
							return (
								<InputGroup
									className='mt-4'
									label='Imagem Desktop'
									id='desktopPicture'
								>
									<FileInput
										mt='mt-4'
										onChange={(v) => {
											setValue('desktopPicture', v)
										}}
										accept='image/png, image/jpeg'
									>
										Imagem
									</FileInput>
								</InputGroup>
							)
						}}
					/>

					<Controller
						control={control}
						name='mobilePicture'
						render={() => {
							return (
								<InputGroup
									className='mt-4'
									label='Imagem Mobile'
									id='mobilePicture'
								>
									<FileInput
										mt='mt-4'
										onChange={(v) => {
											setValue('mobilePicture', v)
										}}
										accept='image/png, image/jpeg'
									>
										Imagem
									</FileInput>
								</InputGroup>
							)
						}}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Popup
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
