import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Select, { MultiValue } from 'react-select'

import { Input, InputGroup } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import SelectInput, { Option } from '@components/SelectInput'
import Template from '@components/Template'
import TextEditor from '@components/TextEditor'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addQuizSchema } from '@schemas/addQuiz.schema'
import { bancaApi } from '@services/BancaApi'
import { disciplineApi } from '@services/DisciplineApi'
import { institutionApi } from '@services/InstitutionApi'
import { occupationApi } from '@services/OccupationApi'
import { yearApi } from '@services/YearApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

type Inputs = {
	subSubject: number[]
	subSubjectSelect: MultiValue<{ value: number; label: string }>
	year: number[]
	yearSelect?: MultiValue<{ value: number; label: string }>
	occupation: number[]
	occupationSelect?: MultiValue<{ value: number; label: string }>
	banca: number[]
	bancaSelect?: MultiValue<{ value: number; label: string }>
	institution: number[]
	institutionSelect?: MultiValue<{ value: number; label: string }>
	title: string
	enunciado: string
	comment: string
	trueOrFalse: number
}

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

function CreateQuiz() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id, subjectId, subSubjectId } = useParams<{
		id: string
		subjectId: string
		subSubjectId: string
	}>()

	const { data: disciplineInfo } = disciplineApi.useShowDisciplineQuery({
		id: Number(id) as number,
	})

	const { data: subjectInfo } = disciplineApi.useShowSubjectOneQuery({
		id: Number(subjectId) as number,
	})

	const { data: subSubjectInfo } = disciplineApi.useShowSubSubjectOneQuery({
		id: Number(subSubjectId) as number,
	})

	const baseUrl = `/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/criar-questao`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Disciplina`, `/disciplina/`, 1),
			createBreadcrumbLink(`${disciplineInfo?.name}`, `/disciplina/${id}/`, 2),
			createBreadcrumbLink(
				`${subjectInfo?.name}`,
				`/disciplina/${id}/assunto/${subjectId}`,
				3
			),
			createBreadcrumbLink(
				`${subSubjectInfo?.name}`,
				`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`,
				4
			),
			createBreadcrumbLink(`Cadastro Questão`, baseUrl, 5),
		]
	}, [
		baseUrl,
		disciplineInfo?.name,
		id,
		subSubjectId,
		subSubjectInfo?.name,
		subjectId,
		subjectInfo?.name,
	])

	const pages = useCreateBreadcrumb(allPages)

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, touchedFields },
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addQuizSchema),
	})

	const [addQuiz, { isSuccess }] = disciplineApi.useAddQuizMutation()

	const onTypeChange = useCallback(
		(v: Option) => setValue('trueOrFalse', Number(v.value)),
		[setValue]
	)

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const subSubject = data?.subSubjectSelect?.map((r) => {
			return r.value
		})

		const year = data?.yearSelect?.map((r) => {
			return r.value
		})

		const position = data?.occupationSelect?.map((r) => {
			return r.value
		})

		const jury = data?.bancaSelect?.map((r) => {
			return r.value
		})

		const institution = data?.institutionSelect?.map((r) => {
			return r.value
		})

		const dataQuiz = {
			title: data.title,
			description: data.enunciado,
			explanation: data.comment,
			isTrue: !!data.trueOrFalse,
			submatterIds: subSubject || [],
			yearIds: year || [],
			positionIds: position || [],
			juryIds: jury || [],
			institutionIds: institution || [],
		}

		addQuiz(dataQuiz)
		reset()
		setValue('trueOrFalse', data.trueOrFalse)
	}

	useEffect(() => {
		setValue('trueOrFalse', 1)
	}, [setValue])

	useEffect(() => {
		if (isSuccess) {
			navigate(
				`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`
			)
			dispatch(notificationCreators.show(successMessages.storedQuiz))
		}
	}, [isSuccess, dispatch, navigate, id, subjectId, subSubjectId])

	const { data: dataSubSubject } = disciplineApi.useListSubSubjectQuery({
		matterId: subjectId as string,
		page: 1,
		search: '',
	})

	const { data: dataYear } = yearApi.useListQuery({
		page: 1,
		search: '',
		limit: 100000,
	})

	const { data: dataOccupation } = occupationApi.useListQuery({
		page: 1,
		search: '',
		limit: 100000,
	})

	const { data: dataBanca } = bancaApi.useListQuery({
		page: 1,
		search: '',
		limit: 100000,
	})

	const { data: dataInstitution } = institutionApi.useListQuery({
		page: 1,
		search: '',
		limit: 100000,
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Cadastro de Questão' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='grid grid-cols-2 gap-4'>
						<InputGroup label='Sub Assunto' id='subSubjectSelect'>
							<Controller
								control={control}
								name='subSubjectSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('subSubjectSelect', v)}
											options={
												dataSubSubject?.data
													? dataSubSubject.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Ano' id='yearSelect'>
							<Controller
								control={control}
								name='yearSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('yearSelect', v)}
											options={
												dataYear?.data
													? dataYear.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Cargo' id='occupationSelect'>
							<Controller
								control={control}
								name='occupationSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('occupationSelect', v)}
											options={
												dataOccupation?.data
													? dataOccupation.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Banca' id='bancaSelect'>
							<Controller
								control={control}
								name='bancaSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('bancaSelect', v)}
											options={
												dataBanca?.data
													? dataBanca.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Instituição' id='institutionSelect'>
							<Controller
								control={control}
								name='institutionSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('institutionSelect', v)}
											options={
												dataInstitution?.data
													? dataInstitution.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Título' id='title'>
							<Input
								type='text'
								{...register('title')}
								isValid={inputIsValid(errors.title, touchedFields.title)}
							/>
						</InputGroup>
					</div>

					<Controller
						{...register('enunciado')}
						name='enunciado'
						control={control}
						render={({ field: { onChange, onBlur } }) => (
							<InputGroup
								className={`mt-6 index-0-controler ${
									errors.enunciado && 'error-textBox'
								}`}
								label='Enunciado'
								id='enunciado'
							>
								<TextEditor onChange={onChange} onBlur={onBlur} />
							</InputGroup>
						)}
					/>

					<Controller
						{...register('comment')}
						name='comment'
						control={control}
						render={({ field: { onChange, onBlur } }) => (
							<InputGroup
								className={`mt-6 ${errors.comment && 'error-textBox'}`}
								label='Comentário'
								id='comment'
							>
								<TextEditor onChange={onChange} onBlur={onBlur} />
							</InputGroup>
						)}
					/>

					<div className='flex justify-start max-w-[50%]'>
						<InputGroup
							className='mt-6'
							label='Verdadeiro/Falso'
							id='trueOrFalse'
						>
							<SelectInput
								options={[
									{ option: 'Verdadeiro', value: 1 },
									{ option: 'Falso', value: 0 },
								]}
								{...register('trueOrFalse')}
								onChange={onTypeChange}
								btnClass='px-[13px] py-[8px] w-[150px]'
							/>
						</InputGroup>
						{/* <InputGroup className='mt-6' label='Cor' id='color'>
							<input
								type='color'
								id='head'
								{...register('color')}
								name='color'
							/>
						</InputGroup> */}
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button
							variant='outline'
							onClick={() =>
								navigate(
									`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}/`
								)
							}
						>
							Cancelar
						</Button>
						<Button type='submit'>Adicionar Questão</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default CreateQuiz
