import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
	Button,
	Divider,
	EmptyRow,
	Flex,
	Pagination,
	SearchInput,
	Spinner,
	Table,
	Td,
	Tr,
} from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import Loading from '@pages/Loading'
import { categoryApi } from '@services/CategoryApi'
import { examsApi } from '@services/ExamsApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import {
	createBreadcrumbLink,
	useCreateBreadcrumb,
	useFilters,
	useInputBounce,
	useQuery,
} from '@utils/hooks'

import EditCategory from '../EditCategory'
import AddExams from './AddExams'
import EditExams from './EditExams'

const styles = {
	searchContainer: 'gap-[8px] my-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function IndividualCategory() {
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''
	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { id } = useParams<{
		id: string
	}>()

	const { data: categoryInfo } = categoryApi.useShowQueryQuery({
		id: String(id) as string,
	})

	const { data, isLoading } = examsApi.useListQuery({
		id,
		page,
		search: encodeURIComponent(searchQuery),
	})

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Categorias`, `/categorias`, 1),
			createBreadcrumbLink(`${categoryInfo?.name}`, `/categorias/${id}`, 2),
		]
	}, [categoryInfo?.name, id])

	const pages = useCreateBreadcrumb(allPages)

	const [
		deleteDiscipline,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = examsApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate(`/categorias/${id}`)
			setSearchQuery(value)
		},
		[id, navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteDiscipline))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/categorias',
	})

	const options = [
		{
			label: 'Editar Categoria',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editCategory',
						modalInfo: id,
					})
				),
		},
	]

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${categoryInfo?.name}`}
					options={options}
				/>

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Simulado'
					/>

					<Button
						onClick={() =>
							dispatch(
								openModal({
									name: 'addExams',
									modalInfo: id,
								})
							)
						}
					>
						Adicionar Simulado
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table th={[{ label: 'id' }, { label: 'nome' }, { label: 'ações' }]}>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(({ id: simulatedId, name }) => (
								<Tr key={simulatedId}>
									<Td>{simulatedId}</Td>
									<Td className='w-[75%] px-6'>
										<Link to={`/categorias/${id}/simulado/${simulatedId}`}>
											<Flex className='items-center gap-4'>{name}</Flex>
										</Link>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editExams',
															modalInfo: simulatedId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeDiscipline = {
														title: 'Remover Simulado',
														message:
															'Tem certeza que deseja remover o simulado?',
														buttonLabel: 'Remover Simulado',
														onClick: () =>
															deleteDiscipline({
																id: simulatedId,
															}),
													}

													dispatch(openAlertModal(removeDiscipline))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhum simulado encontrado' />
						)}
					</Table>
				)}
				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
			<EditCategory />
			<AddExams />
			<EditExams />
		</>
	)
}

export default IndividualCategory
