import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { MarkInput, Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { errorsQuestionsApi } from '@services/errosQuestionsApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AnswerQuestion from './EditQuestions'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

const errorTypes: { [key: string]: string } = {
	answer_error: 'Erro no gabarito',
	title_error: 'Erro no titulo',
	description_error: 'Erro no enunciado',
}

function ErrorsQuestions() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Erros de Questões', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = errorsQuestionsApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteErrorsQuestions,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = errorsQuestionsApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/erros-questoes?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteErrorQuestion))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/erros-questoes',
	})

	const [checkQuestion, { isSuccess }] =
		errorsQuestionsApi.useCheckQuestionMutation()

	// eslint-disable-next-line no-shadow
	function onSubmit(data: { id: number; isCorrected: boolean }) {
		checkQuestion({
			id: data.id,
			isCorrected: data.isCorrected,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				notificationCreators.show(successMessages.updatedStatusErrorQuestion)
			)
		}
	}, [isSuccess, dispatch, navigate])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Erros de Questões' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Erro de Questão'
					/>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{ label: 'aluno' },
							{ label: 'ID Questão' },
							{ label: 'Título da Questão' },
							{ label: 'Tipo de erro' },
							{ label: 'Erro Reportado' },
							{ label: 'Data Cadastro' },
							{ label: 'Versão do APP' },
							{ label: 'SO Versão' },
							{ label: 'Corrigido' },
							{ label: 'ações' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map((errorQuestion) => (
								<Tr key={errorQuestion.id}>
									<Td>{errorQuestion.id}</Td>
									<Td>{`${errorQuestion.client.name} ${errorQuestion.client.email}`}</Td>
									<Td>
										<Link
											to={`/questoes/editar-questao/${errorQuestion.questionId}`}
											state={{ prevQs: search }}
											target='_blank'
										>
											{errorQuestion.questionId}
										</Link>
									</Td>
									<Td>
										{' '}
										<Link
											to={`/questoes/editar-questao/${errorQuestion.questionId}`}
											state={{ prevQs: search }}
											target='_blank'
										>
											{errorQuestion.question.title}
										</Link>
									</Td>
									<Td>{errorTypes[errorQuestion.type]}</Td>
									<Td>{errorQuestion.text}</Td>
									<Td>
										{Formatters.createdAt(errorQuestion.createdAt as string)}
									</Td>
									<Td>{errorQuestion.appVersion}</Td>
									<Td>{errorQuestion.osVersion}</Td>
									<Td>
										<MarkInput
											type='checkbox'
											itens={[{ id: '1', title: '' }]}
											defaultChecked={Boolean(errorQuestion.isCorrected)}
											multiCheckbox
											onChange={(e) =>
												onSubmit({
													id: errorQuestion.id,
													isCorrected: e.currentTarget.checked,
												})
											}
										/>
									</Td>

									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'answerQuestion',
															modalInfo: errorQuestion.id,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeErrorsQuestions = {
														title: 'Remover Erro de Questão',
														message:
															'Tem certeza que deseja remover o erro da Questão?',
														buttonLabel: 'Remover Erro da Questão',
														onClick: () =>
															deleteErrorsQuestions({
																id: errorQuestion.id,
															}),
													}

													dispatch(openAlertModal(removeErrorsQuestions))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={8} message='Nenhum erro encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
			<AnswerQuestion />
		</>
	)
}

export default ErrorsQuestions
