import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface GetBreadcrumb {
	end: number
	href: string
	name: string
}

export const createBreadcrumbLink = (
	name: string,
	href: string,
	end: number
) => {
	return { name, href, end }
}

/**
 * Lista todas as páginas do array para construir um breadcrumb (a ordem dos links no array influencia o resultado)
 * @param allPages um array memoizado, contendo todas as páginas a serem listadas (end representa qual posição o link vai ser mostrado em ordem crescente)
 * @example const pages = useMemo(() => [
 * createBreadcrumbLink('Membros', '/members', 1),
 * createBreadcrumbLink('Estudantes', '/students', 2)
 * ], [])
 *
 * const breadCrumb = useCreateBreadCrumb(pages)
 */
export default function useCreateBreadcrumb(
	allPages: Array<{ name: string; href: string; end: number }>
) {
	const { pathname } = useLocation()

	const [pages, setPages] = useState<Array<{ name: string; href: string }>>([])

	useEffect(() => {
		function getBreadcrumb({ end, name, href }: GetBreadcrumb) {
			return () =>
				setPages(() => {
					const newPages = allPages.map(({ name: name_, href: href_ }) => ({
						name: name_,
						href: href_,
					}))

					newPages[end - 1] = { name, href }

					const newState = newPages

					return newState.slice(0, end)
				})
		}

		const pagesObject: Record<string, CallableFunction> = {}

		allPages.forEach((page) => {
			pagesObject[page.href.split('?')[0]] = getBreadcrumb({
				end: page.end,
				name: page.name,
				href: page.href,
			})
		})

		pagesObject[pathname] && pagesObject[pathname]()
	}, [pathname, allPages])

	return pages
}
