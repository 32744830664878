import { UserRoles } from '@models/auth.model'
import { useAppSelector } from '@store/hooks'

export default function useValidateUserRole(...args: Array<UserRoles>) {
	const {
		auth: { user },
	} = useAppSelector((state) => state)

	let hasPermission = false

	user?.roles?.forEach((role) => {
		if (args.includes(role.name)) {
			hasPermission = true
		}
	})

	return hasPermission
}
