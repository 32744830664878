import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NotificationState {
	show: boolean
	options: { title: string; message: string }
}

const initialState: NotificationState = {
	show: false,
	options: {
		title: '',
		message: '',
	},
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		show: (
			state,
			{
				payload: { title, message },
			}: PayloadAction<{ title: string; message: string }>
		) => {
			state.options = initialState.options

			state.options = { title, message }
			state.show = true
		},

		hide: (state) => {
			state.show = false
		},
	},
})

export const notificationCreators = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer
