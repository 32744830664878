import * as yup from 'yup'

export const addModuleSchema = yup.object({
	name: yup.string().required(),
	url: yup.string().required(),
	classIds: yup
		.array()
		.test((value) => {
			if (!value) {
				return false
			}

			return value.length > 0
		})
		.required(),
})
