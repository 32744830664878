import React from 'react'

import Flex from '@components/Flex'

interface ButtonFloatingProps {
	onComplete: CallableFunction
	onCancel: CallableFunction
}

function ButtonFloating({
	onComplete,

	onCancel,
}: ButtonFloatingProps) {
	return (
		<Flex
			className='fixed bottom-8 right-20 px-8 py-4 rounded-full shadow-md bg-primary text-white gap-3 font-medium text-lg'
			dir='row'
		>
			<button type='button' onClick={() => onComplete()}>
				Aprovar
			</button>
			<button type='button' onClick={() => onCancel()}>
				Reprovar
			</button>
		</Flex>
	)
}

export default ButtonFloating
