import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Breadcrumb from '@components/Breadcrumb'
import InfoBox from '@components/InfoBox'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import Loading from '@pages/Loading'
import { userApi } from '@services/UserApi'
import Formatters from '@utils/helpers/Formatters'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

export function ProfileUser() {
	const { id } = useParams<{ id: string }>()

	const { data: userInfo, isLoading } = userApi.useShowUserQuery({
		id: String(id) as string,
	})

	const baseUrl = `/usuarios/${id}`
	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Usuários`, '/usuarios', 1),
			createBreadcrumbLink(`${userInfo?.name}`, baseUrl, 2),
		]
	}, [baseUrl, userInfo?.name])
	const pages = useCreateBreadcrumb(allPages)

	const infos = [
		{
			label: 'Nome',
			item: <p>{userInfo?.name}</p>,
		},
		{
			label: 'Email',
			item: <p>{userInfo?.email}</p>,
		},
		{
			label: 'Permissões',
			item: <p>{userInfo?.roles?.map((role) => role?.name).join(', ')}</p>,
		},
		{
			label: 'Criado em',
			item: <p>{Formatters.createdAt(userInfo?.createdAt as string)}</p>,
		},
		{
			label: 'Atualizado em',
			item: <p>{Formatters.createdAt(userInfo?.updatedAt as string)}</p>,
		},
	]

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />

				<UserBanner
					name={`${userInfo?.name}`}
					info={`${userInfo?.email}`}
					avatar={userInfo?.imageUrl ? userInfo?.imageUrl : undefined}
				/>
				<InfoBox
					title='Informações'
					subtitle=''
					itens={infos}
					width={500}
					myHeader='py-5'
					mtBox='mt-4'
					mbBox='mb-10'
				/>
			</Template>
		</>
	)
}
