import React from 'react'

import { SearchIcon } from '@heroicons/react/outline'

interface SearchInputProps {
	className?: string
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
	placeholder?: string
	value?: string
}

const styles = {
	iconContainer:
		'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none',
	icon: 'h-5 w-5 text-gray-400',
	input:
		'w-full text-[14px] outline-0 py-[8px] pl-[45px] pr-[18px] rounded-md border-[1px] border-gray-300 shadow-3 placeholder-gray-500 font-medium',
}

export default function SearchInput({
	onChange,
	className = '',
	placeholder = '',
	value,
}: SearchInputProps) {
	return (
		<div className={`relative ${className}`}>
			<div className={styles.iconContainer}>
				<SearchIcon className={styles.icon} aria-hidden='true' />
			</div>

			<input
				className={styles.input}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
			/>
		</div>
	)
}
