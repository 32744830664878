import { cpf, cnpj } from 'cpf-cnpj-validator'

export function formatCpfCnpj(cpfCnpj: string) {
	if (cpfCnpj.length <= 11) {
		cpfCnpj = cpf.format(cpfCnpj)
	} else {
		cpfCnpj = cnpj.format(cpfCnpj)
	}

	return cpfCnpj
}
