import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Breadcrumb, NavTab, Template } from '@components'
import { LinkManager } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

import ListAnnouncement from './ListAnnouncement'
import ListAnnouncementClass from './ListAnnouncementClass'

function Announcement() {
	const { pathname } = useLocation()

	const baseUrl = `/comunicados`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Comunicados`, baseUrl, 1),
			createBreadcrumbLink(`Comunicados de Turma`, `${baseUrl}/turma`, 2),
		]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [
		{ title: 'Comunicados', to: createLink('') },
		{ title: 'Comunicados de Turma', to: createLink('/turma') },
	]

	const components = {
		[createLink('')]: <ListAnnouncement />,
		[createLink('/turma')]: <ListAnnouncementClass />,
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />

				<div className='flex justify-between items-end'>
					<NavTab links={links} />
				</div>
				{components[pathname]}
			</Template>
		</>
	)
}

export default Announcement
