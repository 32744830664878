import React, { useEffect, useState } from 'react'
import { Droppable, DroppableProps } from 'react-beautiful-dnd'

interface TableProps {
	th: Array<{ label: string; icon?: JSX.Element }>
	children: React.ReactNode
	variant?: 'gray' | 'mediumGray' | 'darkGray' | 'mixed'
	containerClass?: string
	droppable?: boolean
	css?: string
	thtCss?: string
	thCss?: string
}

const styles = {
	container:
		'relative overflow-x-auto border-[1px] border-gray-200 rounded-sm shadow-5',
	table: 'w-full text-sm text-left text-gray-500',
	thead: 'text-xs uppercase sticky top-0 shadow-border',
	theadGray: 'text-gray',
	theadMediumGray: 'text-gray-500',
	theadDarkGray: 'text-gray-700',
	theadMixed: 'text-gray',
	th: 'px-6 py-3',
	thMixed: 'first:text-gray-500',
	thContent: 'flex items-center gap-2',
}

export function StrictModeDroppable({ children, ...props }: DroppableProps) {
	const [enabled, setEnabled] = useState(false)

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true))

		return () => {
			cancelAnimationFrame(animation)
			setEnabled(false)
		}
	}, [])

	if (!enabled) {
		return null
	}

	return <Droppable {...props}>{children}</Droppable>
}

export default function Table({
	th,
	children,
	variant = 'mediumGray',
	containerClass = '',
	css = '',
	thtCss,
	thCss,
	droppable = false,
}: TableProps) {
	const variantStyle = {
		gray: styles.theadGray,
		mediumGray: styles.theadMediumGray,
		darkGray: styles.theadDarkGray,
		mixed: `${styles.theadMixed}`,
	}

	return (
		<div className={`${styles.container} ${containerClass} ${css} `}>
			<table className={styles.table}>
				<thead className={`${styles.thead} ${variantStyle[variant]} ${thtCss}`}>
					<tr>
						{th.map(({ label, icon }, idx) => (
							<th
								key={idx}
								scope='col'
								className={`${thCss} ${styles.th} ${
									variant === 'mixed' ? styles.thMixed : ''
								}`}
							>
								<span className={styles.thContent}>
									{icon && <button type='button'>{icon}</button>}
									{label}
								</span>
							</th>
						))}
					</tr>
				</thead>

				{droppable ? (
					<StrictModeDroppable droppableId='tbody'>
						{(provided) => (
							<tbody ref={provided.innerRef} {...provided.droppableProps}>
								{children}
								{provided.placeholder}
							</tbody>
						)}
					</StrictModeDroppable>
				) : (
					<tbody>{children}</tbody>
				)}
			</table>
		</div>
	)
}
