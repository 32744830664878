import React from 'react'

interface DividerProps {
	className?: string
}

const styles = {
	divider: 'bg-gray-200 h-[1px]',
}

export default function Divider({ className = '' }: DividerProps) {
	return <div className={`${styles.divider} ${className}`} />
}
