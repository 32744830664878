import React, { useEffect, useRef } from 'react'

interface TdProps
	extends React.DetailedHTMLProps<
		React.TdHTMLAttributes<HTMLTableCellElement>,
		HTMLTableCellElement
	> {
	children: React.ReactNode
	as?: 'th'
	css?: string
	hasLink?: boolean
}

const styles = {
	th: 'font-medium text-gray-900',
	td: 'px-6 py-4',
	link: 'flex w-full cursor-pointer',
}

export default function Td({
	children,
	css,
	as,
	hasLink = false,
	...props
}: TdProps) {
	const tdRef = useRef<HTMLTableCellElement>(null)

	if (typeof children === 'string') {
		children = <div>{children}</div>
	}

	const children_ = hasLink
		? React.cloneElement(children as JSX.Element, {
				className: `${props.className} ${styles.td} ${styles.link} ${
					as === 'th' ? 'font-medium' : ''
				}`,
		  })
		: children

	useEffect(() => {
		document.querySelectorAll('.hasLink').forEach((el) => {
			if (el.classList.contains('hasLink')) {
				const parent = el.parentElement

				el.addEventListener('mouseenter', () => {
					parent?.classList?.add('hover')
				})

				el.addEventListener('mouseleave', () => {
					parent?.classList?.remove('hover')
				})
			}
		})
	}, [])

	if (as === 'th') {
		return (
			<th
				scope='row'
				className={
					hasLink ? `${styles.th} hasLink` : `${styles.th} ${styles.td}`
				}
				{...props}
			>
				{children_}
			</th>
		)
	}

	return (
		<td
			className={hasLink ? `hasLink` : `${styles.td} ${css}`}
			{...props}
			ref={tdRef}
		>
			{children_}
		</td>
	)
}
