import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { RootState } from '@store'

export default function setAuthorization(
	headers: Headers,
	{ getState, endpoint }: Pick<BaseQueryApi, 'getState' | 'endpoint'>
) {
	const { token } = (getState() as RootState).auth

	if (endpoint.includes('avatar' || 'pdf')) {
		headers.set('content-type', 'multipart/form-data')
	}

	const { location } = window

	headers.set('current-url', `${location.pathname}${location.search}`)

	headers.set('Accept', 'application/json')

	if (token) {
		headers.set('authorization', `Bearer ${token}`)
	} else {
		headers.delete('authorization')
	}

	return headers
}
