import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Input, InputGroup, MarkInput } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import FileInput from '@components/FileInput'
import PageTitle from '@components/PageTitle'
import SelectInput, { Option } from '@components/SelectInput'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addClassesSchema } from '@schemas/addClasses.schema'
import { classesApi } from '@services/ClassesApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

type Inputs = {
	name: string
	period: { value: string | number; label: string }
	pdf: string
	question: string
	general: string
	description: string
	imageUrl: Array<Blob>
	isPaid: boolean
	showExpiration: string
	codes: Array<{ code: string; type: string }>
	csvAluno: Array<File>
	annual: string
	annual2: string
	semiannual: string
	monthly: string
	monthly2: string
	monthly3: string
	monthly4: string
	monthly5: string
	monthly7: string
	monthly8: string
	monthly9: string
	monthly10: string
	monthly11: string
	weekly: string
}

function CreateClasses() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const baseUrl = `/turmas/cadastrar-turma`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Turmas`, `/turmas`, 1),
			createBreadcrumbLink(`Cadastrar Turma`, baseUrl, 2),
		]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const [addClass, { isSuccess }] = classesApi.useAddMutation()

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, touchedFields },
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addClassesSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const codes = []

		if (data.annual) {
			codes.push({ code: data.annual.replace(/\s/g, ''), type: 'ANNUAL' })
		}

		if (data.annual2) {
			codes.push({ code: data.annual2.replace(/\s/g, ''), type: 'ANNUAL2' })
		}

		if (data.monthly) {
			codes.push({ code: data.monthly.replace(/\s/g, ''), type: 'MONTHLY' })
		}
		if (data.monthly2) {
			codes.push({ code: data.monthly2.replace(/\s/g, ''), type: 'MONTHLY2' })
		}
		if (data.monthly3) {
			codes.push({ code: data.monthly3.replace(/\s/g, ''), type: 'MONTHLY3' })
		}
		if (data.monthly4) {
			codes.push({ code: data.monthly4.replace(/\s/g, ''), type: 'MONTHLY4' })
		}
		if (data.monthly5) {
			codes.push({ code: data.monthly5.replace(/\s/g, ''), type: 'MONTHLY5' })
		}
		if (data.monthly7) {
			codes.push({ code: data.monthly7.replace(/\s/g, ''), type: 'MONTHLY7' })
		}
		if (data.monthly8) {
			codes.push({ code: data.monthly8.replace(/\s/g, ''), type: 'MONTHLY8' })
		}
		if (data.monthly9) {
			codes.push({ code: data.monthly9.replace(/\s/g, ''), type: 'MONTHLY9' })
		}
		if (data.monthly10) {
			codes.push({ code: data.monthly10.replace(/\s/g, ''), type: 'MONTHLY10' })
		}
		if (data.monthly11) {
			codes.push({ code: data.monthly11.replace(/\s/g, ''), type: 'MONTHLY11' })
		}

		if (data.semiannual) {
			codes.push({
				code: data.semiannual.replace(/\s/g, ''),
				type: 'SEMIANNUAL',
			})
		}

		if (data.weekly) {
			codes.push({ code: data.weekly.replace(/\s/g, ''), type: 'WEEKLY' })
		}

		const newData = {
			period: data.period.value as string,
			name: data.name,
			pdf: !!data.pdf,
			question: !!data.question,
			general: !!data.general,
			isPaid: !data.isPaid,
			imageUrl: data.imageUrl,
			description: data.description,
			showExpiration: !!data.showExpiration,
			clientsCsv: data.csvAluno,
			codes,
		}

		addClass(newData)
	}

	useEffect(() => {
		if (isSuccess) {
			navigate('/turmas')
			dispatch(notificationCreators.show(successMessages.storedClasses))
			reset()
		}
	}, [isSuccess, dispatch, navigate, reset])

	const periodOptions = useMemo(
		() => [
			{
				option: '7 dias',
				value: 'WEEKLY',
			},
			{ option: 'Mensal', value: 'MONTHLY' },
			{ option: 'Bimestral', value: 'MONTHLY2' },
			{ option: 'Trimestral', value: 'MONTHLY3' },
			{ option: '4 Meses', value: 'MONTHLY4' },
			{ option: '5 Meses', value: 'MONTHLY5' },
			{ option: 'Semestral', value: 'SEMIANNUAL' },
			{ option: '7 Meses', value: 'MONTHLY7' },
			{ option: '8 Meses', value: 'MONTHLY8' },
			{ option: '9 Meses', value: 'MONTHLY9' },
			{ option: '10 Meses', value: 'MONTHLY10' },
			{ option: '11 Meses', value: 'MONTHLY11' },
			{ option: 'Anual', value: 'ANNUAL' },
			{ option: '2 Anos', value: 'ANNUAL2' },
		],
		[]
	)

	const onPeriodCsvRegistrationChange = useCallback(
		(v: Option) => setValue('period', { value: v.value, label: v.option }),
		[setValue]
	)

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Cadastrar Turma' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='grid grid-cols-2 gap-4'>
						<InputGroup label='Título do Turma' id='name'>
							<Input
								type='text'
								{...register('name')}
								isValid={inputIsValid(errors.name, touchedFields.name)}
							/>
						</InputGroup>

						<InputGroup label='Descrição' id='description'>
							<Input
								type='text'
								{...register('description')}
								isValid={inputIsValid(
									errors.description,
									touchedFields.description
								)}
							/>
						</InputGroup>

						<InputGroup label='Código 7 dias' id='weekly'>
							<Input
								type='text'
								{...register('weekly')}
								isValid={inputIsValid(errors.weekly, touchedFields.weekly)}
							/>
						</InputGroup>

						<InputGroup label='Código Mensal' id='monthly'>
							<Input
								type='text'
								{...register('monthly')}
								isValid={inputIsValid(errors.monthly, touchedFields.monthly)}
							/>
						</InputGroup>

						<InputGroup label='Código Bimestral' id='monthly2'>
							<Input
								type='text'
								{...register('monthly2')}
								isValid={inputIsValid(errors.monthly2, touchedFields.monthly2)}
							/>
						</InputGroup>

						<InputGroup label='Código Trimestral' id='monthly3'>
							<Input
								type='text'
								{...register('monthly3')}
								isValid={inputIsValid(errors.monthly3, touchedFields.monthly3)}
							/>
						</InputGroup>

						<InputGroup label='Código 4 Meses' id='monthly4'>
							<Input
								type='text'
								{...register('monthly4')}
								isValid={inputIsValid(errors.monthly4, touchedFields.monthly4)}
							/>
						</InputGroup>

						<InputGroup label='Código 5 Meses' id='monthly5'>
							<Input
								type='text'
								{...register('monthly5')}
								isValid={inputIsValid(errors.monthly5, touchedFields.monthly5)}
							/>
						</InputGroup>

						<InputGroup label='Código Semestral' id='semiannual'>
							<Input
								type='text'
								{...register('semiannual')}
								isValid={inputIsValid(
									errors.semiannual,
									touchedFields.semiannual
								)}
							/>
						</InputGroup>

						<InputGroup label='Código 7 Meses' id='monthly7'>
							<Input
								type='text'
								{...register('monthly7')}
								isValid={inputIsValid(errors.monthly7, touchedFields.monthly7)}
							/>
						</InputGroup>

						<InputGroup label='Código 8 Meses' id='monthly8'>
							<Input
								type='text'
								{...register('monthly8')}
								isValid={inputIsValid(errors.monthly8, touchedFields.monthly8)}
							/>
						</InputGroup>

						<InputGroup label='Código 9 Meses' id='monthly9'>
							<Input
								type='text'
								{...register('monthly9')}
								isValid={inputIsValid(errors.monthly9, touchedFields.monthly9)}
							/>
						</InputGroup>

						<InputGroup label='Código 10 Meses' id='monthly10'>
							<Input
								type='text'
								{...register('monthly10')}
								isValid={inputIsValid(
									errors.monthly10,
									touchedFields.monthly10
								)}
							/>
						</InputGroup>

						<InputGroup label='Código 11 Meses' id='monthly11'>
							<Input
								type='text'
								{...register('monthly11')}
								isValid={inputIsValid(
									errors.monthly11,
									touchedFields.monthly11
								)}
							/>
						</InputGroup>

						<InputGroup label='Código Anual' id='annual'>
							<Input
								type='text'
								{...register('annual')}
								isValid={inputIsValid(errors.annual, touchedFields.annual)}
							/>
						</InputGroup>

						<InputGroup label='Código 2 Anos' id='annual2'>
							<Input
								type='text'
								{...register('annual2')}
								isValid={inputIsValid(errors.annual2, touchedFields.annual2)}
							/>
						</InputGroup>

						<InputGroup
							className='col-span-2'
							label='Período para cadastro de CSV'
							id='period'
						>
							<Controller
								control={control}
								name='period'
								defaultValue={{
									label: '7 dias',
									value: 'WEEKLY',
								}}
								render={() => {
									return (
										<SelectInput
											btnClass='py-[8px] w-full px-[13px]'
											options={periodOptions}
											onChange={onPeriodCsvRegistrationChange}
										/>
									)
								}}
							/>
						</InputGroup>
						<Controller
							control={control}
							name='pdf'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											multiCheckbox
											itens={[{ id: '1', title: 'Acesso ao PDF' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'pdf',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='question'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											multiCheckbox
											itens={[{ id: '1', title: 'Acesso as questões' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'question',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='general'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											multiCheckbox
											itens={[{ id: '1', title: 'Acesso geral' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'general',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='showExpiration'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											multiCheckbox
											itens={[{ id: '1', title: 'Mostrar data de expiração' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue(
													'showExpiration',
													e.currentTarget.checked as unknown as string
												)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='isPaid'
							render={({ field: { onBlur } }) => {
								return (
									<>
										<MarkInput
											className='mt-4'
											type='checkbox'
											multiCheckbox
											itens={[{ id: '1', title: 'Gratuito' }]}
											onBlur={onBlur}
											onChange={(e) => {
												setValue('isPaid', e.currentTarget.checked)
											}}
										/>
									</>
								)
							}}
						/>

						<Controller
							control={control}
							name='csvAluno'
							render={() => {
								return (
									<FileInput
										className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
										mt='mt-4'
										accept='.csv'
										onChange={(v) => {
											setValue('csvAluno', v)
										}}
									>
										CSV Aluno
									</FileInput>
								)
							}}
						/>

						<Controller
							control={control}
							name='imageUrl'
							render={() => {
								return (
									<FileInput
										className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
										mt='mt-4'
										accept='.png'
										onChange={(v) => {
											setValue('imageUrl', v)
										}}
									>
										Imagem da turma
									</FileInput>
								)
							}}
						/>
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button
							variant='outline'
							onClick={() => navigate(`/turmas?page=1`)}
						>
							Cancelar
						</Button>
						<Button type='submit'>Adicionar Turma</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default CreateClasses
