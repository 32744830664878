import React, { useState } from 'react'

import Flex from '@components/Flex'
// import Icon from '@components/Icon'
import Icon from '@components/Icon'
import Option from '@components/Option'
import Options from '@components/Options'

const styles = {
	container:
		'mt-6 rounded-md shadow-3 w-full bg-white py-9 px-8 flex items-center justify-between',
	banner: 'gap-[24px] items-center',
	avatar: 'rounded-full max-w-[60px] max-h-[60px] min-w-[60px] min-h-[60px]',
	name: 'text-dark text-[24px] font-semibold capitalize',
	job: 'text-gray font-medium',
}

interface UserBannerProps {
	name?: string
	info?: string
	avatar?: string
	dontShowAvatar?: boolean
	options?: Array<{
		label: string
		onClick: CallableFunction
		className?: string
	}>
	button?: Array<{
		label: string
		onClick: CallableFunction
	}>
	children?: React.ReactNode
}

export default function UserBanner({
	name,
	info,
	avatar,
	options,
	button,
	dontShowAvatar = false,
	children,
}: UserBannerProps) {
	if (children && options) {
		throw new Error(
			'UserBanner cannot render options and children, pass just one'
		)
	}

	const [notErrorImg, setNotErrorImg] = useState<boolean>(true)

	return (
		<Flex className={styles.container}>
			<Flex className={styles.banner}>
				{(() => {
					if (dontShowAvatar) {
						return <></>
					}

					return avatar && notErrorImg ? (
						<img
							src={avatar}
							alt={name}
							onError={() => setNotErrorImg(false)}
							className={styles.avatar}
							referrerPolicy='no-referrer'
						/>
					) : (
						<Icon
							name='avatar-default'
							className={styles.avatar}
							width={20}
							height={20}
						/>
					)
				})()}
				<div>
					<p className={styles.name}>{name}</p>
					<p className={styles.job}>{info}</p>
				</div>
			</Flex>

			{children}
			{options && <Options options={options} />}
			{button && <Option options={button} />}
		</Flex>
	)
}
