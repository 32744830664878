import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Breadcrumb, PageTitle, Pagination, Template } from '@components'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { popupsApi } from '@services/PopupsApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddPopups from './AddPopups'
import EditPopups from './EditPopups'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function Popups() {
	const pages = [{ name: 'Popups', href: '#', current: true }]
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = popupsApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [deletePopups, { isLoading: loadingDelete, isSuccess: deleteSuccess }] =
		popupsApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/popups?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deletePopups))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/popups',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Popups' />
				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Popups'
					/>

					<Button onClick={() => dispatch(openModal({ name: 'addPopups' }))}>
						Adicionar Popups
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{ label: 'turma' },
							{ label: 'link' },
							{ label: 'Tempo de acesso' },
							{ label: 'Criado em' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(
								({ class: classInfo, id, link, createdAt, accessTime }) => (
									<Tr key={id}>
										<Td>{id}</Td>
										<Td className='w-[40%] px-6'>{classInfo.name}</Td>
										<Td className='w-[45%] px-6'>{link}</Td>
										<Td className='w-[25%] px-6'>
											{accessTime && `${accessTime} Dias`}
										</Td>
										<Td className='w-[70%] px-6'>
											{Formatters.createdAt(createdAt as string)}
										</Td>
										<Td>
											<Flex className='items-center gap-4'>
												<Button
													className='!px-2 !py-2'
													leftIcon={<PencilAltIcon />}
													disabled={false}
													variant='outline'
													onClick={() =>
														dispatch(
															openModal({
																name: 'editPopups',
																modalInfo: id,
															})
														)
													}
												>
													<></>
												</Button>
												<Button
													className='!px-2 !py-2'
													leftIcon={<TrashIcon />}
													disabled={false}
													variant='outline'
													onClick={() => {
														const removePopups = {
															title: 'Remover Popup',
															message:
																'Tem certeza que deseja remover o popup?',
															buttonLabel: 'Remover Popup',
															onClick: () =>
																deletePopups({
																	id,
																}),
														}

														dispatch(openAlertModal(removePopups))
													}}
												>
													<></>
												</Button>
											</Flex>
										</Td>
									</Tr>
								)
							)
						) : (
							<EmptyRow tdAmount={3} message='Nenhum popups encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>

			<AddPopups />
			<EditPopups />
		</>
	)
}

export default Popups
