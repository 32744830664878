/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { closeModal } from '@store/ducks/modal'
import { useAppDispatch, useAppSelector } from '@store/hooks'

interface ModalProps {
	children: React.ReactNode
	name: string
}

const styles = {
	dialog: 'relative z-10',
	background:
		'fixed inset-0 bg-black-transparent bg-opacity-75 transition-opacity',
	container: 'fixed z-10 inset-0 overflow-y-auto',
	subContainer:
		'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0',
	span: 'hidden sm:inline-block sm:align-middle sm:h-screen',
}

export default function Modal({ children, name }: ModalProps) {
	const dispatch = useAppDispatch()
	const { modalName } = useAppSelector((state) => state.modal)

	return (
		<Transition.Root show={modalName === name} as={Fragment}>
			<Dialog
				as='div'
				className={styles.dialog}
				onClose={() => {
					dispatch(closeModal())
				}}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className={styles.background} />
				</Transition.Child>

				<div className={styles.container}>
					<div className={styles.subContainer}>
						<span className={styles.span} aria-hidden='true'>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							{children}
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
