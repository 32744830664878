import React, { MouseEventHandler } from 'react'

interface TrProps {
	children: React.ReactNode
	className?: string
	onClick?: MouseEventHandler<HTMLTableRowElement>
	stripped?: boolean
}

const styles = {
	tr: 'even:bg-white odd:bg-gray-50',
}

const Tr = React.forwardRef<HTMLTableRowElement, TrProps>(
	({ children, className, onClick, stripped = true, ...props }, ref) => {
		return (
			<tr
				ref={ref}
				className={`${stripped ? styles.tr : ''} ${className}`}
				onClick={onClick}
				{...props}
			>
				{children}
			</tr>
		)
	}
)

export default Tr
