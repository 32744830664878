import { BASE_URL } from '@constants/api'
import {
	ListAllQuestionRequest,
	ListAllQuestionResponse,
	ListQuestionRequest,
	ListQuestionResponse,
} from '@models/filters.model'
import {
	AddQuestionsRequest,
	AllFilterResponse,
	FileQuestionsRequest,
	FilterMatterResponse,
	ListMockQuestionsResponse,
	ListQuestionsRequest,
	OrderQuestionRequest,
	QuestionsRequest,
	QuestionsResponse,
	ReportQuestionsRequest,
	ReportQuestionsResponse,
	ShowQuestionsResponse,
} from '@models/questions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const questionsApi = createApi({
	reducerPath: 'questionsApi',
	tagTypes: ['Questions'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListMockQuestionsResponse, ListQuestionsRequest>({
			query: ({ id }) => ({
				url: `/mockQuestions?mockExamId=${id}`,
				method: 'GET',
			}),
			providesTags: ['Questions'],
		}),
		listQuestions: builder.query<ListQuestionResponse, ListQuestionRequest>({
			query: ({ subMatterId, mockId }) => ({
				url: `/question?submatterId=${subMatterId}&mockId=${mockId}`,
				method: 'GET',
			}),
			providesTags: ['Questions'],
		}),
		listAllQuestions: builder.query<
			ListAllQuestionResponse,
			ListAllQuestionRequest
		>({
			query: ({ page, limit, search }) => ({
				url: `/question?page=${page}&limit=${limit}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Questions'],
		}),
		getQuestions: builder.query<QuestionsResponse, QuestionsRequest>({
			query: ({
				value: {
					discipline,
					matter,
					submatter,
					year,
					position,
					jury,
					institution,
					limit = 50,
				},
				page,
				search,
			}) => ({
				url: `/question/general/${page}?disciplineIds=${discipline}&matterIds=${matter}&subMatterIds=${submatter}&yearIds=${year}&positionIds=${position}&juryIds=${jury}&institutionIds=${institution}&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
		}),
		getFileQuestions: builder.mutation<Blob, FileQuestionsRequest>({
			query: ({
				value: {
					discipline,
					matter,
					submatter,
					year,
					position,
					jury,
					institution,
					limit = 50,
				},
			}) => ({
				url: `/question/download/?disciplineIds=${discipline}&matterIds=${matter}&subMatterIds=${submatter}&yearIds=${year}&positionIds=${position}&juryIds=${jury}&institutionIds=${institution}&limit=${limit}`,
				method: 'POST',
				responseType: 'blob',
				cache: 'no-cache',
				responseHandler: (response) => response.blob(),
			}),
		}),
		AllOptionsFilter: builder.mutation<AllFilterResponse, void>({
			query: () => ({
				url: '/filters/general',
				method: 'GET',
			}),
		}),
		OptionMatter: builder.mutation<FilterMatterResponse, string>({
			query: (ids) => ({
				url: `/filters/general?filter=matter&dependencies=${ids}`,
				method: 'GET',
			}),
		}),
		OptionSubMatter: builder.mutation<FilterMatterResponse, string>({
			query: (ids) => ({
				url: `/filters/general?filter=submatter&dependencies=${ids}`,
				method: 'GET',
			}),
		}),
		show: builder.mutation<ShowQuestionsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/mockQuestions/${id}`,
				method: 'GET',
			}),
		}),
		showQuery: builder.query<ShowQuestionsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/mockQuestions/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddQuestionsRequest>({
			query: ({ questionIds, mockExamId }) => {
				return {
					url: `/mockQuestions`,
					body: {
						questionIds,
						mockExamId,
					},
					method: 'POST',
				}
			},
			invalidatesTags: ['Questions'],
		}),
		OrderQuestion: builder.mutation<void, OrderQuestionRequest>({
			query: ({ questionId, mockExamId, newPosition }) => {
				return {
					url: `/mockQuestions/reorder`,
					body: {
						questionId,
						mockExamId,
						newPosition,
					},
					method: 'POST',
				}
			},
			invalidatesTags: ['Questions'],
		}),
		delete: builder.mutation<void, { questionId: number; examId?: string }>({
			query: ({ questionId, examId }) => ({
				url: `/mockQuestions/${questionId}?mockExamId=${examId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Questions'],
		}),
		deleteQuiz: builder.mutation<void, { id: string | number }>({
			query: ({ id }) => ({
				url: `/question/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Questions'],
		}),
		report: builder.mutation<ReportQuestionsResponse, ReportQuestionsRequest>({
			query: ({ startDate, endDate }) => ({
				url: `/reports/question?startDate=${startDate}&endDate=${endDate}`,
				method: 'GET',
			}),
		}),
	}),
})
