import { BASE_URL } from '@constants/api'
import {
	AddPopupsRequest,
	ListPopupsRequest,
	ListPopupsResponse,
	ShowPopupsResponse,
	UpdatePopupsRequest,
} from '@models/popups.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const popupsApi = createApi({
	reducerPath: 'popupsApi',
	tagTypes: ['Popups'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListPopupsResponse, ListPopupsRequest>({
			query: ({ page, search = '' }) => ({
				url: `/popups?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Popups'],
		}),
		showPopups: builder.query<ShowPopupsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'GET',
			}),
			providesTags: ['Popups'],
		}),
		show: builder.mutation<ShowPopupsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddPopupsRequest>({
			query: (data) => {
				return {
					url: `/popups`,
					body: data,
					method: 'POST',
				}
			},
			invalidatesTags: ['Popups'],
		}),
		edit: builder.mutation<void, UpdatePopupsRequest>({
			query: ({ id, ...dataPopups }) => {
				return {
					url: `/popups/${id}`,
					body: dataPopups,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Popups'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Popups'],
		}),
	}),
})
