/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import { Input, InputGroup, Spinner } from '@components'
import Button from '@components/Button'
import FileInput from '@components/FileInput'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addUserSchema } from '@schemas/addUser.schema'
import { userApi } from '@services/UserApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	email: string
	image: Array<string | Blob>
	password: string
	roles: string[]
	rolesSelect?: MultiValue<{ value: string | number; label: string }>
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditUser() {
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const dispatch = useAppDispatch()

	const [getUser, { data: user, isLoading: loadingGetUser }] =
		userApi.useShowMutation()

	const [editUser, { isLoading, isSuccess }] = userApi.useEditMutation()

	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addUserSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const rolesUsers = data?.rolesSelect?.map((r) => {
			return r.value as string
		})

		const dataUser = {
			name: data.name,
			email: data.email,
			image: data.image ? data.image[0] : '',
			password: data.password,
			roles: rolesUsers ? (rolesUsers as string[]) : [],
		}

		editUser({
			...dataUser,
			id: id as number,
		})
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.updatedUser))
		}
	}, [isSuccess, dispatch])

	useEffect(() => {
		if (id && modalName === 'editUser') {
			getUser({
				id,
			})
		}
	}, [id, modalName, getUser])

	useEffect(() => {
		if (user) {
			setValue('name', user?.name as string)
			setValue('email', user?.email as string)
			setValue(
				'rolesSelect',
				user?.roles?.map((e) => ({ value: e.id, label: e.name }))
			)
		}
	}, [user, setValue])

	const rolesOptions = useMemo(
		() => [
			{ value: 1, option: 'Admin' },
			{
				value: 2,
				option: 'Content Creator',
			},
			{
				value: 3,
				option: 'Question Creator',
			},
		],
		[]
	)

	if (loadingGetUser) {
		return (
			<Modal name='editBanca'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editUser'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar Usuário</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar o usuário
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome do usuário' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>
					<InputGroup className='mt-4' label='Email do usuário' id='email'>
						<Input
							isValid={inputIsValid(errors.email, touchedFields.email)}
							{...register('email')}
							type='email'
						/>
					</InputGroup>
					<InputGroup className='mt-4' label='Senha do usuário' id='password'>
						<Input
							isValid={inputIsValid(errors.password, touchedFields.password)}
							{...register('password')}
							type='password'
						/>
					</InputGroup>

					<InputGroup className='mt-4' label='roles' id='rolesSelect'>
						<Controller
							control={control}
							name='rolesSelect'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('rolesSelect', v)}
										options={
											rolesOptions
												? rolesOptions.map(({ option, value: valueRoles }) => ({
														value: valueRoles,
														label: option,
												  }))
												: []
										}
										value={value}
										isMulti
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<Controller
						control={control}
						name='image'
						render={() => {
							return (
								<InputGroup className='mt-4' label='Imagem: ' id='image'>
									<FileInput
										mt='mt-4'
										onChange={(v) => {
											setValue('image', v)
										}}
										accept='image/png, image/jpeg'
									>
										Imagem
									</FileInput>
								</InputGroup>
							)
						}}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar Usuário
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
