import React from 'react'

interface MarkInputProps extends React.HTMLAttributes<HTMLInputElement> {
	type: 'checkbox' | 'radio'
	className?: string
	itens: Array<{
		id: string
		title: string
		name?: string
		checked?: boolean
		disabled?: boolean
	}>
	selectedId?: string | Array<string>
	multiCheckbox?: boolean
}

const radioStyles = {
	container: 'flex items-center space-x-10',
	group: 'flex items-center',
	input: 'focus:ring-primary h-4 w-4 text-primary border-gray-300',
	label: 'ml-3 block font-medium text-gray-700 text-sm',
}

const checkboxStyles = {
	rounded: 'rounded',
}

const MarkInput = React.forwardRef<HTMLInputElement, MarkInputProps>(
	(
		{
			type,
			itens,
			className = '',
			selectedId,
			multiCheckbox = false,
			...props
		},
		ref
	) => {
		return (
			<fieldset>
				<div className={`${radioStyles.container} ${className}`}>
					{itens?.map(({ id, title, name, checked = false, disabled }) => (
						<div key={id} className={radioStyles.group}>
							<input
								id={id}
								type={type}
								ref={ref}
								name={name}
								defaultChecked={checked}
								disabled={disabled}
								checked={(() => {
									if (selectedId) {
										if (typeof selectedId === 'string') return selectedId === id

										if (typeof selectedId === 'object') {
											let selected = false

											selectedId.forEach((item) => {
												if (item === id) selected = true
											})

											return selected
										}
									}

									return undefined
								})()}
								{...props}
								onChange={(e) => {
									e.currentTarget.value = id

									props.onChange && props.onChange(e)

									if (type === 'checkbox' && !multiCheckbox) {
										// pode se tornar um potencial bug se tiver mais de 2 seções de múltiplo check
										const checkboxs = Array.from(
											document.querySelectorAll<HTMLInputElement>(
												'input[type="checkbox"]'
											)
										)

										checkboxs.forEach((checkbox) => {
											if (checkbox === e.target) {
												return
											}

											checkbox.checked = false
										})
									}
								}}
								className={`${radioStyles.input} ${
									type === 'checkbox' ? checkboxStyles.rounded : ''
								} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
							/>
							<label htmlFor={id} className={radioStyles.label}>
								{title}
							</label>
						</div>
					))}
				</div>
			</fieldset>
		)
	}
)

export default MarkInput
