/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import { FileInput, Input, InputGroup } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addLibrarieSchema } from '@schemas/addLibrarie.schema'
import { librariresApi } from '@services/LibrarieApi'
import { pdfApi } from '@services/PdfApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	pdfFiles: Array<string | Blob>
	pdfName: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function AddLibrarie() {
	const dispatch = useAppDispatch()

	const {
		modal: { modalName },
	} = useAppSelector((state) => state)

	const [addLibrarie, { isLoading, isSuccess }] = librariresApi.useAddMutation()

	const [addPdf, { isSuccess: isSuccessPDF }] = pdfApi.useAddMutation()

	const {
		register,
		reset,
		control,
		getValues,
		handleSubmit,
		setValue,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addLibrarieSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		addPdf({ pdf: getValues('pdfFiles') })
			.unwrap()
			.then((res) => {
				addLibrarie({
					name: data.name,
					pdfFiles: res.pdfsArray.map((item) => {
						return {
							name: item.fileName as string,
							file: item.fileName as string,
							deleted: false,
						}
					}),
				})
			})

		reset()
	}

	useEffect(() => {
		if (isSuccess && isSuccessPDF) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedLibrarie))
		}
	}, [isSuccess, dispatch, isSuccessPDF])

	return (
		<Modal name='addLibrarie'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Adicionar Biblioteca</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para adicionar uma nova biblioteca
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome da biblioteca' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<Controller
						control={control}
						name='pdfFiles'
						render={() => {
							return (
								<InputGroup className='mt-4' label='PDF' id='pdfFiles'>
									<FileInput
										mt='mt-4'
										onChange={(v) => {
											setValue('pdfFiles', v)
											setValue('pdfName', v[0].name)
										}}
										accept='application/pdf'
									>
										PDF
									</FileInput>
								</InputGroup>
							)
						}}
					/>
					<p className='text-red-500 mt-4'>{errors.pdfFiles?.message}</p>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>
						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Adicionar Biblioteca
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
