import { BASE_URL } from '@constants/api'
import {
	AddClassesRequest,
	ListClassesRequest,
	ListClassesResponse,
	ShowClassesResponse,
	UpdateClassesRequest,
} from '@models/classes.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const classesApi = createApi({
	reducerPath: 'classesApi',
	tagTypes: ['Classes'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListClassesResponse, ListClassesRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/classes?page=${page}&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Classes'],
		}),
		show: builder.mutation<ShowClassesResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/classes/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddClassesRequest>({
			query: (newData) => {
				const formData = new FormData()

				formData.append('period', newData.period as string)
				formData.append('name', newData.name)
				formData.append('pdf', newData.pdf.toString())
				formData.append('question', newData.question.toString())
				formData.append('general', newData.general.toString())
				formData.append('imageUrl', newData.imageUrl ? newData.imageUrl[0] : '')
				formData.append('description', newData.description)
				formData.append('isPaid', newData.isPaid.toString())
				formData.append('showExpiration', newData.showExpiration.toString())
				formData.append(
					'clientsCsv',
					newData.clientsCsv ? newData.clientsCsv[0] : ''
				)
				formData.append('codes', JSON.stringify(newData.codes))

				return {
					url: `/classes`,
					enctype: 'multipart/form-data',
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Classes'],
		}),
		edit: builder.mutation<void, UpdateClassesRequest>({
			query: ({ id, ...data }) => {
				const formData = new FormData()

				formData.append('period', data.period as string)
				formData.append('name', data.name)
				formData.append('pdf', data.pdf.toString())
				formData.append('question', data.question.toString())
				formData.append('general', data.general.toString())
				formData.append('imageUrl', data.imageUrl ? data.imageUrl[0] : '')
				formData.append(
					'description',
					data.description ? data.description : ' '
				)
				formData.append('isPaid', data.isPaid.toString())
				formData.append('showExpiration', data.showExpiration.toString())
				formData.append('clientsCsv', data.clientsCsv ? data.clientsCsv[0] : '')
				formData.append('codes', JSON.stringify(data.codes))
				formData.append('typeId', '1')

				return {
					url: `/classes/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Classes'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/classes/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Classes'],
		}),
	}),
})
