import React, { useState, Fragment, useEffect } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

export interface Option {
	option: string
	value: string | number
	disabled?: boolean
	id?: string
}

interface SelectInputProps {
	options: Array<Option>
	btnClass?: string
	onChange: (option: Option) => void
	isValid?: boolean
	selectedValue?: string | number
	absolute?: boolean
}

const styles = {
	container: 'relative',
	listboxButton:
		'bg-white relative w-full border  rounded-md shadow-3 text-left cursor-pointer text-sm',
	default: 'border-gray-300',
	valid: 'border-green',
	invalid: 'border-red',
	listboxOption:
		'z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm',
	spanOption: 'block truncate',
	iconGroup:
		'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
	icon: 'h-5 w-5 text-gray-400',
	activeGroup: 'cursor-pointer select-none relative py-2 pl-3 pr-9',
	active: 'text-white bg-primary',
	inactive: 'text-gray-900',
	disabledLabel: 'text-gray font-medium',
	disabled: 'text-gray-300 cursor-default',
	selectedGroup: 'block truncate',
	selected: 'font-semibold',
	notSelected: 'font-normal',
	activeSelectedGroup: 'absolute inset-y-0 right-0 flex items-center pr-4',
	activeSelected: 'text-white',
	inactiveNotSelected: 'text-primary',
	checkIcon: 'h-5 w-5',
}

export default function SelectInput({
	options,
	btnClass = 'px-[13px] py-[6.5px]',
	onChange,
	isValid,
	selectedValue,
	absolute = true,
}: SelectInputProps) {
	const [selected, setSelected] = useState(options[0])

	const [useSelected, setUseSelected] = useState(true)

	useEffect(() => {
		if (typeof selectedValue !== undefined) {
			const value = options.find((option) => option.value === selectedValue)

			value && (value.value === '' || useSelected) && setSelected(value)
		}
	}, [selectedValue, options, useSelected])

	return (
		<Listbox
			value={selected}
			onChange={(value) => {
				onChange(value)

				setSelected(value)
				setUseSelected(false)
			}}
		>
			{({ open }) => (
				<>
					<div className={styles.container}>
						<Listbox.Button
							className={`${styles.listboxButton} ${(() => {
								if (typeof isValid === 'undefined') {
									return styles.default
								}

								if (!isValid) {
									return styles.invalid
								}

								return styles.valid
							})()} ${btnClass}`}
						>
							<span
								className={`${styles.spanOption} ${
									selected?.disabled ? styles.disabledLabel : ''
								}`}
							>
								{selected.option}
							</span>
							<span className={styles.iconGroup}>
								<ChevronDownIcon className={styles.icon} aria-hidden='true' />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Listbox.Options
								className={`${absolute ? 'absolute' : 'relative'} ${
									styles.listboxOption
								} scroll_`}
							>
								{options.map((option) => (
									<Listbox.Option
										key={option.option}
										className={({ active, disabled }) =>
											classNames(
												`${styles.activeGroup} ${
													active && !disabled ? styles.active : styles.inactive
												}`
											)
										}
										value={option}
										disabled={option.disabled}
									>
										{({ selected: selectedItem, active, disabled }) => (
											<>
												<span
													className={classNames(
														`${styles.selectedGroup} ${
															selectedItem && !disabled
																? styles.selected
																: styles.notSelected
														} ${disabled ? styles.disabled : ''}`
													)}
												>
													{option.option}
												</span>

												{selectedItem && !disabled ? (
													<span
														className={classNames(
															`${styles.activeSelectedGroup} ${
																active
																	? styles.activeSelected
																	: styles.inactiveNotSelected
															}`
														)}
													>
														<CheckIcon
															className={styles.checkIcon}
															aria-hidden='true'
														/>
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}
