import React, { LegacyRef } from 'react'
import DatePicker, {
	ReactDatePicker,
	ReactDatePickerProps,
} from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

type InputDateProps = {
	name?: string
	isValid?: boolean
	selected?: Date
} & ReactDatePickerProps

const styles = {
	input:
		'shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]',
	valid: 'border-green',
	error: 'border-red',
	placeholder: 'text-gray',
}

const InputDate = React.forwardRef<HTMLInputElement, InputDateProps>(
	({ isValid, name, onChange, selected, ...props }, ref) => {
		return (
			<DatePicker
				ref={
					ref as
						| LegacyRef<
								ReactDatePicker<
									| 'offset'
									| 'applyStyles'
									| 'arrow'
									| 'hide'
									| 'computeStyles'
									| 'eventListeners'
									| 'flip'
									| 'preventOverflow'
									| 'popperOffsets',
									undefined
								>
						  >
						| undefined
				}
				selected={selected}
				onChange={onChange}
				name={name}
				className={`${styles.input} ${(() => {
					if (isValid && isValid !== undefined) {
						return styles.valid
					}
					if (!isValid && isValid !== undefined) {
						return styles.error
					}

					return ''
				})()}`}
				showYearPicker
				dateFormat='yyyy'
				{...props}
			/>
		)
	}
)

export default InputDate
