import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Pagination } from '@components'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { announcementClassApi } from '@services/AnnouncementClassApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddAnnouncementClass from './AddAnnouncementClass'
import EditAnnouncementClass from './EditAnnouncementClass'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] mt-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ListAnnouncementClass() {
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = announcementClassApi.useListQuery({
		page,
		search: searchQuery,
	})

	const [
		deleteAnnouncementClass,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = announcementClassApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/comunicados/turma?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteAnnouncement))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/comunicados/turma',
	})

	return (
		<>
			<Flex className={styles.searchContainer}>
				<SearchInput
					placeholder='Buscar Comunicado de Turma'
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
				/>

				<Button
					onClick={() => dispatch(openModal({ name: 'addAnnouncementClass' }))}
				>
					Adicionar Comunicado de Turma
				</Button>
			</Flex>

			{isLoading ? (
				<div className='flex justify-center'>
					<Spinner size='md' />
				</div>
			) : (
				<Table
					th={[
						{ label: 'id' },
						{
							label: 'nome',
						},
						{ label: 'turma' },
						{ label: 'tipo' },
						{ label: 'ações' },
					]}
				>
					{data?.data && data?.data?.length > 0 ? (
						data?.data.map(
							({ id: announcementId, name, type, class: classInfo }) => (
								<Tr key={announcementId}>
									<Td>{announcementId}</Td>
									<Td className='w-[50%] px-6'>{name}</Td>
									<Td>{classInfo.name}</Td>
									<Td>{type}</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editAnnouncementClass',
															modalInfo: announcementId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeAnnouncementClass = {
														title: 'Remover Comunicado de Turma',
														message:
															'Tem certeza que deseja remover o comunicado de turma?',
														buttonLabel: 'Remover Comunicado de Turma',
														onClick: () =>
															deleteAnnouncementClass({
																id: announcementId,
															}),
													}

													dispatch(openAlertModal(removeAnnouncementClass))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							)
						)
					) : (
						<EmptyRow tdAmount={4} message='Nenhum comunicado encontrado' />
					)}
				</Table>
			)}

			<Divider className={styles.divider} />
			{data?.data && data?.data?.length > 0 && (
				<Pagination
					pageCount={data?.meta.lastPage || 0}
					maxAmount={data?.meta.total || 0}
					perPage={data?.meta.perPage || 0}
				/>
			)}
			<AddAnnouncementClass />
			<EditAnnouncementClass />
		</>
	)
}

export default ListAnnouncementClass
