import { BASE_URL } from '@constants/api'
import {
	AddUserRequest,
	ListUserRequest,
	ListUserResponse,
	ShowUserResponse,
	UpdateUserRequest,
} from '@models/user.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const userApi = createApi({
	reducerPath: 'userApi',
	tagTypes: ['Usuarios', 'Usuario'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListUserResponse, ListUserRequest>({
			query: ({ page, search = '' }) => ({
				url: `/admins?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Usuarios'],
		}),
		showUser: builder.query<ShowUserResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/admins/${id}`,
				method: 'GET',
			}),
			providesTags: ['Usuarios'],
		}),
		show: builder.mutation<ShowUserResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/admins/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddUserRequest>({
			query: ({ roles, name, email, password, image }) => {
				const formData = new FormData()

				formData.append('name', name)
				formData.append('email', email)
				formData.append('image', image)
				formData.append('password', password)

				roles.forEach((role, i) => {
					formData.append(`roles[${i}]`, role)
				})

				return {
					url: `/admins`,
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Usuarios'],
		}),
		edit: builder.mutation<void, UpdateUserRequest>({
			query: ({ id, roles, name, email, password, image }) => {
				const formData = new FormData()

				formData.append('name', name)
				formData.append('email', email)
				formData.append('image', image)
				formData.append('password', password)

				roles.forEach((role, i) => {
					formData.append(`roles[${i}]`, role)
				})

				return {
					url: `/admins/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Usuarios'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/admins/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Usuarios'],
		}),
	}),
})
