export default class LinkManager {
	constructor(private baseUrl: string) {}

	create(to: string) {
		return `${this.baseUrl}${to}`
	}

	static getBaseUrl(href: string) {
		const qs = href.split('?').filter(Boolean)

		const newHref = `${window.location.pathname}${
			qs[1] ? `?${qs[1]}` : ''
		}`.replace('/', '')

		return newHref
	}

	static replaceUrlParam(paramName: string, paramValue: string) {
		const href = new URL(window.location.href)

		href.searchParams.delete(paramName)
		href.searchParams.append(paramName, paramValue)

		return LinkManager.getBaseUrl(href.href)
	}

	static deleteUrlParam(paramName: string) {
		const href = new URL(window.location.href)

		href.searchParams.delete(paramName)

		return LinkManager.getBaseUrl(href.href)
	}
}
