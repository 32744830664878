import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Divider } from '@components'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import {
	PencilAltIcon,
	TrashIcon,
	MenuAlt2Icon,
} from '@heroicons/react/outline'
import { moduleApi } from '@services/ModuleApi'
import { loadingModal, openAlertModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	table: 'mt-4',
	link: 'font-medium text-primary',
	divider: 'mt-[17px] mb-[12px]',
}

export default function ContentTable() {
	const { id } = useParams<{ id: string }>()
	const dispatch = useAppDispatch()
	const query = useQuery()

	const baseUrl = `/modulos/${id}`

	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading: isLoadingSubjects } = moduleApi.useListContentQuery({
		id: id as string,
		search: searchQuery,
		page,
	})

	const [
		deleteContent,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = moduleApi.useDeleteContentMutation()

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteContent))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	const searchCallback = useCallback(
		(value: string) => {
			navigate(`/modulos/${id}?page=1`)
			setSearchQuery(value)
		},
		[navigate, id]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	const [questions, setQuestions] = useState(data?.data)
	const [orderContent] = moduleApi.useOrderContentMutation()

	useEffect(() => {
		setQuestions(data?.data)
	}, [data])

	function handleDragEnd(result: DropResult) {
		orderContent({
			newPosition: Number(result.destination?.index) + 1,
			contentId: Number(result.draggableId),
		})

		if (questions) {
			const list = [...questions]

			if (list.length && result.destination && result.source) {
				const [removed] = list.splice(result.source.index, 1)

				list.splice(result.destination.index, 0, removed)

				setQuestions(list)
			}
		}
	}

	// const baseUrl = `/modulos/${id}/`

	return (
		<>
			<div className='flex gap-6 items-end mt-6'>
				<SearchInput
					placeholder='Buscar Conteúdo'
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
				/>
			</div>

			{isLoadingSubjects ? (
				<div className='flex justify-center mt-6'>
					<Spinner size='md' />
				</div>
			) : (
				<DragDropContext onDragEnd={handleDragEnd}>
					<div className={styles.table}>
						<Table
							th={[
								{ label: 'ID' },
								{ label: 'Nome' },
								{ label: 'Dias para exposição' },
								{ label: 'Data de expiração' },
								{ label: 'ações' },
								{ label: 'ordem' },
							]}
							droppable
							variant='mixed'
						>
							{questions && questions?.length > 0 ? (
								questions.map(
									({ id: subjectId, name, expirationDate, day }, index) => {
										return (
											<Draggable
												key={subjectId}
												draggableId={String(subjectId)}
												index={index}
											>
												{(provided) => (
													<Tr
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<Td>{subjectId}</Td>
														<Td className='w-[70%] px-6'>{name}</Td>
														<Td className='w-[10%] px-6'>{day}</Td>
														<Td className='w-[75%] px-6'>
															{Formatters.createdAt(expirationDate)}
														</Td>
														<Td>
															<Flex className='items-center gap-4'>
																<Button
																	className='!px-2 !py-2'
																	leftIcon={<PencilAltIcon />}
																	disabled={false}
																	variant='outline'
																	onClick={() =>
																		navigate(
																			`${baseUrl}/conteudo/${subjectId}/editar-conteudo`
																		)
																	}
																>
																	<></>
																</Button>
																<Button
																	className='!px-2 !py-2'
																	leftIcon={<TrashIcon />}
																	disabled={false}
																	variant='outline'
																	onClick={() => {
																		const removeDiscipline = {
																			title: 'Remover Conteúdo',
																			message:
																				'Tem certeza que deseja remover o Conteúdo?',
																			buttonLabel: 'Remover Conteúdo',
																			onClick: () =>
																				deleteContent({
																					id: subjectId,
																				}),
																		}

																		dispatch(openAlertModal(removeDiscipline))
																	}}
																>
																	<></>
																</Button>
															</Flex>
														</Td>
														<Td>
															<MenuAlt2Icon className='w-6 h-6' />
														</Td>
													</Tr>
												)}
											</Draggable>
										)
									}
								)
							) : (
								<EmptyRow tdAmount={4} message='Nenhum Conteúdo encontrado' />
							)}
						</Table>
					</div>
				</DragDropContext>
			)}
			<Divider className={styles.divider} />
		</>
	)
}
