import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Breadcrumb from '@components/Breadcrumb'
import InfoBox from '@components/InfoBox'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { useAppSelector } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

export function Profile() {
	const navigate = useNavigate()

	const { user } = useAppSelector((state) => state.auth)

	const baseUrl = `/profile`

	const allPages = useMemo(() => {
		return [createBreadcrumbLink(`Profile`, baseUrl, 1)]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const infos = [
		{
			label: 'Nome',
			item: <p>{user?.name}</p>,
		},
		{
			label: 'Email',
			item: <p>{user?.email}</p>,
		},
		{
			label: 'Permissões',
			item: <p>{user?.roles?.map((role) => role?.name).join(', ')}</p>,
		},
		{
			label: 'Criado em',
			item: <p>{Formatters.createdAt(user?.createdAt as string)}</p>,
		},
		{
			label: 'Atualizado em',
			item: <p>{Formatters.createdAt(user?.updatedAt as string)}</p>,
		},
	]

	const options = [
		{
			label: 'Sair da conta',
			onClick: () => navigate('/logout'),
		},
	]

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />

				<UserBanner
					name={`${user?.name}`}
					info={`${user?.email}`}
					avatar={user?.imageUrl ? user?.imageUrl : undefined}
					button={options}
				/>
				<InfoBox
					title='Informações'
					subtitle=''
					itens={infos}
					width={500}
					myHeader='py-5'
					mtBox='mt-4'
					mbBox='mb-10'
				/>
			</Template>
		</>
	)
}
