import { BASE_URL } from '@constants/api'
import {
	ListClassRequest,
	ListClassResponse,
	ListFiltersRequest,
	ListFiltersResponse,
	ListMatterRequest,
	ListMatterResponse,
	ListQuestionRequest,
	ListQuestionResponse,
	ListSubMatterRequest,
	ListSubMatterResponse,
} from '@models/filters.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const filtersApi = createApi({
	reducerPath: 'filtersApi',
	tagTypes: ['Filtros'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListFiltersResponse, ListFiltersRequest>({
			query: () => ({
				url: `/questionFilters`,
				method: 'GET',
			}),
			providesTags: ['Filtros'],
		}),
		listMatter: builder.query<ListMatterResponse, ListMatterRequest>({
			query: ({ disciplineId }) => ({
				url: `/questionFilters?filter=matter&dependencies=${disciplineId}`,
				method: 'GET',
			}),
			providesTags: ['Filtros'],
		}),
		listSubMatter: builder.query<ListSubMatterResponse, ListSubMatterRequest>({
			query: ({ matterId }) => ({
				url: `/questionFilters?filter=submatter&dependencies=${matterId}`,
				method: 'GET',
			}),
			providesTags: ['Filtros'],
		}),
		listQuestions: builder.query<ListQuestionResponse, ListQuestionRequest>({
			query: ({ subMatterId, mockId }) => ({
				url: `/question?submatterId=${subMatterId}&mockId=${mockId}`,
				method: 'GET',
			}),
			providesTags: ['Filtros'],
		}),
		listClass: builder.query<ListClassResponse, ListClassRequest>({
			query: () => ({
				url: `/questionFilters?filter=class`,
				method: 'GET',
			}),
			providesTags: ['Filtros'],
		}),
	}),
})
