/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { Icon } from '@components'
import { CheckCircleIcon } from '@heroicons/react/outline'

interface FileInputProps {
	mt?: string
	className?: string
	children: React.ReactNode
	multiple?: boolean
	accept?: string
	onChange: (...event: any[]) => void
	isValid?: boolean
}

const styles = {
	label: 'block cursor-pointer',
	container:
		'bg-white px-4 py-4 items-center flex justify-between border-x border-gray-200',
	fileGroup: 'flex items-center',
	fileGroupIcon: 'mr-3',
	fileGroupText: 'flex gap-2 text-gray-900',
	input: 'w-full text-sm text-slate-500 hidden',
	action: 'font-medium text-primary',
	error: 'border border-red',
}

export default function FileInput({
	mt = 'mt-0',
	className = 'rounded-md border-y',
	children,
	multiple,
	onChange,
	accept,
	isValid,
}: FileInputProps) {
	const [uploaded, setUploaded] = useState(false)

	const onDrop = useCallback((acceptedFiles: any) => {
		onChange(acceptedFiles)

		setUploaded(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<label
			htmlFor='file'
			className={`${styles.label} ${mt}`}
			{...getRootProps()}
		>
			<div
				className={`${styles.container} ${className} ${
					!isValid && isValid !== undefined && styles.error
				}`}
			>
				<div className={styles.fileGroup}>
					<Icon
						name='file-icon'
						className={styles.fileGroupIcon}
						width={20}
						height={20}
					/>
					<p className={styles.fileGroupText}>
						{children}{' '}
						{uploaded && <CheckCircleIcon className='w-5 stroke-green' />}
					</p>
					<input
						type='file'
						{...getInputProps()}
						className={styles.input}
						multiple={multiple}
						accept={accept}
					/>
				</div>
				{isDragActive && (
					<p className='text-gray font-medium'>Solte o arquivo aqui</p>
				)}
				<p className={styles.action}>Upload</p>
			</div>
		</label>
	)
}
