import { useCallback } from 'react'

import { BASE_URL } from '@constants/api'
import { openErrorModal } from '@store/ducks/modal'
import { useAppDispatch } from '@store/hooks'
import { api } from '@utils/api'

export function useDownloadPDF() {
	const dispatch = useAppDispatch()

	const downloadPDF = useCallback(
		({ name, file }: { name: string; file: string }) => {
			api({
				url: `${BASE_URL}/downloadPdf?pdf=${file}`, // your url
				method: 'GET',
				responseType: 'blob', // important
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')

					link.href = url
					link.setAttribute('download', `${name}.pdf`)
					document.body.appendChild(link)
					link.click()
					link.remove()
				})
				.catch(() => {
					dispatch(
						openErrorModal({
							title: 'Ocorreu um erro',
							message: 'Ocorreu um erro ao baixar o pdf',
						})
					)
				})
		},
		[dispatch]
	)

	return { downloadPDF }
}
