import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MarkInput, Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import ButtonFloating from '@components/ButtonFloating'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon } from '@heroicons/react/outline'
import { questionGeneratorApi } from '@services/GenerateQuestions'
import { openInputModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ReviewQuestions() {
	const pages = [{ name: 'Revisar Questões', href: '#', current: true }]
	const dispatch = useAppDispatch()
	const { inputValue } = useAppSelector((state) => state.modal)
	const inputValueRef = useRef(inputValue)

	useEffect(() => {
		inputValueRef.current = inputValue
	}, [inputValue])

	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)
	const [lastAction, setLastAction] = useState<'approve' | 'reject' | null>(
		null
	)
	const modalOptions = {
		title: 'Motivo',
		inputPlaceholder: 'Digite aqui o motivo',
		buttonLabel: 'Confirmar',
	}

	const [groupQuestions, setGroupQuestions] = useState<
		| {
				id: number
		  }[]
	>()

	const { data, isLoading } = questionGeneratorApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [approveRejectQuestion, { isSuccess }] =
		questionGeneratorApi.useApproveRejectMutation()

	useEffect(() => {
		if (isSuccess) {
			if (lastAction === 'approve') {
				dispatch(
					notificationCreators.show(
						successMessages.storedGenerateQuestionApproved
					)
				)
			} else if (lastAction === 'reject') {
				dispatch(
					notificationCreators.show(
						successMessages.storedGenerateQuestionReject
					)
				)
			}
			setLastAction(null)
		}
	}, [isSuccess, lastAction, dispatch])

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/revisar-questoes?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/revisar-questoes',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Questões geradas por IA' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Questão'
					/>

					<Button onClick={() => navigate(`/gerador-questoes`)}>
						Adicionar Questão
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{ label: 'id' },
							{
								label: 'titulo',
							},
							{ label: 'ações' },
						]}
					>
						{data?.data && data?.data.length > 0 ? (
							data.data?.map(({ id, title }) => (
								<Tr key={id}>
									<Td>{id}</Td>

									<Td className='w-[75%] px-6'>
										<Flex className='items-center gap-4'>{title}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												disabled={false}
												variant='default'
												onClick={() => {
													setLastAction('approve')
													approveRejectQuestion({
														isApproved: true,
														questionIds: [id],
													})
												}}
											>
												<>Aprovar</>
											</Button>
											<Button
												className='!px-2 !py-2'
												disabled={false}
												variant='danger'
												onClick={() => {
													dispatch(
														openInputModal({
															...modalOptions,
															onClick: () => {
																setLastAction('reject')
																approveRejectQuestion({
																	isApproved: false,
																	questionIds: [id],
																	refuseReason: inputValueRef.current,
																})
															},
														})
													)
												}}
											>
												<>Reprovar</>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() => navigate(`/revisar-questoes/${id}`)}
											>
												<></>
											</Button>
											<MarkInput
												type='checkbox'
												multiCheckbox
												onChange={() => {
													const newItem = { id }

													setGroupQuestions((prev) => {
														const currentItems = prev || []
														const exists = currentItems.some(
															(item) => item.id === newItem.id
														)

														if (exists) {
															return currentItems.filter(
																(item) => item.id !== newItem.id
															)
														}

														return [...currentItems, newItem]
													})
												}}
												selectedId={groupQuestions?.map((item) =>
													item.id.toString()
												)}
												itens={[
													{
														id: id.toString(),
														title: '',
													},
												]}
											/>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhum turma encontrada' />
						)}
					</Table>
				)}

				{groupQuestions && groupQuestions?.length > 0 && (
					<ButtonFloating
						onCancel={() =>
							dispatch(
								openInputModal({
									...modalOptions,
									onClick: () => {
										setLastAction('reject')
										approveRejectQuestion({
											isApproved: false,
											questionIds: groupQuestions?.map((item) => item.id),
											refuseReason: inputValueRef.current,
										})
									},
								})
							)
						}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onComplete={() => {
							setLastAction('approve')
							approveRejectQuestion({
								isApproved: true,
								questionIds: groupQuestions?.map((item) => item.id),
								refuseReason: inputValueRef.current,
							})
						}}
					/>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}

export default ReviewQuestions
