import * as yup from 'yup'

export const questionGeneratorSchema = yup.object({
	subSubjectSelect: yup.array().required(),
	yearSelect: yup.array(),
	occupationSelect: yup.array(),
	bancaSelect: yup.array(),
	institutionSelect: yup.array(),
	quantity: yup.number().required(),
	statementInformation: yup.string(),
	customPrompt: yup.string(),
	sourceFile: yup.mixed().required(),
})
