import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, NavTab } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import Loading from '@pages/Loading'
import { moduleApi } from '@services/ModuleApi'
import { closeModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import Formatters from '@utils/helpers/Formatters'
import LinkManager from '@utils/helpers/LinkManager'
import useCreateBreadcrumb, {
	createBreadcrumbLink,
} from '@utils/hooks/useBreadcrumb'

import ContentTable from '../contents'
import EditModule from './EditModule'

function IndividualModule() {
	const { id } = useParams<{ id: string }>()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { data: moduleInfo, isLoading } = moduleApi.useShowQueryQuery({
		id: Number(id) as number,
	})

	const baseUrl = `/modulos/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Modulos`, '/modulos', 1),
			createBreadcrumbLink(`${moduleInfo?.name}`, baseUrl, 2),
		]
	}, [baseUrl, moduleInfo])

	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [{ title: 'Conteúdos', to: createLink('') }]

	const components = {
		[createLink('')]: <ContentTable />,
	}

	const [deleteModule, setDeleteModule] = useState(false)

	useEffect(() => {
		if (deleteModule) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.deleteModule))
			setDeleteModule(false)
		}
	}, [deleteModule, dispatch])

	const options = [
		{
			label: 'Editar Modulo',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editModule',
						modalInfo: id,
					})
				),
		},
	]

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${moduleInfo?.name}`}
					info={`Alterado pela última vez em ${Formatters.date(
						moduleInfo?.updatedAt || '',
						2
					)}`}
					options={options}
				/>

				<div className='flex justify-between items-end'>
					<NavTab links={links} />
					<Button onClick={() => navigate(`${baseUrl}/criar-conteudo`)}>
						Adicionar Conteudo
					</Button>
				</div>
				{components[pathname]}
			</Template>
			<EditModule />
		</>
	)
}

export default IndividualModule
