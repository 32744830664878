import { BASE_URL } from '@constants/api'
import {
	AddContentsRequest,
	AddModuleRequest,
	ListContentsRequest,
	ListContentsResponse,
	ListModuleRequest,
	ListModuleResponse,
	ReorderContentsRequest,
	ShowContentsResponse,
	ShowModuleResponse,
	UpdateContentsRequest,
	UpdateModuleRequest,
} from '@models/module.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const moduleApi = createApi({
	reducerPath: 'moduleApi',
	tagTypes: ['Module'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListModuleResponse, ListModuleRequest>({
			query: ({ page = '1', search = '' }) => ({
				url: `/modules?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['Module'],
		}),
		listContent: builder.query<ListContentsResponse, ListContentsRequest>({
			query: ({ id, page = '1', search = '' }) => ({
				url: `/contents?page=${page}&search=${search}&limit=1000&moduleId=${id}`,
				method: 'GET',
			}),
			providesTags: ['Module'],
		}),
		show: builder.mutation<ShowModuleResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/modules/${id}`,
				method: 'GET',
			}),
		}),
		showQuery: builder.query<ShowModuleResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/modules/${id}`,
				method: 'GET',
			}),
		}),
		showContent: builder.mutation<ShowContentsResponse, { idContent: string }>({
			query: ({ idContent }) => ({
				url: `/contents/${idContent}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddModuleRequest>({
			query: (dataModule) => {
				const formData = new FormData()

				formData.append('name', dataModule.name as string)
				formData.append('url', dataModule.url)
				formData.append('isFree', dataModule.isFree)
				formData.append('showPercentage', dataModule.showPercentage)
				formData.append(
					'description',
					dataModule.description ? dataModule.description : ''
				)
				if (dataModule.image) {
					formData.append('image', dataModule.image[0])
				}
				dataModule.classIds.forEach((classe, i) => {
					formData.append(`classIds[${i}]`, String(classe))
				})

				return {
					url: `/modules`,
					enctype: 'multipart/form-data',
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Module'],
		}),
		addContent: builder.mutation<void, AddContentsRequest>({
			query: (dataModule) => ({
				url: `/contents`,
				body: dataModule,
				method: 'POST',
			}),
			invalidatesTags: ['Module'],
		}),
		orderContent: builder.mutation<void, ReorderContentsRequest>({
			query: (dataModule) => ({
				url: `/contents/reorder`,
				body: dataModule,
				method: 'POST',
			}),
			invalidatesTags: ['Module'],
		}),
		duplicateModule: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/modules/duplicate`,
				body: { moduleId: id },
				method: 'POST',
			}),
			invalidatesTags: ['Module'],
		}),
		edit: builder.mutation<void, UpdateModuleRequest>({
			query: ({ id, ...data }) => {
				const formData = new FormData()

				formData.append('name', data.name as string)
				formData.append('url', data.url)
				formData.append('isFree', data.isFree)
				formData.append('showPercentage', data.showPercentage)
				formData.append('description', data.description ? data.description : '')
				if (data.image) {
					formData.append('image', data.image[0])
				}
				data.classIds.forEach((classe, i) => {
					formData.append(`classIds[${i}]`, String(classe))
				})

				return {
					url: `/modules/${id}`,
					enctype: 'multipart/form-data',
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Module'],
		}),
		editContent: builder.mutation<void, UpdateContentsRequest>({
			query: ({ idContent, ...data }) => ({
				url: `/contents/${idContent}`,
				body: data,
				method: 'PUT',
			}),
			invalidatesTags: ['Module'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/modules/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Module'],
		}),
		deleteContent: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/contents/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Module'],
		}),
	}),
})
