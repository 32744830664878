/* eslint-disable react/button-has-type */

import React from 'react'

import Spinner from '@components/Spinner'
import classNames from 'classnames'

const styles = {
	icon: 'w-[20px] h-[20px]',
	button:
		'flex gap-[8px] justify-center align-center font-medium rounded-md shadow-3 text-[14px] py-[8px] px-[18px]',
	outline: 'bg-white text-gray border-[1px] border-gray-300 hover:bg-gray-100',
	default: 'bg-primary text-white hover:bg-blue-700',
	danger: 'bg-red text-white hover:bg-red-700',
	outlineActived: 'bg-white text-primary border-[1px] border-primary',
	outlineHover: 'hover:text-primary hover:border-primary',
}

interface ButtonProps {
	children: React.ReactNode
	type?: 'button' | 'submit' | 'reset'
	variant?: 'default' | 'outline' | 'danger' | 'outlineActive'
	className?: string
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
	leftIcon?: JSX.Element
	rightIcon?: JSX.Element
	active?: boolean
	isLoading?: boolean
	disabled?: boolean
}

export default function Button({
	children,
	type = 'button',
	variant = 'default',
	className = '',
	onClick,
	leftIcon,
	rightIcon,
	active = false,
	isLoading = false,
	disabled,
}: ButtonProps) {
	const defaultStyles = `${styles.button} ${className}`

	function StyledIcon(icon: JSX.Element) {
		return React.cloneElement(icon, {
			className: styles.icon,
		})
	}

	const outline = classNames(`${styles.outline} ${defaultStyles}`)
	const _default = classNames(`${styles.default} ${defaultStyles}`)
	const danger = classNames(`${styles.danger} ${defaultStyles}`)
	const outlineActive = classNames(`${outline} ${styles.outlineHover}`)

	const buttonVariant = () => {
		if (variant === 'outlineActive') {
			if (active) {
				return `${defaultStyles} ${styles.outlineActived}`
			}

			return outlineActive
		}

		if (variant === 'outline') {
			return outline
		}

		if (variant === 'danger') return danger

		return _default
	}

	return (
		<button
			disabled={isLoading || disabled}
			onClick={onClick}
			type={type}
			className={`${buttonVariant()}`}
		>
			{leftIcon && StyledIcon(leftIcon)}
			{children}
			{isLoading && <Spinner size='ssm' />}
			{rightIcon && StyledIcon(rightIcon)}
		</button>
	)
}
