import { BASE_URL } from '@constants/api'
import {
	AddBancaRequest,
	ListBancaRequest,
	ListBancaResponse,
	ShowBancaResponse,
	UpdateBancaRequest,
} from '@models/banca.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const bancaApi = createApi({
	reducerPath: 'bancaApi',
	tagTypes: ['Bancas', 'Banca'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListBancaResponse, ListBancaRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/filters?page=${page}&type=jury&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Bancas'],
		}),
		show: builder.mutation<ShowBancaResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddBancaRequest>({
			query: (data) => ({
				url: `/filters`,
				body: {
					...data,
					typeId: 3,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Bancas'],
		}),
		edit: builder.mutation<void, UpdateBancaRequest>({
			query: ({ id, ...data }) => ({
				url: `/filters/${id}`,
				body: {
					...data,
					typeId: 3,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Bancas'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/filters/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Bancas'],
		}),
	}),
})
