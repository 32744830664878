import { BASE_URL } from '@constants/api'
import {
	ListDashboardRequest,
	ListDashboardResponse,
} from '@models/dashboard.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const dashboardApi = createApi({
	reducerPath: 'dashboard',
	tagTypes: ['Dashboard'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListDashboardResponse, ListDashboardRequest>({
			query: ({ month = '', year = 2023 }) => ({
				url: `/dashboard?month=${month}&year=${year}`,
				method: 'GET',
			}),
			providesTags: ['Dashboard'],
		}),
	}),
})
