import { DEBUG_MODE } from '@constants/api'
import { ModalProps } from '@models/modal.model'
import {
	MiddlewareAPI,
	isRejectedWithValue,
	Middleware,
} from '@reduxjs/toolkit'
import { logout } from '@store/ducks/auth'
import { openErrorModal } from '@store/ducks/modal'

/**
 * Log a warning and show a toast!
 */
export const errorMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (isRejectedWithValue(action)) {
			if (DEBUG_MODE) {
				// eslint-disable-next-line no-console
				console.error(action.type, action)
			}

			const error: ModalProps = {
				title: 'Ocorreu um erro',
				buttonLabel: 'Fechar',
				message: (() => {
					if (action.payload.data?.errors) {
						return action.payload.data.errors[0].message
					}

					if (action.payload.data?.error) {
						return action.payload.data.error
					}

					if (action.payload.data?.message) {
						return action.payload.data?.message
					}

					if (action.payload.data?.length) {
						return action.payload.data[0].message
					}

					if (action.payload.data?.err) {
						return 'Problema na validação (dados inconsistentes)'
					}

					if (action.payload.status === 404) {
						return 'Ocorreu um erro desconhecido: Não encontrado'
					}

					return 'Ocorreu um erro desconhecido'
				})(),
			}

			if (action.payload.data?.message === 'Invalid JWT') {
				api.dispatch(logout())

				return next(action)
			}

			api.dispatch(openErrorModal(error))
		}

		return next(action)
	}
