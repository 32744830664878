import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import {
	MenuAlt2Icon,
	PencilAltIcon,
	TrashIcon,
} from '@heroicons/react/outline'
import { disciplineApi } from '@services/DisciplineApi'
import { openAlertModal } from '@store/ducks/modal'
import { useAppDispatch } from '@store/hooks'
import { useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	table: 'mt-4',
	link: 'font-medium text-primary',
	divider: 'mt-[17px] mb-[12px]',
}

export default function QuizTable() {
	const { id, subjectId, subSubjectId } = useParams<{
		id: string
		subjectId: string
		subSubjectId: string
	}>()
	const dispatch = useAppDispatch()
	const query = useQuery()

	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)

	const { data: questionsData, isLoading: isLoadingQuiz } =
		disciplineApi.useListQuizQuery({
			submatterId: subSubjectId as string,
			search: searchQuery,
			page,
		})

	const [deleteQuiz] = disciplineApi.useDeleteQuizMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate(
				`/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}`
			)
			setSearchQuery(value)
		},
		[id, navigate, subSubjectId, subjectId]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	const baseUrl = `/disciplina/${id}/assunto/${subjectId}/sub-assunto/${subSubjectId}`

	const [orderQuestion] = disciplineApi.useOrderQuestionMutation()
	const [questions, setQuestions] = useState(questionsData?.data)

	useEffect(() => {
		setQuestions(questionsData?.data)
	}, [questionsData])

	function handleDragEnd(result: DropResult) {
		if (questions) {
			orderQuestion({
				questionId: Number(result.draggableId),
				submatterId: Number(subSubjectId),
				newPosition:
					Number(result.destination?.index) +
					(questions[0].position > 0 ? 1 : 0),
			})

			const list = [...questions]

			if (list.length && result.destination && result.source) {
				const [removed] = list.splice(result.source.index, 1)

				list.splice(result.destination.index, 0, removed)

				setQuestions(list)
			}
		}
	}

	return (
		<>
			<div className='flex gap-6 items-end mt-6'>
				<SearchInput
					placeholder='Buscar Questão'
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
				/>
			</div>

			{isLoadingQuiz ? (
				<div className='flex justify-center my-10'>
					<Spinner size='md' />
				</div>
			) : (
				<DragDropContext onDragEnd={handleDragEnd}>
					<div className={styles.table}>
						<Table
							css='overflow-x-visible'
							th={[
								{ label: 'ID' },
								{ label: 'Nome' },
								{ label: 'Ações' },
								{
									label: 'ordem',
								},
							]}
							droppable
							variant='mixed'
						>
							{questions && questions?.length > 0 ? (
								questions.map(
									({ id: idQuestion, title: name, description }, index) => {
										return (
											<Draggable
												key={idQuestion}
												draggableId={String(idQuestion)}
												index={index}
											>
												{(provided) => (
													<Tr
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<Td>{idQuestion}</Td>
														<Td className='w-[75%] px-6'>
															<span className='text-primary items-center font-medium'>
																{name}
															</span>
															<br />
															<span>{description?.formated}</span>
														</Td>
														<Td>
															<Flex className='items-center gap-4'>
																<Button
																	className='!px-2 !py-2'
																	leftIcon={<PencilAltIcon />}
																	disabled={false}
																	variant='outline'
																	onClick={() =>
																		navigate(
																			`${baseUrl}/editar-questao/${idQuestion}`
																		)
																	}
																>
																	<></>
																</Button>
																<Button
																	className='!px-2 !py-2'
																	leftIcon={<TrashIcon />}
																	disabled={false}
																	variant='outline'
																	onClick={() => {
																		const removeQuiz = {
																			title: 'Remover Questão',
																			message:
																				'Tem certeza que deseja remover a questão?',
																			buttonLabel: 'Remover Assunto',
																			onClick: () =>
																				deleteQuiz({
																					id: idQuestion,
																				}),
																		}

																		dispatch(openAlertModal(removeQuiz))
																	}}
																>
																	<></>
																</Button>
															</Flex>
														</Td>
														<Td>
															<MenuAlt2Icon className='w-6 h-6' />
														</Td>
													</Tr>
												)}
											</Draggable>
										)
									}
								)
							) : (
								<EmptyRow tdAmount={4} message='Nenhuma questão encontrada' />
							)}
						</Table>
					</div>
				</DragDropContext>
			)}

			<Divider className={styles.divider} />
		</>
	)
}
