/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Select, { MultiValue } from 'react-select'

import { Input, InputGroup } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addExamsSchema } from '@schemas/addexams.schema'
import { classesApi } from '@services/ClassesApi'
import { examsApi } from '@services/ExamsApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	categoryId: string
	classes: string[]
	classesSelect?: MultiValue<{ value: string | number; label: string }>
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function AddExams() {
	const dispatch = useAppDispatch()

	const { id } = useParams<{
		id: string
	}>()

	const {
		modal: { modalName },
	} = useAppSelector((state) => state)

	const { data: dataClasses } = classesApi.useListQuery({ limit: 10000 })

	const [addExams, { isLoading, isSuccess }] = examsApi.useAddMutation()

	const {
		register,
		control,
		setValue,
		reset,
		handleSubmit,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addExamsSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const classExamss = data?.classesSelect?.map((r) => {
			return r.value as number
		})

		const dataExams = {
			name: data.name ? data.name : '',
			categoryId: Number(id),
			classIds: classExamss ? (classExamss as number[]) : [],
		}

		addExams(dataExams)
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedExams))
		}
	}, [isSuccess, dispatch])

	return (
		<Modal name='addExams'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Adicionar Simulado</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para adicionar um novo simulado
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup className='mt-4' label='Nome do Simulado' id='name'>
						<Input
							isValid={inputIsValid(errors.name, touchedFields.name)}
							{...register('name')}
							type='text'
						/>
					</InputGroup>

					<InputGroup label='Turmas' id='classesSelect' className='mt-4'>
						<Controller
							control={control}
							name='classesSelect'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('classesSelect', v)}
										options={
											dataClasses?.data
												? dataClasses.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										isMulti
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>

						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Adicionar Simulado
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
