import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Breadcrumb, NavTab, Template } from '@components'
import { LinkManager } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

import ListClients from './ListClients'
import ListClientsActive from './ListClientsActive'

function Clients() {
	const { pathname } = useLocation()

	const baseUrl = `/clientes`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Clientes`, baseUrl, 1),
			createBreadcrumbLink(`Clientes Ativos`, `${baseUrl}/ativos`, 2),
		]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const linkManager = new LinkManager(baseUrl)

	function createLink(to: string) {
		return linkManager.create(to)
	}

	const links = [
		{ title: 'Clientes', to: createLink('') },
		{ title: 'Clientes Ativos', to: createLink('/ativos') },
	]

	const components = {
		[createLink('')]: <ListClients />,
		[createLink('/ativos')]: <ListClientsActive />,
	}

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />

				<div className='flex justify-between items-end '>
					<NavTab links={links} />
				</div>
				{components[pathname]}
			</Template>
		</>
	)
}

export default Clients
