import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import { FileInput, InputGroup, Spinner } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addLibrarieSchema } from '@schemas/addLibrarie.schema'
import { pdfApi } from '@services/PdfApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	name: string
	pdfFiles: Array<string | Blob>
	pdfName: string
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function EditPDF() {
	const dispatch = useAppDispatch()
	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [
		getPdf,
		{
			data: dataPdf,
			isLoading: loadingGetLibrarie,
			isSuccess: successGetLibrarie,
		},
	] = pdfApi.useShowMutation()

	useEffect(() => {
		if (id && modalName === 'editPDF') {
			getPdf({
				id: Number(id),
			})
		}
	}, [id, modalName, getPdf])

	const [editPdf, { isSuccess: isSuccessPDF, isLoading }] =
		pdfApi.useEditMutation()

	const { reset, control, getValues, handleSubmit, setValue } = useForm<Inputs>(
		{
			mode: 'onBlur',
			resolver: yupResolver(addLibrarieSchema),
		}
	)

	const onSubmit: SubmitHandler<Inputs> = () => {
		editPdf({ pdf: getValues('pdfFiles')[0], id })

		reset()
	}

	useEffect(() => {
		if (isSuccessPDF) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedPDF))
		}
	}, [dispatch, isSuccessPDF])

	useEffect(() => {
		if (dataPdf && successGetLibrarie) {
			setValue('name', dataPdf?.name as string)
		}
	}, [dataPdf, setValue, successGetLibrarie])

	if (loadingGetLibrarie) {
		return (
			<Modal name='editPDF'>
				<Dialog.Panel
					style={getCssStyle(
						{ name: 'overflowY', value: 'auto' },
						{ name: 'maxWidth', value: pixelsToRem(630) },
						{ name: 'maxHeight', value: pixelsToRem(600) },
						{ name: 'width', value: '100%' }
					)}
					className={`${styles.container} modal-scroll`}
				>
					<div className='w-full h-[90%] flex items-center justify-center mt-10'>
						<Spinner size='xl' />
					</div>
				</Dialog.Panel>
			</Modal>
		)
	}

	return (
		<Modal name='editPDF'>
			<Dialog.Panel
				style={getCssStyle(
					{ name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Editar PDF</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para editar o PDF
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name='pdfFiles'
						render={() => {
							return (
								<InputGroup className='mt-4' label='PDF' id='pdfFiles'>
									<FileInput
										mt='mt-4'
										onChange={(v) => {
											setValue('pdfFiles', v)
											setValue('pdfName', v[0].name)
										}}
										accept='application/pdf'
									>
										PDF
									</FileInput>
								</InputGroup>
							)
						}}
					/>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>
						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Editar PDF
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
