import { BASE_URL } from '@constants/api'
import {
	AddCategoryRequest,
	ListCategoryRequest,
	ListCategoryResponse,
	ShowCategoryResponse,
	UpdateCategoryRequest,
} from '@models/category.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const categoryApi = createApi({
	reducerPath: 'categoryApi',
	tagTypes: ['Category'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListCategoryResponse, ListCategoryRequest>({
			query: ({ page = '1', search = '' }) => ({
				url: `/mockCategories?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['Category'],
		}),
		showQuery: builder.query<ShowCategoryResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/mockCategories/${id}`,
				method: 'GET',
			}),
		}),
		show: builder.mutation<ShowCategoryResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/mockCategories/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddCategoryRequest>({
			query: ({ name, isFree }) => ({
				url: `/mockCategories`,
				body: {
					name,
					isFree,
				},
				method: 'POST',
			}),
			invalidatesTags: ['Category'],
		}),
		edit: builder.mutation<void, UpdateCategoryRequest>({
			query: ({ id, ...data }) => ({
				url: `/mockCategories/${id}`,
				body: {
					...data,
					typeId: 1,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Category'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/mockCategories/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Category'],
		}),
	}),
})
