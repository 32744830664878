import React from 'react'
import { Link } from 'react-router-dom'

import useIsCurrentLink from '@utils/helpers/useIsCurrentLink'

interface NavTabProps {
	links: Array<{ title: string; to: string }>
}

interface LinksProps {
	to: string
	title: string
}

const styles = {
	container: 'flex gap-2 mt-6 flex-wrap',
	link: 'font-medium px-3 py-2',
	defaultLink:
		'bg-transparent text-gray hover:bg-primary-transparent hover:rounded-md hover:text-primary',
	currentLink: 'bg-primary-transparent rounded-md text-primary',
}

function Links({ to, title }: LinksProps) {
	const match = useIsCurrentLink(to)

	return (
		<Link
			key={title}
			to={to}
			className={`${styles.link} ${
				match ? styles.currentLink : styles.defaultLink
			}`}
		>
			{title}
		</Link>
	)
}

export default function NavTab({ links }: NavTabProps) {
	return (
		<div className={`${styles.container}`}>
			{links.map(({ ...props }) => {
				return <Links key={props.title} {...props} />
			})}
		</div>
	)
}
