import { BASE_URL } from '@constants/api'
import {
	CheckQuestionsRequest,
	ListCommentsQuestionsRequest,
	ListCommentsQuestionsResponse,
	ShowCommentsQuestionsResponse,
	UpdateCommentsQuestionsRequest,
} from '@models/commentsQuestions.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const commentsQuestionsApi = createApi({
	reducerPath: 'commentsQuestionsApi',
	tagTypes: ['ErrorQuestion'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<
			ListCommentsQuestionsResponse,
			ListCommentsQuestionsRequest
		>({
			query: ({ page = '1', search = '' }) => ({
				url: `/questionComments?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['ErrorQuestion'],
		}),
		show: builder.mutation<ShowCommentsQuestionsResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/questionComments/${id}`,
				method: 'GET',
			}),
		}),
		edit: builder.mutation<void, UpdateCommentsQuestionsRequest>({
			query: ({ id, ...data }) => ({
				url: `/questionComments/${id}`,
				body: {
					...data,
					typeId: 4,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
		checkQuestion: builder.mutation<void, CheckQuestionsRequest>({
			query: ({ id, isAccepted }) => ({
				url: `/questionComments/${id}`,
				body: { isAccepted },
				method: 'PUT',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/questionComments/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ErrorQuestion'],
		}),
	}),
})
