import { BASE_URL } from '@constants/api'
import {
	IAQuestionsResponse,
	IAQuestionsListRequest,
	IAGenerateQuestionResponse,
	AproveRejectResponse,
	IAShowQuestionResponse,
} from '@models/generateQuestion.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const questionGeneratorApi = createApi({
	reducerPath: 'questionGeneratorApi',
	tagTypes: ['QuestionsGenerator', 'Question'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<IAQuestionsResponse, IAQuestionsListRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/ia/questions?page=${page}&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['QuestionsGenerator'],
		}),
		show: builder.query<IAShowQuestionResponse, { id: number | string }>({
			query: ({ id }) => ({
				url: `question/${id}`,
				method: 'GET',
			}),
			providesTags: ['Question'],
		}),
		generate: builder.mutation<void, IAGenerateQuestionResponse>({
			query: (data) => {
				const formData = new FormData()

				formData.append('title', data.title as string)
				formData.append('archive', data.archive[0])
				formData.append('quantity', String(data.quantity))
				formData.append('customPrompt', data.customPrompt as string)
				if (data.yearIds) {
					data.yearIds.forEach((id) => {
						formData.append('yearIds[]', id.toString())
					})
				}

				if (data.positionIds) {
					data.positionIds.forEach((id) => {
						formData.append('positionIds[]', id.toString())
					})
				}

				if (data.juryIds) {
					data.juryIds.forEach((id) => {
						formData.append('juryIds[]', id.toString())
					})
				}

				if (data.institutionIds) {
					data.institutionIds.forEach((id) => {
						formData.append('institutionIds[]', id.toString())
					})
				}

				if (data.submatterIds) {
					data.submatterIds.forEach((id) => {
						formData.append('submatterIds[]', id.toString())
					})
				}

				return {
					url: `/ia/generate/questions`,
					enctype: 'multipart/form-data',
					body: formData,
					method: 'POST',
				}
			},
		}),
		approveReject: builder.mutation<void, AproveRejectResponse>({
			query: (data) => {
				return {
					url: `/ia/questions`,
					body: data,
					method: 'POST',
				}
			},
		}),
	}),
})
