import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
	Button,
	Divider,
	EmptyRow,
	Flex,
	Spinner,
	Table,
	Td,
	Tr,
} from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Template from '@components/Template'
import UserBanner from '@components/UserBanner'
import { successMessages } from '@constants/feedbackMessages'
import {
	DownloadIcon,
	PencilAltIcon,
	TrashIcon,
} from '@heroicons/react/outline'
import { librariresApi } from '@services/LibrarieApi'
import { openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useDownloadPDF } from '@store/hooks/useDownloadPDF'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

import EditLibrarie from '../EditLibrarie'
import AddPDF from './AddPDF'
import EditPDF from './EditPDF'

const styles = {
	searchContainer: 'gap-[8px] my-[32px] items-end flex-wrap justify-end',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function IndividualLibrarie() {
	const dispatch = useAppDispatch()

	const { id } = useParams<{
		id: string
	}>()

	const { data: dataLibrarie, isLoading } = librariresApi.useShowQueryQuery({
		id,
	})

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Bibliotecas`, `/bibliotecas`, 1),
			createBreadcrumbLink(`${dataLibrarie?.name}`, `/bibliotecas/${id}`, 2),
		]
	}, [dataLibrarie?.name, id])

	const pages = useCreateBreadcrumb(allPages)

	const options = [
		{
			label: 'Editar Biblioteca',
			onClick: () =>
				dispatch(
					openModal({
						name: 'editLibrarie',
						modalInfo: id,
					})
				),
		},
	]

	const [editLibrarie, { isSuccess }] = librariresApi.useEditMutation()

	const { downloadPDF } = useDownloadPDF()

	const deletePDF = (data: { name: string; file: string }) => {
		editLibrarie({
			id: dataLibrarie?.id as number,
			name: dataLibrarie?.name,
			pdfFiles: [
				{
					name: data.name,
					file: data.file,
					deleted: true,
				},
			],
		})
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(notificationCreators.show(successMessages.deletePDF))
		}
	}, [dispatch, isSuccess])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<UserBanner
					dontShowAvatar
					name={`${dataLibrarie?.name}`}
					options={options}
				/>

				<Flex className={styles.searchContainer}>
					<Button
						onClick={() =>
							dispatch(
								openModal({
									name: 'addPdf',
									modalInfo: id,
								})
							)
						}
					>
						Adicionar PDF
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table th={[{ label: 'id' }, { label: 'nome' }, { label: 'ações' }]}>
						{dataLibrarie?.files && dataLibrarie?.files?.length > 0 ? (
							dataLibrarie?.files.map(({ id: pdfId, name, file }) => (
								<Tr key={pdfId}>
									<Td>{pdfId}</Td>
									<Td className='w-[75%] px-6'>
										<Flex className='items-center gap-4'>{name}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editPDF',
															modalInfo: pdfId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<DownloadIcon />}
												variant='outline'
												onClick={() => downloadPDF({ file, name })}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removePDF = {
														title: 'Remover PDF',
														message: 'Tem certeza que deseja remover o pdf?',
														buttonLabel: 'Remover PDF',
														onClick: () =>
															deletePDF({
																name,
																file,
															}),
													}

													dispatch(openAlertModal(removePDF))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhum pdf encontrado' />
						)}
					</Table>
				)}
				<Divider className={styles.divider} />
			</Template>
			<AddPDF />
			<EditPDF />
			<EditLibrarie />
		</>
	)
}

export default IndividualLibrarie
