import { APP_NAME } from '@constants/api'

export default class LocalStorage {
	public appName = APP_NAME

	private getKey(key: string) {
		return `${this.appName}/${key}`
	}

	// eslint-disable-next-line class-methods-use-this
	public stringify(item: unknown) {
		return JSON.stringify(item)
	}

	public get(key: string) {
		return localStorage.getItem(this.getKey(key))
	}

	public set(key: string, value: string) {
		localStorage.setItem(this.getKey(key), value)
	}

	public remove(key: string) {
		localStorage.removeItem(this.getKey(key))
	}
}
