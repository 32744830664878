import * as yup from 'yup'

export const addUserSchema = yup.object({
	name: yup.string().required(),
	email: yup.string().required(),
	password: yup.string().required(),
	rolesSelect: yup
		.array()
		.test((value) => {
			if (!value) {
				return false
			}

			return value.length > 0
		})
		.required(),
})
