import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SidebarState {
	showSubmenus: number[]
}

const initialState: SidebarState = {
	showSubmenus: [],
}

export const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		setSubmenuOpened: (
			state,
			{ payload: { type } }: PayloadAction<{ type: number }>
		) => {
			state.showSubmenus = state.showSubmenus.includes(type)
				? [...state.showSubmenus.splice(type)]
				: [...state.showSubmenus.splice(type), type]
		},
		resetSubmenu: (state) => {
			if (state.showSubmenus) {
				state.showSubmenus = []
			}
		},
	},
})

export const { setSubmenuOpened, resetSubmenu } = sidebarSlice.actions

export const sidebarReducer = sidebarSlice.reducer
