import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Divider, Pagination } from '@components'
import Button from '@components/Button'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { disciplineApi } from '@services/DisciplineApi'
import { openAlertModal, openModal } from '@store/ducks/modal'
import { useAppDispatch } from '@store/hooks'
import { useInputBounce, useQuery } from '@utils/hooks'

import EditSubject from '../EditSubject'

const styles = {
	table: 'mt-4',
	link: 'font-medium text-primary',
	divider: 'mt-[17px] mb-[12px]',
}

export default function SubjectTable() {
	const { id: disciplineId } = useParams<{ id: string }>()
	const dispatch = useAppDispatch()
	const query = useQuery()

	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(searchQs)
	const [searchQuery, setSearchQuery] = useState(searchQs)
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading: isLoadingSubjects } =
		disciplineApi.useListSubjectQuery({
			disciplineId: disciplineId as string,
			search: searchQuery,
			page,
		})

	const [deleteSubject] = disciplineApi.useDeleteSubjectMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate(`/disciplina/${disciplineId}`)
			setSearchQuery(value)
		},
		[navigate, disciplineId]
	)

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	const baseUrl = `/disciplina/${disciplineId}/assunto/`

	return (
		<>
			<div className='flex gap-6 items-end mt-6'>
				<SearchInput
					placeholder='Buscar Assunto'
					onChange={(e) => setSearch(e.target.value)}
					className='flex-1'
				/>
			</div>

			{isLoadingSubjects ? (
				<div className='flex justify-center mt-6'>
					<Spinner size='md' />
				</div>
			) : (
				<div className={styles.table}>
					<Table
						th={[
							{ label: 'ID' },
							{ label: 'Nome' },
							{ label: 'Nº questões' },
							{ label: 'ações' },
						]}
						variant='mixed'
					>
						{data && data?.data?.length > 0 ? (
							data?.data.map(({ id: subjectId, name, total }) => {
								return (
									<Tr key={subjectId}>
										<Td>{subjectId}</Td>
										<Td hasLink className='w-[75%]'>
											<Link to={`${baseUrl}${subjectId}`}>
												<span className='underline underline-offset-1'>
													{name}
												</span>
											</Link>
										</Td>
										<Td>{total}</Td>
										<Td>
											<Flex className='items-center gap-4'>
												<Button
													className='!px-2 !py-2'
													leftIcon={<PencilAltIcon />}
													disabled={false}
													variant='outline'
													onClick={() =>
														dispatch(
															openModal({
																name: 'editSubject',
																modalInfo: subjectId,
															})
														)
													}
												>
													<></>
												</Button>
												<Button
													className='!px-2 !py-2'
													leftIcon={<TrashIcon />}
													disabled={false}
													variant='outline'
													onClick={() => {
														const removeDiscipline = {
															title: 'Remover Assunto',
															message:
																'Tem certeza que deseja remover o assunto?',
															buttonLabel: 'Remover Assunto',
															onClick: () =>
																deleteSubject({
																	id: subjectId,
																}),
														}

														dispatch(openAlertModal(removeDiscipline))
													}}
												>
													<></>
												</Button>
											</Flex>
										</Td>
									</Tr>
								)
							})
						) : (
							<EmptyRow tdAmount={4} message='Nenhum assunto encontrado' />
						)}
					</Table>
				</div>
			)}
			<Divider className={styles.divider} />
			{data?.data && data?.data?.length > 0 && (
				<Pagination
					pageCount={data?.meta.lastPage || 0}
					maxAmount={data?.meta.total || 0}
					perPage={data?.meta.perPage || 0}
				/>
			)}

			<EditSubject />
		</>
	)
}
