import { LoginResponse, ProfileResponse } from '@models/auth.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '@utils/api'
import { LocalStorage } from '@utils/helpers'

interface AuthState extends LoginResponse {
	signed: boolean
	user: ProfileResponse | undefined
}

const initialState: AuthState = {
	signed: false,
	token: undefined,
	user: undefined,
}

const storage = new LocalStorage()

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signInSuccess: (state, action: PayloadAction<LoginResponse>) => {
			api.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`

			state.token = action.payload.token
			state.signed = true
			storage.set('token', action.payload.token || '')
		},
		logout: () => {
			api.defaults.headers.common.Authorization = ''

			storage.remove('token')

			return initialState
		},
		setUser: (state, action: PayloadAction<ProfileResponse>) => {
			state.user = action.payload
		},
	},
})

export const { signInSuccess, logout, setUser } = authSlice.actions

export const authReducer = authSlice.reducer
