import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { occupationApi } from '@services/OccupationApi'
import { loadingModal, openAlertModal, openModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

import AddInstitution from './AddOccupation'
import EditInstitution from './EditOccupation'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function Occupation() {
	const dispatch = useAppDispatch()
	const pages = [{ name: 'Cargo', href: '#', current: true }]
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = occupationApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteOccupation,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = occupationApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/cargo?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteOccupation))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/cargo',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Cargo' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Cargo'
					/>

					<Button
						onClick={() => dispatch(openModal({ name: 'addOccupation' }))}
					>
						Adicionar Cargo
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table th={[{ label: 'id' }, { label: 'cargo' }, { label: 'ações' }]}>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(({ id: occupationId, name }) => (
								<Tr key={occupationId}>
									<Td>{occupationId}</Td>
									<Td className='w-[75%] px-6'>
										<Flex className='items-center gap-4'>{name}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													dispatch(
														openModal({
															name: 'editOccupation',
															modalInfo: occupationId,
														})
													)
												}
											>
												<></>
											</Button>
											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeOccupation = {
														title: 'Remover Cargo',
														message: 'Tem certeza que deseja remover o cargo?',
														buttonLabel: 'Remover Cargo',
														onClick: () =>
															deleteOccupation({
																id: occupationId,
															}),
													}

													dispatch(openAlertModal(removeOccupation))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhuma cargo encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />

				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>

			<AddInstitution />
			<EditInstitution />
		</>
	)
}

export default Occupation
