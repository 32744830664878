import { BASE_URL } from '@constants/api'
import {
	AddLibrariesRequest,
	ListLibrariesRequest,
	ListLibrariesResponse,
	ShowLibrariesResponse,
	UpdateLibrariesRequest,
} from '@models/libraries'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const librariresApi = createApi({
	reducerPath: 'librariresApi',
	tagTypes: ['Bibliotecas'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListLibrariesResponse, ListLibrariesRequest>({
			query: ({ page = '1', search = '' }) => ({
				url: `/libraries?page=${page}&search=${search}&limit=20`,
				method: 'GET',
			}),
			providesTags: ['Bibliotecas'],
		}),
		showQuery: builder.query<ShowLibrariesResponse, { id?: string }>({
			query: ({ id }) => ({
				url: `/libraries/${id}`,
				method: 'GET',
			}),
			providesTags: ['Bibliotecas'],
		}),
		show: builder.mutation<ShowLibrariesResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/libraries/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['Bibliotecas'],
		}),
		add: builder.mutation<void, AddLibrariesRequest>({
			query: (data) => ({
				url: `/libraries`,
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['Bibliotecas'],
		}),
		edit: builder.mutation<void, UpdateLibrariesRequest>({
			query: ({ id, name, pdfFiles }) => ({
				url: `/libraries/${id}`,
				body: {
					name,
					pdfFiles,
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Bibliotecas'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/libraries/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Bibliotecas'],
		}),
	}),
})
