/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import { InputGroup } from '@components'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { successMessages } from '@constants/feedbackMessages'
import { Dialog } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addClassClientSchema } from '@schemas/addClient.schema'
import { clientApi } from '@services/ClientApi'
import { closeModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { getCssStyle, pixelsToRem } from '@utils/helpers/styleHelpers'

type Inputs = {
	class: MultiValue<{ value: string | number; label: string }>
}

const styles = {
	container:
		'relative inline-block align-bottom bg-white rounded-lg pl-10 pr-10 pt-6 pb-9 text-left overflow-visible shadow-xl transform transition-all my-8 sm:align-middle',
	title: 'text-lg leading-6 font-medium text-gray-900',
	subTitle: 'text-gray-500 mt-1',
	buttonGroup: 'mt-7 flex gap-4 justify-end',
}

export default function AddClass() {
	const dispatch = useAppDispatch()

	const { modalInfo: id, modalName } = useAppSelector((state) => state.modal)

	const [getClient, { data: clientInfo }] =
		clientApi.useShowClientMutationMutation()

	const [addClass, { isLoading, isSuccess }] = clientApi.useAddClassMutation()

	const [getClass, { data: dataClasses }] = clientApi.useShowClassMutation()

	useEffect(() => {
		if (id && modalName === 'addClass') {
			getClass({ id })
			getClient({ id })
		}
	}, [id, getClass, modalName, getClient])

	const { handleSubmit, control, setValue, reset } = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addClassClientSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const dataClass = {
			classes: data.class.map((v) => v.value as number) || [],
		}

		addClass({
			id: clientInfo?.id,
			...dataClass,
			name: clientInfo?.name,
			email: clientInfo?.email,
		})
		reset()
	}

	useEffect(() => {
		if (isSuccess) {
			dispatch(closeModal())
			dispatch(notificationCreators.show(successMessages.storedClasses))
		}
	}, [isSuccess, dispatch])

	return (
		<Modal name='addClass'>
			<Dialog.Panel
				style={getCssStyle(
					// { name: 'overflowY', value: 'auto' },
					{ name: 'maxWidth', value: pixelsToRem(630) },
					{ name: 'maxHeight', value: pixelsToRem(600) },
					{ name: 'width', value: '100%' }
				)}
				className={`${styles.container} modal-scroll`}
			>
				<p className={styles.title}>Adicionar Turma</p>
				<p className={styles.subTitle}>
					Preencha os campos abaixo para adicionar uma nova turma
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<InputGroup label='Turma' id='class' className='mt-4'>
						<Controller
							control={control}
							name='class'
							render={({
								field: { value, onBlur },
								fieldState: { isTouched, error },
							}) => {
								return (
									<Select
										onChange={(v) => setValue('class', v)}
										options={
											dataClasses?.data
												? dataClasses.data.map((item) => ({
														value: item.id,
														label: item.name,
												  }))
												: []
										}
										value={value}
										isMulti
										onBlur={onBlur}
										styles={{
											input: (base) => ({
												...base,
												borderRadius: '6px',
												paddingLeft: '2.5px',
												paddingRight: '2.5px',
												paddingTop: '3.5px',
												paddingBottom: '3.5px',
											}),
											control: (controlStyles) => ({
												...controlStyles,
												borderColor: (() => {
													const isValid = inputIsValid(error, isTouched)

													if (typeof isValid === 'undefined') {
														return '#D1D5DB !important'
													}

													if (!isValid) {
														return '#F44545 !important'
													}

													return '#15CA71 !important'
												})(),
											}),
											option: (optionStyle, { isFocused }) => ({
												...optionStyle,
												backgroundColor: isFocused ? '#1673FA' : '#fff',
												color: isFocused ? '#fff' : '#000',
											}),
										}}
										placeholder=''
										classNamePrefix='react-select'
									/>
								)
							}}
						/>
					</InputGroup>

					<div className={styles.buttonGroup}>
						<Button
							type='button'
							onClick={() => {
								dispatch(closeModal())
							}}
							variant='outline'
							disabled={false}
						>
							Cancelar
						</Button>
						<Button type='submit' isLoading={isLoading} disabled={!modalName}>
							Adicionar Turma
						</Button>
					</div>
				</form>
			</Dialog.Panel>
		</Modal>
	)
}
